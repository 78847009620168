/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import Fullscreen from 'vue-fullscreen'
import Vue from 'vue'

Vue.use(Fullscreen)

export default Fullscreen
