/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Material+Icons'
      // 'Material Design Icons',
    ]
  },
  custom: {
    families: [
      'Material Design Icons',
      'Font Awesome 5'
    ],
    urls: [
      'https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css',
      // 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
      '/fonts/fontawesome-free/css/all.css'
    ]
  }
})
