/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import axios from 'axios'

export function appendArray (formData, values, name) {
  if (values instanceof File) {
    formData.append(name, values, values.name)
  } else if (typeof values === 'object') {
    for (const key in values) {
      // console.log(key);
      if (typeof values[key] === 'object') {
        // console.log(values[key])
        let k
        if (name) {
          k = name + '[' + key + ']'
        } else {
          k = key
        }

        appendArray(formData, values[key], k)
      } else {
        if (values[key] === true) {
          values[key] = 1
        }
        if (values[key] === false) {
          values[key] = 0
        }
        if (name) {
          formData.append(name + '[' + key + ']', values[key])
        } else {
          // console.log(values[key])
          formData.append(key, values[key])
        }
      }
    }
  }

  return formData
}

export const Stub = prefix => {
  const url = p => prefix + (p ? `/${p}` : '')
  return {
    index (params, config = {}) {
      const u = url()
      return params === !0 ? u : axios.get(u, { params, ...config })
    },
    store (data = {}, config) {
      const u = url()
      const formData = new FormData()
      // for (let k in data)
      //   formData.append (k, data[k])
      appendArray(formData, data)
      return data === true ? u : axios.post(u, formData, config)

      // let u = url()
      // return args[0] === true ? u : axios.post(u, ...args)
    },
    show (id, ...args) {
      const u = url(id)
      return id === true ? u : axios.get(u, ...args)
    },
    update (id, data = {}, config = {}) {
      const u = url(id)
      const formData = new FormData()
      formData.append('_method', 'put')
      // for(let k in data)
      //   formData.append(k, data[k])
      appendArray(formData, data)
      return id === true ? u : axios.post(u, formData, config)
      // return id === true ? u : axios.put(u, ...args)
    },
    destroy (id, ...args) {
      const u = url(id)
      return id === true ? u : axios.delete(u, ...args)
    },
    resource (params, config = {}) {
      const u = url('All')
      return params === !0
        ? u
        : axios.get(u, {
          ...config,
          params
        })
    },
    activeOnly (params, config = {}) {
      const u = url('Resource')
      return params === true
        ? u
        : axios.get(u, {
          ...config,
          params
        })
    },
    utilities (params, config = {}) {
      const u = 'Utilities/All-' + prefix
      return params === !0
        ? u
        : axios.get(u, {
          ...config,
          params
        })
    }
  }
}
export const StubUrl = url => (prefix = null) => url + (prefix ? `/${prefix}` : '')
