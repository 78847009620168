<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <base-btn
    :class="getClasses"
    type="submit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </base-btn>
</template>

<script>

export default {
  name: 'DtSubmit',
  components: {
    Btn: () => import('@components/base/btn/Btn'),
    BaseBtn: () => import('@components/base/btn/BaseBtn')
  },
  props: {
    left: {
      default: () => false
    },
    right: {
      default: () => true
    }
  },
  computed: {
    getClasses () {
      return ['fab--btn', { right: this.right, left: this.left }]
    }
  },
  created () {
  }
}
</script>

<style scoped>
.fab--btn.left {
  left: 10px;
}

.fab--btn.right {
  right: 10px;
}

.fab--btn {
  position: absolute;
  bottom: 10px;
  z-index: 15000;
}

</style>
