/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'
import axios from 'axios'

const Url = StubUrl('Front/GuidanceCounselor/StudentInfraction')

export default {
  ...Stub(Url()),
  myStudentIndex (params, config = {}) {
    const url = Url('MyStudentIndex')
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  }
}
