/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routeAllies from '@routes/config'

let routes = []
const requireModule = require.context('@routes', true, /\.js$/)
// const requireModule = require.context("@routes", true, /index.js$/);
requireModule.keys().forEach(fileName => {
  // console.log(fileName)
  if (fileName.split('/').pop() !== 'index.js') return
  const m = requireModule(fileName)
  const l = m.default || m
  // console.log(l)
  routes = [...routes, ...l]
})
// console.log(routes)
/**
 * Not Found route
 */
routes.push({
  path: '*',
  name: 'NotFound',
  component: () => import(/* webpackChunkName: "FourOuFour" */ '@views/NotFound')
})
routes.push({
  path: routeAllies.errors.noMembership.path,
  name: routeAllies.errors.noMembership.name,
  meta: {
    pageError: true
  },
  component: () => import(/* webpackChunkName: "NoMembership" */ '@views/errors/NoMembership')
})
routes.push({
  path: routeAllies.auth.logout.path,
  name: routeAllies.auth.logout.name,
  component: () => import(/* webpackChunkName: "Logout" */ '@views/Logout')
})
export default routes
