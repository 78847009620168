/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import $ from 'jquery'
//
Vue.prototype.$jquery = $
window.$ = window.jQuery = $

export default jQuery
