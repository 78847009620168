/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  name: 'الملف الشخصي',
  account: 'الحساب',
  update: 'تعديل الملف الشخصي',
  photo: 'صورة الملف الشخصي',
  account_information: 'بيانات الحساب',
  personal_information: 'البيانات الشخصية',
  delete_account: 'حذف الحساب',
  delete_account_desc: 'لحذف الحساب الرجاء قم بادخال كلمة المرور الخاصة بك، بعد حذف الحساب سوف يتم حذف بياناتك ولن تستطيع تسجيل الدخول مرة أخرى.'
}
