/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const prefix = 'Front/TeacherAffair/Absence'
const url = StubUrl(prefix)

export default { ...Stub(prefix) }
