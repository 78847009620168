<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-app-bar
    :clipped-left="!AppRtl"
    :clipped-right="AppRtl"
    app
    color="primary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-app-bar-nav-icon
      v-if="showDrawerIcon"
      @click.stop="drawer = !drawer"
    />
    <v-avatar
      v-if="!AppIsSmall"
      :color="logoColor"
    >
      <v-img
        :src="AppLogo"
        contain
      />
    </v-avatar>
    <v-toolbar-title class="ps-4">
      {{ AppName }}
    </v-toolbar-title>
    <app-guest-buttons-bar />
    <v-spacer />
    <v-menu offset-y>
      <template #activator="{on,attr}">
        <v-btn
          icon
          v-bind="attr"
          v-on="on"
        >
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>

      <template #default>
        <v-list dense>
          <v-list-item
            v-if="user"
            :to="route($config.routes.user.profile)"
          >
            {{ $t('profile.account') }}
          </v-list-item>
          <v-list-item-group v-model="AppLocale">
            <template v-for="(item, i) in AppLocales.filter(e => e.code !== AppLocale)">
              <v-list-item
                :key="i.toString()"
                :disabled="item.code === AppLocale"
                :value="item.code"
                @click="SetAppLocale(item.code)"
              >
                <v-list-item-title class="text-center">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </template>
    </v-menu>
    <!--<v-btn-->
    <!--  v-show="user"-->
    <!--  :to="route($config.routes.user.profile)"-->
    <!--  icon-->
    <!--&gt;-->
    <!--  <v-icon>mdi-account</v-icon>-->
    <!--</v-btn>-->
    <!--<div class="mx-1" />-->
    <!--<template v-if="user">-->
    <app-notifications-menu v-if="user" />
    <!--<div class="mx-1" />-->
    <!--</template>-->
    <!--<app-language-menu />-->
  </v-app-bar>
</template>

<script>

export default {
  name: 'Bar',
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    logoColor () {
      return this.themeDark ? 'gray' : 'white'
    },
    showDrawerIcon () {
      return this.isLogin
    },
    drawer: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', Boolean(v))
      }
    },
    user () {
      return this.$root.authUser
    }
  }
}
</script>
