/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  choose: 'اختر {n}',
  store: 'اضافة {n} جديد',
  update: 'تعديل بيانات {n}',
  show: 'عرض بيانات {n}',
  destroy: 'حذف بيانات {n}',
  jobPermissions: 'صلاحيات وظيفة {n}',
  studentParticipations: 'مشاركات الطالب {n}',
  studentHomeworks: 'واجبات الطالب {n}',
  imported: 'تم استيراد {n}',
  notImported: 'فشل في الاستيراد {n}',
  copy_right: 'جميع الحقوق محفوظة © {d}'
}
