/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  '#': 'No.',
  Both_absent_user_id: 'Absent Teacher',
  Both_waiting_user_id: 'Waiting Teacher',
  Female_absent_user_id: 'Absent Teacher',
  Female_waiting_user_id: 'Waiting Teacher',
  Male_absent_user_id: 'Absent Teacher',
  Male_waiting_user_id: 'Waiting Teacher',
  absent_employee_id: 'Absent Teacher',
  action_date: 'Action Date',
  action_taken: 'The action taken',
  actions: 'Actions',
  active: 'Enabled',
  active_to_string: 'الحالة',
  add_answer: 'اضافة اجابة',
  add_question: 'اضافة سؤال',
  address: 'Address',
  answer: 'الإجابة',
  answer_name: 'الإجابة',
  answers: 'Answers',
  app_country_id: 'Country',
  app_name_ar: 'اسم الموقع بالعربي',
  app_name_en: 'اسم الموقع بالانجليزي',
  app_region_id: 'Region',
  approve_action: 'اعتماد',
  approved: 'Approved',
  archive_category: 'Archive category',
  archive_type_id: 'نوع المستند',
  arrive_time: 'Arrive time',
  attend_date: 'Attend Date',
  attend_time: 'Attend time',
  automatic_absence_waiting: 'Automatic wait distribution',
  balance: 'Balance',
  both_student_id: 'Female Student',
  both_teacher_id: 'Teacher',
  can_login: 'Can Login',
  cancel_reason: 'Cancel reason',
  categories: 'Categories',
  'categories.*': 'Category',
  category_id: 'Category',
  city_id: 'Region',
  city_key: 'City/Region Key',
  civil_registry: 'Civil Registry',
  code: 'الكود',
  coin: 'نقطة',
  coins: 'Coins',
  contact_data: 'Contact Data',
  contact_number: 'Contact Number',
  control: 'تحكم',
  correct_answer: 'Correct Answer',
  country_code: 'رمز الدولة',
  country_id: 'Country',
  country_key: 'Country Key',
  created_at: 'Created At',
  created_by_id: 'Created By',
  currency_code: 'كود العملة',
  current_password: 'كلمة المرور الحالية',
  customer_id: 'العميل',
  date: 'Date',
  date_delay_degree: 'The degree of deduction according to date',
  day: 'Day',
  day_delay_degree: 'Day Delay degree',
  day_key: 'Day Key',
  default: 'افتراضي',
  default_delay_degree_deduction: 'Delay Degree Deduction',
  degree: 'Degree',
  degree_deduction: 'Degree Deduction',
  degree_deduction_count: 'Total of Degree Deduction',
  degree_rating: 'Degree Rating',
  'degree_rating.*': 'Degree Rating',
  delay_date: 'Delay Date',
  delay_minutes: 'Delay Minutes',
  delay_time: 'Delay Time',
  delays_count: 'Delays Count',
  delivery_date: 'تاريخ التوصيل',
  delivery_fees: 'Delivery Fees',
  delivery_type_id: 'Delivery type',
  demand_kind_id: 'Demand Kind',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  details: 'البيانات',
  disable: 'Disable',
  double: 'مزدوج',
  education_class2_id: 'Education Class',
  education_class_group_id: 'Education class group',
  education_class_id: 'Education Class',
  education_classes: 'Education Classes',
  education_department_id: 'Education Department',
  education_grade_id: 'Education Grade',
  education_id: 'Education',
  education_major_id: 'Education major',
  education_office_id: 'Education Office',
  education_program_id: 'Education Program',
  education_specialization_id: 'Education Specialization',
  education_stage_id: 'Education Stage',
  education_stages: 'Education Stages',
  education_system_id: 'Education System',
  email: 'Email',
  email_confirmation: 'Email Confirmation',
  email_contact: 'بريد التواصل معنا',
  email_content: 'Email Content',
  email_content_ar: 'Arabic Email Content',
  email_content_en: 'English Email Content',
  employee_id: 'Employee',
  employee_name: 'Employee Name',
  employees: 'المعلمين',
  end_date: 'End Date',
  end_date_delay_degree: 'The end date of the delay discount rate',
  excuse_id: 'Excuse',
  extra_procedures: 'Procedure Actions',
  female_student_id: 'Female Student',
  female_teacher_id: 'Teacher',
  financial_status_id: 'Financial Status',
  first_address: 'العنوان 1',
  first_class_repeat: 'Limit number for the first class repeat',
  first_name: 'الاسم الاول',
  fortified: 'Fortified',
  from_date: 'التاريخ من',
  gender_code: 'Gender Code',
  gender_id: 'Gender',
  gifted_id: 'Gifted user',
  google_map: 'Google map url',
  grades: 'Grades',
  'grades.*.homework ': 'Homework',
  'grades.*.monthly_test': 'Monthly Test',
  'grades.*.participate': 'Participate',
  group: 'Group',
  guardian_id: 'Student Parent',
  guardian_mobile: 'Guardian Mobile',
  guardian_name: 'Guardian Name',
  has_employee_list: 'Has Employee list',
  has_student_list: 'Has Student list',
  have_coins: 'Have coins',
  have_reason: 'Have Reason',
  health_status_id: 'Health Status',
  homework: 'Homework',
  homework_content: 'Homework Content',
  homework_date: 'Homework Date',
  homework_id: 'Homework',
  homework_received_date: 'Homework Received Date',
  homework_setting_answer_id: 'Answer',
  homework_setting_id: 'Homework Setting',
  homework_setting_name: 'اسم اعداد الواجد',
  homework_setting_question_id: 'Question',
  homework_title: 'Homework Title',
  id_number: 'id Number',
  image: 'Image',
  images: 'الصور',
  implementation: 'Implementation',
  incoming: 'Incoming',
  infraction_categories: 'Infraction Categories',
  infraction_category_id: 'Infraction Category',
  infraction_date: 'Infraction Date',
  infraction_day: 'Infraction Day',
  infraction_groups: 'Infraction Name Procedure',
  infraction_hijri_ar: 'Infraction Arabic hijri date',
  infraction_hijri_en: 'Infraction English hijri date',
  infraction_level_id: 'Infraction Level',
  infraction_levels: 'Infraction Levels',
  infraction_name_id: 'Infraction Name',
  infraction_no: 'Infraction No.',
  infraction_type_id: 'Infraction Type',
  invoice_prefix: 'بادئة الطلبات',
  is_active: 'نشط',
  is_approved: 'Approved',
  is_coins_returned: 'Coins returned',
  is_read: 'Read',
  is_withdraw: 'Is Withdraw',
  iso_2: 'ISO-2',
  iso_3: 'ISO-3',
  job_id: 'Job',
  job_title_id: 'Job Title',
  jobs: 'Jobs',
  key: 'Key',
  language_code: 'Language Code',
  last_class_repeat: 'Limit number for the last class repeat',
  last_name: 'الاسم الاخير',
  late_students: 'الطلاب المتأخرين',
  leader_id: 'School Leader',
  leave_time: 'Leave time',
  leave_type: 'Leave Type',
  limit_shifts: 'Limit Shifts',
  limit_supervisions: 'Limit Supervisions',
  limit_time_table: 'Limit of school schedule per week',
  loadMore: 'عرض المزيد',
  locale: 'Language',
  login_id: 'ID',
  long_name: 'Long name',
  long_name_ar: 'Arabic Long Name',
  long_name_en: 'English Long Name',
  male_student_id: 'Student',
  male_teacher_id: 'Teacher',
  manager_email: 'بريد مدير الجهة',
  manager_mobile: 'جوال مدير الجهة',
  manager_name: 'اسم مدير الجهة',
  max_class_repeat: 'Max Class Repeat',
  max_first_class: 'Maximum number for the first class',
  max_last_class: 'Maximum number for the last class',
  membership_date: 'Membership Date',
  membership_end_date: 'Membership End Date',
  membership_end_date_from: 'تاريخ نهاية الاشتراك - من',
  membership_end_date_to: 'تاريخ نهاية الاشتراك - إلى',
  membership_period: 'Membership Period',
  membership_type: 'Membership Type',
  membership_type_id: 'Membership Type',
  message: 'Message',
  min_first_class: 'Minimum number of the first class',
  min_last_class: 'Minimum number of the last class',
  min_price: 'اقل سعر',
  mobile: 'Mobile',
  mobile_content: 'Mobile SMS Content',
  mobile_content_ar: 'Arabic Mobile SMS Content',
  mobile_content_en: 'English Mobile SMS Content',
  monthly_test: 'Monthly Test',
  myAccount: 'حسابي',
  myCoins: 'نقاطي',
  name: 'Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  nationality_id: 'Nationality',
  net_total: 'المجموع النهائي',
  new_school_class_id: 'New School Class',
  new_school_day_id: 'New day',
  new_user_id: 'future of demand',
  notes: 'Notes',
  number: 'Number',
  number_of_repeat: 'Arrange classes per day',
  number_per_week: 'Number of classes per week',
  online_payment: 'الدفع الالكتروني',
  opinion: 'School leader\'s opinion',
  orderNow: 'اطلب الآن',
  orderTotal: 'إجمالي الطلب',
  order_by: 'Order By',
  order_coins: 'نقاط الطلب',
  order_date: 'تاريخ الطلب',
  order_id: 'رقم الطلب',
  order_items: 'Order items',
  'order_items.*.id': 'Order item',
  'order_items.*.product_id': 'Product',
  order_return_coins: 'نقاط الطلب المسترجعة',
  order_status: 'حالة الطلب',
  order_total: 'إجمالي الطلب',
  organization_type_id: 'نوع الجهة',
  outgoing: 'Outgoing',
  owner_request: 'Applicant',
  parent_id: 'الأب',
  participate: 'Participate',
  participations: 'المشاركات',
  participations_count: 'عدد المشاركات',
  passport_number: 'Passport Number',
  password: 'Password',
  password_confirmation: 'Password Confirmation',
  payment_method_code: 'طريقة الدفع',
  payment_method_id: 'طريقة الدفع',
  period_delay: 'Period of delay',
  phase: 'Phase',
  phone: 'phone',
  postcode: 'الرمز البريدي',
  price: 'Price',
  print_after_student_delay: 'Print after preparing for the late morning',
  procedure_assign: 'Assign',
  procedure_entries: 'Procedures',
  procedure_id: 'Procedure',
  procedure_number: 'Procedure Number',
  procedure_taken: 'Procedure taken',
  procedure_type: 'Procedure type',
  procedures: 'Procedures',
  product_id: 'Product',
  'product_id.*': 'Product',
  product_image: 'صورة المنتج',
  product_images: 'صور المنتج',
  products: 'المنتجات',
  profile_type: 'نوع الحساب',
  program_date: 'Program Date',
  program_name: 'Program Name',
  program_number: 'Program Number',
  purchases_manager_email: 'بريد مدير المشتريات',
  purchases_manager_mobile: 'جوال مدير المشتريات',
  purchases_manager_name: 'اسم مدير المشتريات',
  quantity: 'Quantity',
  question: 'السؤال',
  question_degree: 'درجة السؤال',
  question_name: 'السؤال',
  rate: 'Rate',
  'rate.*.degree_rating': 'Degree Rating',
  'rate.*.rating_item_id': 'Rating item',
  rating: 'Degree Rating',
  'rating.*': 'Degree Rating',
  'rating.*.degree_rating': 'Degree Rating',
  rating_item_id: 'Rating item',
  rating_items: 'Rating items',
  'rating_items.*': 'Rating items',
  read_at: 'Read At',
  reason: 'Reason',
  receiver_jobs: 'Receiver Roles',
  region_code: 'رمز المنطقة',
  region_id: 'المنطقة',
  region_key: 'City/Region Key',
  remember: 'Remember Me',
  remove_answer: 'إزالة الإجابة',
  remove_question: 'إزالة السؤال',
  return_coins: 'Return coins',
  role_code: 'مجموعة المستخدم',
  role_id: 'Role',
  roles: 'Roles',
  sar: 'ريال',
  schedule_notes: 'Schedule Notes',
  schoolCoins: 'نقاط المدرسة',
  school_class_id: 'School Class',
  school_classes: 'School Classes',
  school_day_id: 'Day',
  school_days_id: 'Days',
  school_gender_code: 'School Gender Code',
  school_gender_id: 'Gender of School Education',
  school_id: 'School',
  school_name: 'School Name',
  school_program_id: 'البرنامج',
  school_semester_id: 'Semester',
  school_semester_id2: 'Semester',
  school_subject2_id: 'School Subject',
  school_subject_id: 'School Subject',
  school_subjects: 'School Subjects',
  school_year_id: 'School year',
  schools: 'Schools',
  search: 'بحث',
  search_infraction_name_id: 'Infraction Name',
  search_type: 'Search Type',
  second_address: 'العنوان 2',
  send_date: 'تاريخ الارسال',
  sender_jobs: 'Sender Roles',
  set_degree: 'Set Degree',
  share_waiting_classes: 'Share Waiting Classes',
  shared_subject: 'Shared Subject',
  shift_course_id: 'Shift Course',
  shift_date: 'Shift Date',
  shift_period_id: 'Shift Period',
  shift_procedure_id: 'Shift Procedure',
  shifts: 'Shifts',
  'shifts.*.shift_course_id': 'Shift Course',
  'shifts.*.shift_date': 'Shift Date',
  'shifts.*.shift_period_id': 'Shift Period',
  'shifts.*.shift_procedure_id': 'Shift Procedure',
  shipping_method_code: 'طريقة الشحن',
  shipping_method_id: 'طريقة الشحن',
  shop: 'المتجر المدرسي',
  short_name: 'Short Name',
  show: 'إظهار',
  show_to_students: 'Show to students',
  signature: 'Signature',
  single: 'فردي',
  social_status_id: 'Social Status',
  sort_order: 'ترتيب العرض',
  start_date: 'Start Date',
  start_date_delay_degree: 'The beginning of the date of deduction of delay',
  status: 'Status',
  student_id: 'Student',
  students: 'الطلاب',
  study_date: 'Study Date',
  study_day: 'Study Day',
  subject: 'Subject',
  subject_ar: 'Arabic Subject',
  subject_en: 'English Subject',
  subtotal: 'المجموع الفرعي',
  supervision_course_id: 'Daily Supervisions',
  supervision_date: 'Daily Supervision Date',
  supervision_location_id: 'Supervision Location',
  supervision_procedure_id: 'Supervision Procedure',
  supervisions: 'Supervisions',
  'supervisions.*.supervision_date': 'Daily Supervision Date',
  'supervisions.*.supervision_location_id': 'Supervision Location',
  'supervisions.*.supervision_procedure_id': 'Supervision Location',
  switch_employee_id: 'المعلم المستبدل',
  switch_school_class_id: 'الحصة المستبدلة',
  switch_school_day_id: 'اليوم المستبدل',
  teacher_id: 'Teacher',
  template_content: 'Template',
  template_content_ar: 'Arabic Template',
  template_content_en: 'English Template',
  text_input: 'Text input',
  textarea_input: 'Textarea input',
  time: 'Time',
  time_back: 'Time Back',
  time_exit: 'Time Exit',
  time_table_assign_count: 'Data Number',
  time_table_count: 'Schedule number',
  to_date: 'التاريخ إلى',
  to_id: 'Send To',
  total: 'الإجمالي',
  transcript_attend: 'درجة الحضور',
  transcript_homework: 'واجبات',
  transcript_monthly_test: 'اختبار تحريري قصير',
  transcript_participate: 'مشاركة',
  transcript_report: 'بحوث مشروعات تقارير',
  transcript_programs: 'تطبيقات أنشطة صفية',
  type: 'Type',
  unfortified: 'Unfortified',
  url: 'الرابط',
  user_group_id: 'User Group',
  user_id: 'User',
  vat: 'القيمة المضافة',
  verify_code: 'رمز التحقق',
  violating_students: 'الطلاب المخالفين',
  waiting: 'Is waiting',
  waiting_employee_id: 'Waiting Teacher',
  waiting_excuse_id: 'Implementation',
  week_number: 'Week number',
  work_time: 'School time',
  shift_date_hijri: 'Shift Date',
  supervision_date_hijri: 'Supervision Date',
  hijri_date: 'Date',
  has_mandate: 'Mandate',
  absence_days: 'Absence Days',
  attachment: 'Attachment',
  attachments: 'Attachments',
  promo_code: 'Promo Code',
  promo_codes: 'Promo Codes',
  has_quantity: 'Has Quantity',
  affidavit: 'Affidavit',
  title: 'Title',
  content: 'Content'
}
