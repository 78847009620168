<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-progress-linear
    :active="$root.AppProgress"
    class="mb-1"
    fixed
    indeterminate
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AppProgressLinear'
}
</script>

<style scoped>

</style>
