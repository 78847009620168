/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "App" */'@views/App'),
    meta: {
      // guest: true
    },
    children: [
      {
        path: routes.auth.login,
        name: routes.auth.login,
        meta: {
          guest: true
        },
        component: () => import(/* webpackChunkName: "Login" */ '@components/auth/Login')
      },
      {
        path: routes.auth.register,
        name: routes.auth.register,
        meta: {
          guest: true
        },
        component: () => import(/* webpackChunkName: "Register" */ '@components/auth/RegisterPage.vue')
      },
      {
        path: routes.auth.forgotPassword,
        name: routes.auth.forgotPassword,
        meta: {
          guest: true
        },
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '@components/auth/ForgotPasswordPage.vue')
      }
    ]
  }
]
