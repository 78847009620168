/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  about_us_desc: 'An integrated system that supports modern technology for digital transformation.<br/>' +
   ' We provide the best services and solutions for schools through which data can be digitally automated.',
  contact_us_desc: 'We are happy to communicate with us, receive your opinions and provide solutions.',
  address_desc: 'KSA, Riyadh',
  last_update: 'Last update: {d}',
  terms_line_1: 'By using an App ({n}) This means that you agree to the terms and conditions of the application.',
  terms_line_2: '&bullet; " اتابع " هو برنامج للتحول الرقمي للإدارة المدرسية و وسيط للشراكات المجتمعية مع الجامعات و المدارس و الهيئات و الجمعيات .<br />' +
   '&bullet; يقتصر دور " أتابع " في برامج الشراكة المجتمعية على ايصال كوبونات الخصم الممنوحة من الشريك ( مزود الخدمة ) للمستفيدين المشتركين في " أتابع " .<br />' +
   '&bullet; لا يقوم أتابع بدور الوساطة المالية بين طرفي الشراكة المجتمعية .<br />' +
   '&bullet; يلتزم " أتابع " بتطبيق أحكام و شروط الشريك فيما يخص استخدام كوبونات الخصم المتفق عليها.<br />' +
   '&bullet; يلتزم الشريك (مزود الخدمة) بتقديم خصومات حقيقية و تفعيلها خلال الفترة المتفق عليها و يتحمل ما يترتب على عدم التزامه بذلك .<br />' +
   '&bullet; يقدم "أتابع " فترة تجريبية للشريك ( 60 ) يوما بدون مقابل مالي .<br />' +
   '&bullet; في حال ظهرت بوادر تحقق اهداف الشراكة خلال الفترة التجريبية فيلزم توقيع اتفاقية شراكة بين الطرفين .<br />' +
   '&bullet; التطبيق لا يطلب من طرفي الشراكة المجتمعية اي بيانات شخصية إجبارية.<br />' +
   '&bullet; جميع البيانات التي يتم إدخالها أو تخزينها هي فقط لتقديم الخدمات واستخدماها من طرفكم.<br />' +
   '&bullet; لا يتم جمع البيانات، تخزينها أو تقديمها لأي جهة أخرى.<br />' +
   '&bullet; التطبيق غير مسؤول عن النزاعات التي قد تحدث بين طرفي الشراكة المجتمعية<br />' +
   '&bullet; يحق للتطبيق الغاء حساب المشترك في حال حدث خلل في الاتفاق<br />' +
   '&bullet; هذه الخدمات عامة لتسهيل عمليات أتمتة البيانات واستخدامها رقمياً.',
  pp_line_1: 'Application privacy policy ({n})',
  pp_line_2: 'All data entered or stored by you is for the purpose of using the features and services of the application.<br />' +
   'No data is collected or shared and all data is encrypted and stored securely.<br />' +
   'The App is not responsible for disputes that may occur by services or misunderstandings.<br />' +
   'The App has the right to delete the account or school data in the event of a defect in the agreement or other matters.<br />',
  contact_with_app: 'Connect with us',
  plans: {
    basic: 'Free Plan',
    gold: 'Golden Plan'
  },
  subscribe: 'Subscribe'
}
