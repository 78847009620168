<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <slot name="top" />
      <v-file-input
        :clearable="getClearable"
        :counter="counter"
        :counter-value="a => a ? a.length : 0"
        :disabled="disabled"
        :error="getErrorMessages.length>0"
        :error-count="getErrorCount"
        :error-messages="[...getErrorMessages,...v.errors]"
        :label="getLabel"
        :placeholder="getPlaceholder"
        :readonly="readonly"
        :show-size="showSize"
        :value="value"
        v-bind="$attrs"
        v-on="{...$listeners,on:input,change}"
      />
      <slot />
      <slot name="bottom" />
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import InputsMixins from '@mixins/InputsMixins'

export default {
  name: 'FileInput',
  mixins: [InputsMixins],
  props: {
    showSize: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {},
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    change (v) {
      this.input(v)
      this.$emit('change', v)
    }
  }
}
</script>

<style scoped>

</style>
