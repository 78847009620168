<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-row v-bind="$attrs">
    <slot name="prepend" />
    <app-auto-select
      v-model="form.infraction_category_id"
      :items="items"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="infraction_category_id"
    />
    <app-auto-select
      v-if="levelSelect"
      v-model="form.infraction_level_id"
      :items="infractionLevels"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="infraction_level_id"
    />
    <app-auto-select
      v-if="nameSelect"
      v-model="form.infraction_name_id"
      :items="infractionNames"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="infraction_name_id"
    />
    <slot />
    <slot />
    <slot name="append" />
  </app-row>
</template>

<script>

export default {
  name: 'InfractionCategoryRow',
  props: {
    required: {
      type: Boolean,
      default: () => undefined
    },
    activeOnly: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      required: true
    },
    levelSelect: {
      type: Boolean,
      default: () => true
    },
    nameSelect: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    const urls = this.$api.methods.infractionCategory
    return {
      urls,
      loading: !1,
      items: [],
      rules: []
    }
  },
  computed: {
    selectedItem () {
      const form = this.form || {}
      const id = form.infraction_category_id || undefined
      const selected = this.items.find(e => e.id === id)
      if (!id || !selected) return []
      return selected
    },
    infractionLevels () {
      const selected = this.selectedItem
      if (!selected || !selected.infraction_levels) return []
      return selected.infraction_levels
    },
    selectedLevel () {
      const form = this.form || {}
      const id = form.infraction_level_id || undefined
      const selected = this.infractionLevels.find(e => e.id === id)
      if (!id || !selected) return []
      return selected
    },
    infractionNames () {
      const selected = this.selectedLevel
      if (!selected || !selected.infraction_names) return []
      return selected.infraction_names
    },
    getRules () {
      const r = this.rules
      this.required === true && r.indexOf('required') === -1 && r.push('required')
      return r.join('|')
    }
  },
  mounted () {
    this.queueAxios(this.fetchItems)
  },
  methods: {
    fetchItems () {
      if (this.loading) return
      this.loading = !0

      const items = this.getAxiosItems()
      if (items !== undefined && items !== null) {
        this.setItems(items)
        this.loading = !1
        return
      }

      this.setItems([])
      const action = this.urls[`${this.activeOnly ? 'active' : 'all'}Select`]

      action({ params: { all: 'all' } }).then(({ data }) => {
        if (data && data.data) {
          this.setIniAxios(this.getAxiosName(), data.data)
          this.setItems(data.data)
        }
      }).catch(() => this.setItems([])).finally(() => this.loading = !1)
    },
    setItems (v) {
      this.items = v
    }
  }
}
</script>
