/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  props: {
    mode: {
      // default: () => 'aggressive'
      default: () => 'lazy'
      // default: () => undefined
    },
    vid: {},
    value: {},
    rules: {
      type: [Array, String],
      default: () => undefined
    },
    name: {
      required: true
    },
    label: {
      default () {
        return undefined
      }
    },
    placeholder: {
      default () {
        return this.label
      }
    },
    errors: {
      type: Array,
      default: () => []
    },
    errorCount: {
      type: [Number, String],
      default: () => undefined
    },
    clearable: {
      type: Boolean,
      default: () => !1
    },
    readonly: {
      type: Boolean,
      default: () => !1
    },
    disabled: {
      type: Boolean,
      default: () => !1
    },
    required: {
      type: Boolean,
      default: () => !1
    },
    immediate: {
      default: () => false
    },
    counter: {
      type: Boolean,
      default: () => false
    },
    forceClearable: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getLabel () {
      const k = this.label === undefined ? this.name : this.label

      let label = ''
      if (k) {
        label = this.parseAttribute(k)
        if (label && this.hasRequired) {
          label = `${label}*`
        }
      }
      return label
    },
    getPlaceholder () {
      if (!this.placeholder && this.hasMobile) {
        return '05xxxxxxxx'
      }
      const k = this.placeholder === undefined ? this.name : this.placeholder
      return k ? this.parseAttribute(k) : ''
    },
    getRules () {
      let rules = this.rules || []
      rules = typeof rules === 'string' ? rules.split('|') : rules
      if (this.required === true && rules.indexOf('required') === -1) {
        rules.push('required')
      }
      // console.log(rules)
      return rules.join('|')
      // return 'required';
    },
    getClearable () {
      return this.forceClearable === true
        ? true
        : (this.readonly === true
            ? false
            : this.clearable)
      // return this.readonly === true || this.disabled === true ? false : this.clearable
    },
    hasRequired () {
      if (this.required === true) return true
      let r = this.getRules || ''
      if (typeof r === 'string') {
        r = r.split('|')
      }

      return r.indexOf('required') >= 0
    },
    hasMobile () {
      let r = this.getRules || ''
      if (typeof r === 'string') {
        r = r.split('|')
      }

      return r.indexOf('mobile') >= 0
    },
    getErrorCount () {
      return this.errorCount === undefined
        ? 1
        : (parseInt(this.errorCount) ||
       1)
    },
    getErrorMessages () {
      const provider = this.$refs.provider
      let errors = [...this.errors]
      provider && (errors = [...errors, ...provider.errors])

      return errors
    }
  }
}
