/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub } from '@helpers/api/requests/Stub'

const prefix = a => 'Front' + (a ? `/${a}` : '')

export default { ...Stub(prefix('EducationMajor')) }
