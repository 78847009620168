/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "App" */ '@views/App'),
    meta: {
      auth: true
    },
    children: [
      {
        /* Main Auth Route */
        path: 'dashboard',
        alias: '/',
        name: routes.user.home,
        component: () => import(/* webpackChunkName: "Dashboard" */ '@components/dashboard/Dashboard')
      },

      {
        path: routes.school.setup,
        name: routes.school.setup,
        component: () => import(/* webpackChunkName: "SchoolSetting" */ '@actor/school/SchoolSetting')
      },

      {
        path: 'profile',
        name: routes.user.profile,
        component: () => import(/* webpackChunkName: "Profile" */ '@actor/user/Profile')
      },

      {
        path: routes.user.index,
        name: routes.user.index,
        component: () => import(/* webpackChunkName: "Users" */ '@actor/user/Index')
      },

      {
        path: routes.school.index,
        name: routes.school.index,
        component: () => import(/* webpackChunkName: "Schools" */ '@actor/school/Index')
      },

      {
        path: routes.membershipType.index,
        name: routes.membershipType.index,
        component: () => import(/* webpackChunkName: "MembershipTypes" */ '@actor/membership-type/Index')
      },

      {
        path: routes.leader.index,
        name: routes.leader.index,
        component: () => import(/* webpackChunkName: "Leaders" */ '@actor/leader/Index')
      },

      {
        path: routes.membership.index,
        name: routes.membership.index,
        component: () => import(/* webpackChunkName: "Memberships" */ '@actor/membership/Index')
      },

      {
        path: routes.student.index,
        name: routes.student.index,
        component: () => import(/* webpackChunkName: "StudentsIndex" */ '@actor/student/Index')
      },

      {
        path: routes.student.qrScanner,
        name: routes.student.qrScanner,
        component: () => import(/* webpackChunkName: "StudentsQrScanner" */ '@actor/student/QrScanner')
      },

      {
        path: routes.student.qrList,
        name: routes.student.qrList,
        component: () => import(/* webpackChunkName: "StudentsQrList" */ '@actor/student/QrList')
      },

      {
        path: routes.student.import,
        name: routes.student.import,
        component: () => import(/* webpackChunkName: "importStudents" */ '@actor/student/Import')
      },

      {
        path: routes.guardian.index,
        name: routes.guardian.index,
        component: () => import(/* webpackChunkName: "Guardians" */ '@actor/guardian/Index')
      },

      {
        path: routes.educationMajor.index,
        name: routes.educationMajor.index,
        component: () => import(/* webpackChunkName: "EducationMajors" */ '@actor/education-major/Index')
      },

      {
        path: routes.employee.index,
        name: routes.employee.index,
        component: () => import(/* webpackChunkName: "Employees" */ '@actor/employee/Index')
      },

      {
        path: routes.employee.import,
        name: routes.employee.import,
        component: () => import(/* webpackChunkName: "ImportEmployees" */ '@actor/employee/Import')
      },

      {
        path: routes.education.index,
        name: routes.education.index,
        component: () => import(/* webpackChunkName: "Educations" */ '@setup/education/Index')
      },

      {
        path: routes.educationDepartment.index,
        name: routes.educationDepartment.index,
        component: () => import(/* webpackChunkName: "EducationDepartments" */ '@setup/education-department/Index')
      },

      {
        path: routes.educationOffice.index,
        name: routes.educationOffice.index,
        component: () => import(/* webpackChunkName: "EducationOffices" */ '@setup/education-office/Index')
      },

      {
        path: routes.educationProgram.index,
        name: routes.educationProgram.index,
        component: () => import(/* webpackChunkName: "EducationPrograms" */ '@edu/education-program/Index')
      },
      {
        path: routes.educationSystem.index,
        name: routes.educationSystem.index,
        component: () => import(/* webpackChunkName: "EducationSystems" */ '@edu/education-system/Index')
      },
      {
        path: routes.educationSpecialization.index,
        name: routes.educationSpecialization.index,
        component: () => import(/* webpackChunkName: "EducationSpecializations" */ '@edu/education-specialization/Index')
      },
      {
        path: routes.educationStage.index,
        name: routes.educationStage.index,
        component: () => import(/* webpackChunkName: "EducationStages" */ '@edu/education-stage/Index')
      },
      {
        path: routes.educationGrade.index,
        name: routes.educationGrade.index,
        component: () => import(/* webpackChunkName: "EducationGrades" */ '@edu/education-grade/Index')
      },
      {
        path: routes.educationClass.index,
        name: routes.educationClass.index,
        component: () => import(/* webpackChunkName: "EducationClasses" */ '@edu/education-class/Index')
      },
      {
        path: routes.educationClassGroup.index,
        name: routes.educationClassGroup.index,
        component: () => import(/* webpackChunkName: "EducationClassGroups" */ '@edu/education-class-group/Index')
      },
      {
        path: routes.attendanceClassGroup.index,
        name: routes.attendanceClassGroup.index,
        component: () => import(/* webpackChunkName: "AttendanceClassGroups" */ '@edu/attendance-class-group/Index')
      },

      {
        path: routes.jobTitle.index,
        name: routes.jobTitle.index,
        component: () => import(/* webpackChunkName: "JobTitles" */ '@accessory/job-title/Index')
      },
      {
        path: routes.job.index,
        name: routes.job.index,
        component: () => import(/* webpackChunkName: "Jobs" */ '@accessory/job/Index')
      },

      {
        path: routes.nationality.index,
        name: routes.nationality.index,
        component: () => import(/* webpackChunkName: "Nationalities" */ '@accessory/nationality/Index')
      },
      {
        path: routes.country.index,
        name: routes.country.index,
        component: () => import(/* webpackChunkName: "Countries" */ '@accessory/country/Index')
      },
      {
        path: routes.city.index,
        name: routes.city.index,
        component: () => import(/* webpackChunkName: "Cities" */ '@accessory/city/Index')
      },
      {
        path: routes.financialStatus.index,
        name: routes.financialStatus.index,
        component: () => import(/* webpackChunkName: "FinancialStatuses" */ '@accessory/financial-status/Index')
      },
      {
        path: routes.healthStatus.index,
        name: routes.healthStatus.index,
        component: () => import(/* webpackChunkName: "HealthStatuses" */ '@accessory/health-status/Index')
      },
      {
        path: routes.socialStatus.index,
        name: routes.socialStatus.index,
        component: () => import(/* webpackChunkName: "SocialStatuses" */ '@accessory/social-status/Index')
      },
      {
        path: routes.gender.index,
        name: routes.gender.index,
        component: () => import(/* webpackChunkName: "Genders" */ '@accessory/gender/Index')
      },
      {
        path: routes.schoolGender.index,
        name: routes.schoolGender.index,
        component: () => import(/* webpackChunkName: "SchoolGenders" */ '@accessory/school-gender/Index')
      },

      {
        path: routes.schoolYear.index,
        name: routes.schoolYear.index,
        component: () => import(/* webpackChunkName: "SchoolYears" */ '@accessory/school-year/Index')
      },
      {
        path: routes.schoolSemester.index,
        name: routes.schoolSemester.index,
        component: () => import(/* webpackChunkName: "SchoolSemesters" */ '@accessory/school-semester/Index')
      },
      {
        path: routes.schoolSubject.index,
        name: routes.schoolSubject.index,
        component: () => import(/* webpackChunkName: "SchoolSubjects" */ '@accessory/school-subject/Index')
      },
      {
        path: routes.schoolClass.index,
        name: routes.schoolClass.index,
        component: () => import(/* webpackChunkName: "SchoolClasses" */ '@accessory/school-class/Index')
      },
      {
        path: routes.schoolDay.index,
        name: routes.schoolDay.index,
        component: () => import(/* webpackChunkName: "SchoolDays" */ '@accessory/school-day/Index')
      },

      {
        path: routes.infraction.index,
        name: routes.infraction.index,
        component: () => import(/* webpackChunkName: "Infractions" */ '@guidance/infraction/Index')
      },

      {
        path: routes.infraction.view,
        name: routes.infraction.view,
        component: () => import(/* webpackChunkName: "infractionsView" */ '@guidance/infraction/ViewInfraction'),
        props: !0
      },

      {
        path: routes.infraction.studentInfractions,
        name: routes.infraction.studentInfractions,
        component: () => import(/* webpackChunkName: "StudentInfractions" */ '@guidance/infraction/StudentInfractions')
      },

      {
        path: routes.infraction.assignedInfractions,
        name: routes.infraction.assignedInfractions,
        component: () => import(/* webpackChunkName: "AssignedInfractions" */ '@guidance/infraction/AssignedInfractions')
      },

      {
        path: routes.infractionCategory.index,
        name: routes.infractionCategory.index,
        component: () => import(/* webpackChunkName: "InfractionCategories" */ '@guidance/infraction-category/Index')
      },

      {
        path: routes.infractionLevel.index,
        name: routes.infractionLevel.index,
        component: () => import(/* webpackChunkName: "InfractionLevels" */ '@guidance/infraction-level/Index')
      },

      {
        path: routes.infractionName.index,
        name: routes.infractionName.index,
        component: () => import(/* webpackChunkName: "InfractionNames" */ '@guidance/infraction-name/Index')
      },

      {
        path: routes.procedure.index,
        name: routes.procedure.index,
        component: () => import(/* webpackChunkName: "Procedure" */ '@guidance/procedure/Index')
      },
      {
        path: routes.extraProcedure.index,
        name: routes.extraProcedure.index,
        component: () => import(/* webpackChunkName: "ExtraProcedures" */ '@guidance/extra-procedure/Index')
      },
      {
        path: routes.procedureNumber.index,
        name: routes.procedureNumber.index,
        component: () => import(/* webpackChunkName: "ProcedureNumbers" */ '@guidance/procedure-number/Index')
      },

      {
        path: routes.demandKind.index,
        name: routes.demandKind.index,
        component: () => import(/* webpackChunkName: "DemandKinds" */ '@school-feature/demand-kind/Index')
      },
      {
        path: routes.demand.index,
        name: routes.demand.index,
        component: () => import(/* webpackChunkName: "Demands" */ '@school-feature/demand/Index')
      },
      {
        path: routes.demand.myIndex,
        name: routes.demand.myIndex,
        component: () => import(/* webpackChunkName: "MyDemands" */ '@school-feature/demand/MyIndex')
      },
      {
        path: routes.excuse.index,
        name: routes.excuse.index,
        component: () => import(/* webpackChunkName: "Excuses" */ '@teacher-affair/excuse/Index')
      },
      {
        path: routes.waitingExcuse.index,
        name: routes.waitingExcuse.index,
        component: () => import(/* webpackChunkName: "waitingExcuses" */ '@teacher-affair/waiting-excuse/Index')
      },
      {
        path: routes.waitingClass.index,
        name: routes.waitingClass.index,
        component: () => import(/* webpackChunkName: "WaitingClasses" */ '@teacher-affair/waiting-class/Index')
      },
      {
        path: routes.absence.index,
        name: routes.absence.index,
        component: () => import(/* webpackChunkName: "Absences" */ '@teacher-affair/absence/Index')
      },
      {
        path: routes.empPermit.index,
        name: routes.empPermit.index,
        component: () => import(/* webpackChunkName: "EmpPermits" */ '@teacher-affair/emp-permit/Index')
      },
      {
        path: routes.empPermitDemand.index,
        name: routes.empPermitDemand.index,
        component: () => import(/* webpackChunkName: "EmpPermitDemands" */ '@teacher-affair/emp-permit-demand/Index')
      },
      {
        path: routes.studentAttend.index,
        name: routes.studentAttend.index,
        component: () => import(/* webpackChunkName: "StudentAttends" */ '@attend/student-attend/Index')
      },
      {
        path: routes.studentAttend.qr,
        name: routes.studentAttend.qr,
        component: () => import(/* webpackChunkName: "StudentQrAttends" */ '@attend/student-attend/StudentQr')
      },
      {
        path: routes.studentAttend.confirmAbsences,
        name: routes.studentAttend.confirmAbsences,
        component: () => import(/* webpackChunkName: "StudentAbsence" */ '@attend/StudentAbsence')
      },

      {
        path: routes.studentDelay.index,
        name: routes.studentDelay.index,
        component: () => import(/* webpackChunkName: "StudentDelays" */ '@attend/student-delay/Index')
      },
      {
        path: routes.studentDelay.qr,
        name: routes.studentDelay.qr,
        component: () => import(/* webpackChunkName: "StudentQrDelays" */ '@attend/student-delay/StudentQr')
      },

      {
        path: routes.supervisionLocation.index,
        name: routes.supervisionLocation.index,
        component: () => import(/* webpackChunkName: "SupervisionLocations" */ '@supervision/supervision-location/Index')
      },
      {
        path: routes.supervisionProcedure.index,
        name: routes.supervisionProcedure.index,
        component: () => import(/* webpackChunkName: "SupervisionProcedures" */ '@supervision/supervision-procedure/Index')
      },
      {
        path: routes.supervisionCourse.index,
        name: routes.supervisionCourse.index,
        component: () => import(/* webpackChunkName: "SupervisionCourses" */ '@supervision/supervision-course/Index')
      },
      {
        path: routes.supervision.index,
        name: routes.supervision.index,
        component: () => import(/* webpackChunkName: "Supervisions" */ '@supervision/supervision/Index')
      },
      {
        path: routes.supervision.myIndex,
        name: routes.supervision.myIndex,
        component: () => import(/* webpackChunkName: "MySupervisions" */ '@supervision/supervision/MyIndex')
      },
      {
        path: routes.supervision.registration,
        name: routes.supervision.registration,
        component: () => import(/* webpackChunkName: "SupervisionRegistrations" */ '@supervision/supervision/Registration')
      },

      {
        path: routes.shiftPeriod.index,
        name: routes.shiftPeriod.index,
        component: () => import(/* webpackChunkName: "ShiftPeriods" */ '@teacher-affair/shift-period/Index')
      },
      {
        path: routes.shiftProcedure.index,
        name: routes.shiftProcedure.index,
        component: () => import(/* webpackChunkName: "ShiftProcedures" */ '@teacher-affair/shift-procedure/Index')
      },
      {
        path: routes.shiftCourse.index,
        name: routes.shiftCourse.index,
        component: () => import(/* webpackChunkName: "ShiftCourses" */ '@teacher-affair/shift-course/Index')
      },
      {
        path: routes.shift.index,
        name: routes.shift.index,
        component: () => import(/* webpackChunkName: "Shifts" */ '@teacher-affair/shift/Index')
      },
      {
        path: routes.shift.myIndex,
        name: routes.shift.myIndex,
        component: () => import(/* webpackChunkName: "MyShifts" */ '@teacher-affair/shift/MyIndex')
      },
      {
        path: routes.shift.registration,
        name: routes.shift.registration,
        component: () => import(/* webpackChunkName: "ShiftRegistrations" */ '@teacher-affair/shift/Registration')
      },
      {
        path: routes.ratingItem.index,
        name: routes.ratingItem.index,
        component: () => import(/* webpackChunkName: "RatingItems" */ '@school-activity/rating-item/Index')
      },
      {
        path: routes.schoolProgram.index,
        name: routes.schoolProgram.index,
        component: () => import(/* webpackChunkName: "SchoolPrograms" */ '@school-activity/school-program/Index')
      },
      {
        path: routes.schoolProgram.rating,
        name: routes.schoolProgram.rating,
        component: () => import(/* webpackChunkName: "SchoolProgramRating" */ '@school-activity/school-program/Rating')
      },
      {
        path: routes.schoolProgram.employee,
        name: routes.schoolProgram.employee,
        component: () => import(/* webpackChunkName: "EmployeeSchoolPrograms" */ '@school-activity/school-program/EmployeeIndex')
      },
      {
        path: routes.schoolProgram.student,
        name: routes.schoolProgram.student,
        component: () => import(/* webpackChunkName: "StudentSchoolPrograms" */ '@school-activity/school-program/StudentIndex')
      },
      {
        path: routes.schoolProgram.studentRegistration,
        name: routes.schoolProgram.studentRegistration,
        component: () => import(/* webpackChunkName: "StudentRegistrations" */ '@school-activity/school-program/StudentRegistration')
      },

      {
        path: routes.transcript.transcriptRecord,
        name: routes.transcript.transcriptRecord,
        component: () => import(/* webpackChunkName: "TranscriptRecords" */ '@transcript/TranscriptRecord')
      },
      {
        path: routes.transcript.setting,
        name: routes.transcript.setting,
        component: () => import(/* webpackChunkName: "TranscriptSettings" */ '@transcript/TranscriptSetting')
      },
      {
        path: routes.transcript.homeworkSetting,
        name: routes.transcript.homeworkSetting,
        component: () => import(/* webpackChunkName: "HomeworkSettings" */ '@transcript/homework-setting/Index')
      },
      {
        path: routes.transcript.homework,
        name: routes.transcript.homework,
        component: () => import(/* webpackChunkName: "Homework" */ '@transcript/homework/Index')
      },
      {
        path: routes.transcript.dailyHomework,
        name: routes.transcript.dailyHomework,
        component: () => import(/* webpackChunkName: "DailyHomework" */ '@transcript/homework/DailyHomework.vue')
      },
      {
        path: routes.transcript.setDailyParticipation,
        name: routes.transcript.setDailyParticipation,
        component: () => import(/* webpackChunkName: "SetDailyParticipations" */ '@transcript/SetDailyParticipation')
      },
      {
        path: routes.transcript.dailyParticipation,
        name: routes.transcript.dailyParticipation,
        component: () => import(/* webpackChunkName: "DailyParticipations" */ '@transcript/DailyParticipation')
      },
      {
        path: routes.transcript.studentNewHomework,
        name: routes.transcript.studentNewHomework,
        component: () => import(/* webpackChunkName: "StudentNewHomework" */ '@transcript/homework/StudentNewIndex')
      },
      {
        path: routes.transcript.studentSentHomework,
        name: routes.transcript.studentSentHomework,
        component: () => import(/* webpackChunkName: "StudentSentHomework" */ '@transcript/homework/StudentSentIndex')
      },

      {
        path: routes.timeTable.index,
        name: routes.timeTable.index,
        component: () => import(/* webpackChunkName: "Timetables" */ '@teacher-affair/time-table/Index')
      },
      {
        path: routes.timeTable.distributionSchedule,
        name: routes.timeTable.distributionSchedule,
        component: () => import(/* webpackChunkName: "DistributionSchedule" */ '@teacher-affair/time-table/DistributionSchedule')
      },
      {
        path: routes.timeTable.assignIndex,
        name: routes.timeTable.assignIndex,
        component: () => import(/* webpackChunkName: "TimetableAssigns" */ '@teacher-affair/time-table/AssignIndex')
      },
      {
        path: routes.timeTable.switchIndex,
        name: routes.timeTable.switchIndex,
        component: () => import(/* webpackChunkName: "TimetableSwitches" */ '@teacher-affair/time-table/SwitchIndex')
      },
      {
        path: routes.timeTable.assignDataEntryReport,
        name: routes.timeTable.assignDataEntryReport,
        component: () => import(/* webpackChunkName: "TimetableAssignDataEntryReport" */ '@teacher-affair/time-table/AssignDataEntryReport')
      },
      {
        path: routes.timeTable.employeeIndex,
        name: routes.timeTable.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeTimetables" */ '@teacher-affair/time-table/EmployeeIndex')
      },
      {
        path: routes.timeTable.studentIndex,
        name: routes.timeTable.studentIndex,
        component: () => import(/* webpackChunkName: "StudentTimetables" */ '@teacher-affair/time-table/StudentIndex')
      },
      {
        path: routes.absence.employeeIndex,
        name: routes.absence.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeAbsences" */ '@teacher-affair/absence/EmployeeIndex')
      },
      {
        path: routes.waitingClass.employeeIndex,
        name: routes.waitingClass.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeWaitingClasses" */ '@teacher-affair/waiting-class/EmployeeIndex')
      },
      {
        path: routes.empPermit.employeeIndex,
        name: routes.empPermit.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeEmpPermits" */ '@teacher-affair/emp-permit/EmployeeIndex')
      },
      {
        path: routes.empPermitDemand.employeeIndex,
        name: routes.empPermitDemand.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeEmpPermitDemand" */ '@teacher-affair/emp-permit-demand/EmployeeIndex')
      },
      {
        path: routes.timeTable.employeeSwitchIndex,
        name: routes.timeTable.employeeSwitchIndex,
        component: () => import(/* webpackChunkName: "EmployeeTimeTableSwitches" */ '@teacher-affair/time-table/EmployeeSwitchIndex')
      },

      {
        path: routes.shop.deliveryType,
        name: routes.shop.deliveryType,
        component: () => import(/* webpackChunkName: "DeliveryTypes" */ '@shop/delivery-type/Index')
      },
      {
        path: routes.shop.category,
        name: routes.shop.category,
        component: () => import(/* webpackChunkName: "Category" */ '@shop/category/Index')
      },
      {
        path: routes.shop.product,
        name: routes.shop.product,
        component: () => import(/* webpackChunkName: "Product" */ '@shop/product/Index')
      },
      {
        path: routes.shop.schoolProduct,
        name: routes.shop.schoolProduct,
        component: () => import(/* webpackChunkName: "SchoolProduct" */ '@shop/product/SchoolIndex')
      },
      {
        path: routes.shop.index,
        name: routes.shop.index,
        component: () => import(/* webpackChunkName: "Shop" */ '@shop/Index')
      },
      {
        path: routes.shop.order,
        name: routes.shop.order,
        component: () => import(/* webpackChunkName: "Orders" */ '@shop/order/Index')
      },
      {
        path: routes.shop.userWallet,
        name: routes.shop.userWallet,
        component: () => import(/* webpackChunkName: "UserWallets" */ '@shop/user-wallet/Index')
      },
      {
        path: routes.shop.employeeWallet,
        name: routes.shop.employeeWallet,
        component: () => import(/* webpackChunkName: "EmployeeWallets" */ '@shop/employee-wallet/Index')
      },
      {
        path: routes.shop.myOrders,
        name: routes.shop.myOrders,
        component: () => import(/* webpackChunkName: "MyOrders" */ '@shop/order/MyIndex')
      },
      {
        path: routes.shop.mySchoolOrders,
        name: routes.shop.mySchoolOrders,
        component: () => import(/* webpackChunkName: "MySchoolOrders" */ '@shop/order/SchoolIndex')
      },
      {
        path: routes.shop.sendSchoolPrizes,
        name: routes.shop.sendSchoolPrizes,
        component: () => import(/* webpackChunkName: "SendSchoolPrizes" */ '@shop/school/SendSchoolPrizes.vue')
      },
      {
        path: routes.shop.providerInquiry,
        name: routes.shop.providerInquiry,
        component: () => import(/* webpackChunkName: "ProviderInquiry" */ '@shop/provider/ProviderInquiry.vue')
      },
      {
        path: routes.shop.providerProducts,
        name: routes.shop.providerProducts,
        component: () => import(/* webpackChunkName: "ProviderProducts" */ '@shop/provider/ProviderProducts.vue')
      },

      {
        path: routes.studentPermit.index,
        name: routes.studentPermit.index,
        component: () => import(/* webpackChunkName: "StudentPermits" */ '@components/student-affair/student-permit/Index')
      },
      {
        path: routes.studentPermit.myIndex,
        name: routes.studentPermit.myIndex,
        component: () => import(/* webpackChunkName: "MyStudentPermits" */ '@components/student-affair/student-permit/StudentIndex')
      },

      {
        path: routes.studentAbsence.index,
        name: routes.studentAbsence.index,
        component: () => import(/* webpackChunkName: "StudentAbsences" */ '@components/student-affair/student-absence/Index')
      },
      {
        path: routes.studentAbsence.myIndex,
        name: routes.studentAbsence.myIndex,
        component: () => import(/* webpackChunkName: "MyStudentAbsences" */ '@components/student-affair/student-absence/StudentIndex')
      },
      {
        path: routes.studentDelay.myIndex,
        name: routes.studentDelay.myIndex,
        component: () => import(/* webpackChunkName: "MyStudentDelays" */ '@components/attend/student-delay/StudentIndex')
      },
      {
        path: routes.studentAbsence.report,
        name: routes.studentAbsence.report,
        component: () => import(/* webpackChunkName: "StudentAbsenceReport" */ '@components/student-affair/student-absence/StudentAbsenceReport')
      },

      {
        path: routes.infractionType.index,
        name: routes.infractionType.index,
        component: () => import(/* webpackChunkName: "InfractionTypes" */ '@teacher-affair/infraction-type/Index')
      },

      {
        path: routes.employeeInfraction.index,
        name: routes.employeeInfraction.index,
        component: () => import(/* webpackChunkName: "EmployeeInfractions" */ '@teacher-affair/employee-infraction/Index')
      },
      {
        path: routes.employeeInfraction.employeeIndex,
        name: routes.employeeInfraction.employeeIndex,
        component: () => import(/* webpackChunkName: "MyEmployeeInfractions" */ '@teacher-affair/employee-infraction/EmployeeIndex')
      },

      {
        path: routes.archive.archiveType,
        name: routes.archive.archiveType,
        component: () => import(/* webpackChunkName: "ArchiveTypes" */ '@school-feature/archive/ArchiveType')
      },
      {
        path: routes.archive.outgoingArchive,
        name: routes.archive.outgoingArchive,
        component: () => import(/* webpackChunkName: "OutgoingArchives" */ '@school-feature/archive/OutgoingArchive')
      },
      {
        path: routes.archive.incomingArchive,
        name: routes.archive.incomingArchive,
        component: () => import(/* webpackChunkName: "IncomingArchives" */ '@school-feature/archive/IncomingArchive')
      },
      {
        path: routes.archive.studentOutgoingArchive,
        name: routes.archive.studentOutgoingArchive,
        component: () => import(/* webpackChunkName: "StudentOutgoingArchives" */ '@school-feature/archive/StudentOutgoingArchive')
      },
      {
        path: routes.archive.studentIncomingArchive,
        name: routes.archive.studentIncomingArchive,
        component: () => import(/* webpackChunkName: "StudentIncomingArchives" */ '@school-feature/archive/StudentIncomingArchive')
      },

      {
        path: routes.employeeDelay.index,
        name: routes.employeeDelay.index,
        component: () => import(/* webpackChunkName: "EmployeeDelays" */ '@teacher-affair/employee-delay/Index')
      },
      {
        path: routes.employeeDelay.employeeIndex,
        name: routes.employeeDelay.employeeIndex,
        component: () => import(/* webpackChunkName: "EmployeeDelaysIndex" */ '@teacher-affair/employee-delay/EmployeeIndex')
      },

      {
        path: routes.notification.studentDelay,
        name: routes.notification.studentDelay,
        component: () => import(/* webpackChunkName: "StudentDelayNotification" */ '@components/notification/StudentDelayNotification')
      },
      {
        path: routes.notification.studentAbsence,
        name: routes.notification.studentAbsence,
        component: () => import(/* webpackChunkName: "StudentAbsenceNotification" */ '@components/notification/StudentAbsenceNotification')
      },

      {
        path: routes.affidavit.details.path,
        name: routes.affidavit.details.name,
        component: () => import(/* webpackChunkName: "AffidavitDetails" */ '@components/affidavit/AffidavitDetails.vue')
      },

      {
        path: routes.studentInfraction.myIndex,
        name: routes.studentInfraction.myIndex,
        component: () => import(/* webpackChunkName: "MyStudentInfractions" */ '@components/student-affair/student-infraction/MyIndex.vue')
      },

      {
        path: routes.circular.index,
        name: routes.circular.index,
        component: () => import(/* webpackChunkName: "CircularsPage" */ '@components/circular/CircularsPage.vue')
      },

      {
        path: routes.notification.index,
        name: routes.notification.index,
        component: () => import(/* webpackChunkName: "NotificationsPage" */ '@components/notification/IndexPage.vue')
      }
    ]
  }
]
