<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-btn
    :color="color"
    type="submit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </app-btn>
</template>

<script>
export default {
  name: 'Submit',
  props: {
    color: {
      default: () => 'primary'
    }
  }
}
</script>
