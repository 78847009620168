/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import API_CONFIG from '@/app/api-config'
import { defaultLocale } from '@/app/config'
import Auth from '@helpers/auth'
import routeAllies from '@routes/config'
import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = API_CONFIG.baseUrl
axios.defaults.headers.common.Locale = defaultLocale
// axios.defaults.headers.common['App-Name'] = process.env.MIX_APP_NAME
// axios.defaults.headers.common['App-Version'] = process.env.MIX_APP_VERSION

let token
if ((token = document.head.querySelector('meta[name=\'csrf-token\']'))) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error(
    'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

let accessToken
if ((accessToken = Auth.getAccessToken())) {
  // console.log(accessToken)
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}
axios.defaults.paramsSerializer = params => Qs.stringify(params,
  { arrayFormat: 'indices' })

// console.log(axios.defaults);

axios.interceptors.response.use(function (response) {
  const { data } = response || {}
  // console.log(data)
  if (data) {
    response.result = data.data
    if (data.message) {
      response.resultMessage = data.message
    }
  }
  response._data = response?.data?.data
  response._message = response?.data?.message
  response._meta = response?.data?.meta
  response._success = response?.data?.success || false
  return response
}, function (error) {
  // console.log(error.response)
  const { response } = error || {}
  const status = response?.status || null
  let url

  if (status === 401) {
    if (window.location.pathname !== (url = routeAllies.auth.logout.path)) {
      window.location.href = url
    }
    // dispatch('logout', true);
  }
  if (status === 403) {
    const { data } = response || {}
    // if (window.location.pathname !== (url = routeAllies.auth.logout.path)) {
    //   alert(data.message)
    // }
    if (data.error_no === 1) {
      // console.log(data)
      if (window.location.pathname !== (url = routeAllies.auth.logout.path)) {
        window.location.href = url
      }
      // else if (window.location.pathname !== (url = routeAllies.errors.noSchoolSetup.path)) {
      //     console.log(12)
      //     // window.location.href = url;
      // }
    } else if (data.error_no === 2) {
      if (window.location.pathname !== (url = routeAllies.auth.logout.path)) {
        window.location.href = url
      }
      // if (window.location.pathname !== (url = routeAllies.errors.noMembership.path)) {
      //     window.location.href = url;
      // }
    } else {
      if (window.location.pathname !== '/' && window.location.pathname !== '') {
        window.location.href = '/'
      }
      // console.log(window.location.pathname);
    }
    // console.log(window.location,response.data)
  }
  if (response && response.data) {
    error.responseError = response.data
  }

  error._data = error?.response?.data?.data
  error._message = error?.response?.data?.message
  error._errors = error?.response?.data?.errors || {}
  return Promise.reject(error)
})

Vue.prototype.$axios = axios
window.axios = axios

export default axios
