<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<template>
  <v-simple-table v-bind="$attrs">
    <template #default>
      <thead class="text-center">
        <tr>
          <template v-for="(h,hIndex) in getHeaders">
            <th
              :key="hIndex.toString()"
              class="text-center"
            >
              {{ h.text }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(day,dayIndex) in days">
          <tr :key="dayIndex.toString()">
            <td class="text-center">
              {{ day.text }}
            </td>
            <template v-for="(c,i) in classes">
              <td
                :key="i.toString()"
                class="text-center"
              >
                <span v-html="findData(c, day)" />
              </td>
            </template>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="printBtn">
        <tr>
          <td :colspan="getHeaders.length">
            <app-btn
              v-if="tablePrintUrl"
              @click="openPrintWindow"
            >
              {{ $t('print') }}
            </app-btn>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TimeTable',
  props: {
    type: {
      type: String,
      default: () => 'employee'
    },
    printBtn: {
      type: Boolean,
      default: () => !0
    },
    printUrl: {
      type: [String, Function],
      default: () => undefined
    },
    url: {
      type: Function,
      default: () => undefined
    },
    items: {
      type: Array,
      default: () => undefined
    }
  },
  data () {
    return {
      loading: !1,
      tableItems: [],
      tablePrintUrl: !1,
      classes: [],
      days: []
    }
  },
  computed: {
    getHeaders () {
      const text = `${this.$tc('choice.SchoolDays', 1)} / ${this.$t('class_period')}`
      let h = this.classes || []
      h = [
        { text },
        ...h
      ]
      return h.map(e => {
        e.align = e.align || 'center'
        e.sortable = !1
        e.filterable = !1
        return e
      })
    }
  },
  mounted () {
    this.ini()
  },
  methods: {
    tableHeaders () {
      if (this.loadingTable) return new Promise()

      this.loadingTable = !0
      return this.$api.methods.resources.teacherAffair.timetableHeaders().then(({ result }) => {
        const { days, classes } = result || {}
        this.classes = classes || []
        this.days = days || []
      }).finally(() => (this.loadingTable = !1))
    },
    fetchData () {
      if (this.loading) return
      this.loading = !0
      this.url().then((r) => {
        const { result } = r || {}
        const { schedule, print_url: printUrl } = result || {}
        schedule && (this.tableItems = schedule)
        if (printUrl && !this.AppIsSmall) {
          this.tablePrintUrl = printUrl
        }
        this.$emit('inserted', r)
      }).finally(() => (this.loading = !1))
    },
    findData (c, day, asRow = !1) {
      let text

      const row = this.tableItems || []
      const elm = row.find((e) => e.school_class_id === c.id && e.school_day_id === day.id) || {}

      if (asRow === true) {
        return elm
      }
      if (this.type === 'employee') {
        const { education_class_id_to_string: educationClass, school_subject_id_to_string: schoolSubject } = elm || {}
        text = (educationClass || '') + (schoolSubject ? ` ${schoolSubject}` : '')
      }
      if (this.type === 'student') {
        const { school_subject_id_to_string: schoolSubject } = elm || {}
        text = schoolSubject
      }

      return text
    },
    openPrintWindow () {
      if (!this.tablePrintUrl || this.AppIsSmall) return
      const windowPrint = window.open(this.tablePrintUrl, 'print_url')
      if (!windowPrint) {
        this.alertError(this.$t('messages.window_blocked'))
      }
    },
    ini () {
      this.tableHeaders()
      if (this.url !== undefined) {
        this.fetchData()
      } else if (this.items !== undefined) {
        this.tableItems = this.items
      }
      this.tablePrintUrl = this.printUrl
    }
  }
}
</script>
