<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-btn
    :class="getClasses"
    :color="color"
    :large="getLarge"
    :min-width="getMinWidth"
    :outlined="outlined"
    :retain-focus-on-click="retainFocusOnClick"
    :tile="tile"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    color: {
      type: String,
      default: () => 'primary'
    },
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    tile: {
      type: Boolean,
      default: () => !1
    },
    outlined: {
      type: Boolean,
      default: () => !1
    },
    retainFocusOnClick: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getClasses () {
      const classes = [
        'app-btn',
        'my-1'
      ]
      // console.log(this.$attrs)
      // if (this.$el && this.$el.getAttribute('class')) {
      //   classes.push(this.$el.getAttribute('class'))
      // console.log();
      // }
      return classes.join(' ')
    },
    getMinWidth () {
      return this.$attrs.icon === undefined ? this.minWidth : undefined
    },
    getLarge () {
      return this.$attrs.large || this.AppIsSmall
    }
  }
}
</script>
