<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <slot name="top" />
      <v-text-field
        ref="input"
        :clearable="getClearable"
        :counter="counter"
        :counter-value="a => a ? a.length : 0"
        :disabled="disabled"
        :error="getErrorMessages.length>0"
        :error-count="getErrorCount"
        :error-messages="[...getErrorMessages,...v.errors]"
        :label="getLabel"
        :placeholder="getPlaceholder"
        :readonly="readonly"
        :value="value"
        v-bind="$attrs"
        v-on="{...$listeners,on:input}"
      />
      <slot />
      <slot name="bottom" />
    </ValidationProvider>
  </app-col-span>
</template>

<script>

import InputsMixins from '@mixins/InputsMixins'

export default {
  name: 'TextInput',
  mixins: [InputsMixins],
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    }
  }
}
</script>
