/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const prefix = 'Front/TeacherAffair/TimeTable'

const Timetable = a => `${prefix}/${a}`

const Employee = (employee, prefix) => Timetable(
 `Employee/${employee}/${prefix}`)
const EducationClass = (eduClass, prefix) => Timetable(
 `EducationClass/${eduClass}/${prefix}`)

export default {
  index (params, config = {}) {
    return params === true
      ? prefix
      : axios.get(prefix, {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true ? prefix : axios.post(prefix, data, config)
  },
  show (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.delete(url, config)
  },

  showByEmployee (employee, config = {}) {
    const url = Employee(employee, 'show')
    return employee === true ? url : axios.get(url, config)
  },
  destroyByEmployee (employee, config = {}) {
    const url = Employee(employee, 'destroy')
    return employee === true ? url : axios.get(url, config)
  },
  updateByEmployee (employee, data, config = {}) {
    const url = Employee(employee, 'update')
    return employee === true ? url : axios.put(url, data, config)
  },

  showByEducationClass (educationClass, config = {}) {
    const url = EducationClass(educationClass, 'show')
    return educationClass === true ? url : axios.get(url, config)
  },
  destroyByEducationClass (educationClass, config = {}) {
    const url = EducationClass(educationClass, 'destroy')
    return educationClass === true ? url : axios.get(url, config)
  },
  updateByEducationClass (educationClass, data, config = {}) {
    const url = EducationClass(educationClass, 'update')
    return educationClass === true ? url : axios.put(url, data, config)
  },

  distributionScheduleData: () => axios.get(
    Timetable('distributionScheduleData')),
  saveScheduleData: form => axios.post(Timetable('saveScheduleData'), form)

}
