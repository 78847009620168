/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  name: 'Profile',
  account: 'Account',
  update: 'Update Profile',
  photo: 'Profile photo',
  account_information: 'Account Information',
  personal_information: 'Personal Information',
  delete_account: 'Delete Account',
  delete_account_desc: 'To delete the account, please enter your password, after deleting the account, your data will be deleted and you will not be able to log in again'
}
