/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

// import moment from 'moment'
import moment from 'moment-hijri'
import Vue from 'vue'
import { defaultLocale } from '@/app/config'

Vue.prototype.$moment = moment
moment.locale(defaultLocale)

export default moment
