/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import { defaultLocale, LOCALE_STORAGE_KEY } from '@/app/config'
import { localize } from '@plugins/vee-validate'
import { locales } from '@plugins/vue-i18n/register'
import { camelCase, isString } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

const defaultAxiosCountDown = 310
export default {
  data () {
    return {
      DEVELOPMENT_MODE: process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    IsAppLocale (locale) {
      return this.AppLocales.find(l => l.code === locale)
    },
    SetAppLocale (locale) {
      this.AppLocale = locale
      this.$nextTick(() => window.location.reload())
      this.sendToApp('setLocale', { locale, restart: !0 })
    },
    IniAppLocale () {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      })
      // console.log(params.lang, this.AppLocales)
      if (params.lang && this.IsAppLocale(params.lang) !== -1) {
        this.AppLocale = `${params.lang}`.toString().toLowerCase()
      } else {
        // console.log(this.$route, lang, window.location.search)
        this.AppLocale = localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLocale
      }
    },
    getPageTitle (number = 2) {
      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const e = (...a) => this.$te(...a)
      const _ = this.$_
      let str = ''

      let {
        name,
        pageName
      } = this.$options || {}
      let routePath = this.$route.path.split('/').pop()
      let routeName = this.$route.name.split('/').pop()
      let $routes

      if (routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      $routes = `$routes.${routeName}`
      if (routeName && e($routes) && isString((str = t($routes)))) {
        return str
      }

      routePath = camelCase(routePath)
      if (routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      if (routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      routeName = camelCase(routeName)
      if (routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      $routes = `$routes.${routeName}`
      // console.log($routes)
      if (routeName && e($routes) && isString((str = t($routes)))) {
        return str
      }

      const pluralize = _.pluralize(
        _.upperFirst(routeName.split('Index').join('').split('index').join('')))
      $routes = `choice.${pluralize}`

      if ($routes && e($routes) && isString((str = tc($routes, number)))) {
        return str
      }
      // console.log($routes)
      if (e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if (e(name) && isString((str = t(name)))) {
        return str
      }

      name = camelCase(name)
      if (e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if (e(name) && isString((str = t(name)))) {
        return str
      }

      if (e(pageName) && isString((str = t(pageName)))) {
        return str
      }

      return ''
    },

    parseArabicTitle (string, locale) {
      locale = locale || this.AppLocale
      if (string && locale === 'ar') {
        let i
        const e = string.split(' ')
        // console.log(e)
        for (i in e) {
          if (e[i].substr(0, 2) === 'ال') {
            e[i] = e[i].substr(2)
          }
        }
        string = e.join(' ')
      }
      return string
    },

    parseAttribute (string) {
      if (!string) return string

      const t = (...a) => this.$t(...a)

      const te = (...a) => this.$te(...a)

      const key = (this.$helpers.isOnlyObject(string)
        ? (string.text || '')
        : string).toString()

      if (te(key) && isString(t(key))) return t(key)

      if (te(`attributes.${key}`) && isString(t(`attributes.${key}`))) {
        return t(
         `attributes.${key}`)
      }
      return string
      // return te(key) ? t(key) : (te(`attributes.${key}`) ? t(`attributes.${key}`) : key);
    },

    getAxiosName () {
      return this.$options.name
    },
    getAxiosItems () {
      return this.getIniAxios(this.getAxiosName())
    },
    setIniAxios (name, v) {
      this.$root.iniAxios[name] = v
      // console.log(name,"Set",this.$root.iniAxios[name],v)
    },
    getIniAxios (name) {
      return null
      // console.log(name,"GET",this.$root.iniAxios[name])
      return this.$root.iniAxios[name]
    },
    increaseAxios (countdown = defaultAxiosCountDown) {
      countdown = parseInt(countdown) || defaultAxiosCountDown
      this.$root.AxiosCountdown += countdown
      // console.log(this.$root.AxiosCountdown);
      return this.getAxiosCountdown()
    },
    setAxiosCountdown (countdown = defaultAxiosCountDown) {
      this.$root.AxiosCountdown = countdown
    },
    getAxiosCountdown () {
      return this.$root.AxiosCountdown
    },
    queueAxios (func, countdown = defaultAxiosCountDown) {
      // console.log(countdown);
      setTimeout(() => setTimeout(() => this.$nextTick(func),
        this.increaseAxios(countdown)), 60)
    },

    sendToApp (action = '', data = {}) {
      if (window.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ action, data }))
      }
    }
  },
  computed: {
    DefaultAppLocale: () => defaultLocale,
    AppRtl: {
      set (v) {
        this.$root.$vuetify.rtl = v
      },
      get () {
        const { rtl } = this.$root.$vuetify || {}
        return rtl !== undefined ? rtl : true
      }
    },
    AppDirection: {
      set (v) {
        this.AppRtl = v.toLowerCase() === 'rtl'
      },
      get () {
        return this.AppRtl ? 'rtl' : 'ltr'
      }
    },
    AppAlign: {
      set (v) {
        this.AppRtl = v.toLowerCase() === 'right'
      },
      get () {
        return this.AppRtl ? 'right' : 'left'
      }
    },
    AppLocales () {
      return locales.map(code => ({
        title: this.$t(code),
        code
      }))
    },
    AppLocale: {
      set (locale = defaultLocale) {
        locale = ('' + locale).toString().toLocaleLowerCase()

        !this.IsAppLocale(locale) && (locale = defaultLocale)

        localStorage.setItem(LOCALE_STORAGE_KEY, locale)
        document.documentElement.setAttribute('lang', locale)
        document.documentElement.setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

        // Vuetify
        this.$root.$i18n.locale = locale
        this.$root.$vuetify.lang.current = locale
        this.$root.$vuetify.rtl = locale === 'ar'

        // axios
        this.$axios.defaults.headers.common.Locale = locale

        // Vuex
        const validations = require(`@locales/${locale}/validations.js`).default
        localize(locale, validations.default || validations)

        // moment js
        this.$moment.locale(locale)
        // this.sendToApp('setLocale', { locale, restart: !1 })
      },
      get () {
        return this.$root.$vuetify.lang.current
      }
    },
    themeDark: {
      get () {
        return Boolean(mapGetters('auth', ['themeDark']).themeDark.call(this))
      },
      set (value) {
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeLight: {
      get () {
        // console.log(this.themeDark)
        return !this.themeDark
      },
      set (value) {
        value = !value
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeColor () {
      return this.themeDark ? 'dark' : 'light'
    },
    AppIsSmall () {
      return this.$root.$vuetify.breakpoint.smAndDown || !1
    },
    AppIsMobile () {
      return Boolean(window?.isMyThApp)
    },

    authUser: {
      set (user) {
        mapMutations('auth', ['setUser']).setUser.call(this, user)
      },
      get () {
        return mapGetters('auth', ['getUser']).getUser.call(this)
      }
    },

    authSchool: {
      set (school) {
        mapMutations('auth', ['setSchool']).setSchool.call(this, school)
      },
      get () {
        return mapGetters('auth', ['getSchool']).getSchool.call(this)
      }
    },
    apiService () {
      return this.$api.methods
    },
    isAdmin () {
      return this.authUser?.is_admin === !0
    },
    isEmployee () {
      return this.authUser?.is_employee === !0
    },
    isStudent () {
      return this.authUser?.is_student === !0
    }
  },
  mounted () {
    // console.log('mounted')
  }
}
