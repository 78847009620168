/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  '#': 'م',
  send: 'إرسال',
  about_us: 'من نحن',
  terms_conditions: 'الشروط والأحكام',
  privacy_policy: 'سياسة الخصوصية',
  NoResults: 'لا توجد نتائج',
  SA: 'المملكة العربية السعودية',
  TranscriptSetting: 'إعداد كشف أعمال السنة',
  accept: 'قبول',
  accepted: 'مقبول',
  activate: 'تفعيل',
  active: 'فعال',
  add: 'إضافة',
  add_new: 'إضافة جديد',
  all: 'الكل',
  all_week: 'طوال الاسبوع',
  allow: 'مسموح',
  am: 'ص',
  and: 'و',
  andJoin: 'و',
  and_implode: ' و',
  apply: 'تطبيق',
  approve: 'قبول',
  approveInfraction: 'اعتماد المخالفة',
  approve_program: 'إعتماد البرنامج',
  ar: 'العربية',
  archiveTypeLabel: 'فئة المستند',
  are_you_sure: 'هل أنت متأكد؟',
  attendanceRegistration: 'تسجيل حضور',
  avatar: 'الصورة',
  back: 'رجوع',
  barcode: 'باركود',
  'barcode4-6': 'باركود 6*4',
  browse: 'تصفح',
  cancel: 'إلغاء',
  cancelInfraction: 'الغاء المخالفة',
  cancel_approve: 'الغاء الاعتماد',
  change: 'تعديل',
  choose: 'اختر',
  choose_switch_class: 'اختر أحد الحصص المتاحة للتبديل',
  class_period: 'الحصة',
  clear: 'مسح',
  close: 'إغلاق',
  close_registration: 'إغلاق التسجيل',
  close_window: 'اغلاق النافذة',
  coin: 'نقطة',
  coins: 'النقاط',
  confirm: 'تأكيد',
  contact_us: 'تواصل معنا',
  contact_us_management: 'التواصل معنا',
  control: 'تحكم',
  create: 'إضافة',
  create_time_tables: 'اعادة توزيع الجدول المدرسي',
  dashboard: 'الإحصائيات',
  data_and: ' و',
  date: 'التاريخ',
  day: 'اليوم',
  default: 'الافتراضي',
  degree: 'الدرجة',
  delayedRegistration: 'تسجيل تأخر',
  delete: 'حذف',
  deleteData: 'حذف البيانات',
  delete_all_term_rows: 'افراغ جميع قيود المعلمين',
  delete_all_time_tables: 'افراغ اسنادات المعلمين',
  delete_print_header: 'حذف ترويسة الطباعة',
  delete_time_tables: 'حذف الجدول المدرسي',
  delivery_fees: 'رسوم التوصيل',
  destroy: 'حذف',
  details: 'التفاصيل',
  disable: 'تعطيل',
  disabled: 'معطل',
  distributionSchedule: 'توزيع الجدول',
  done: 'موافق',
  download_example_file: 'تحميل ملف ملأ البيانات',
  edit: 'تعديل',
  employeeScheduleHint: 'قم بالنقر على الحصة للتبديل في حصة فارغة',
  employee_shifting_name: 'اسم الموظف المناوب',
  en: 'English',
  end_rating: 'إنهاء التقييم',
  error: 'خطأ !!',
  expansionPanelHeaderOptions: 'الأدوات',
  export: 'تصدير',
  exportExcel: 'تصدير البيانات Excel',
  exportPdf: 'تصدير البيانات PDF',
  filter: 'تصفية',
  finishOrder: 'إنهاء الطلب',
  finish_program: 'إنهاء البرنامج',
  force_delete: 'حذف نهائياً',
  forgot_your_password: 'نسيت كلمة المرور',
  reset_password: 'إعادة تعيين كلمة المرور',
  from: 'من',
  gift: 'هدية مجانية',
  hello: 'مرحباَ',
  home: 'الرئيسية',
  id: '#',
  image: 'الصورة',
  import: 'استيراد',
  importStudents: 'استيراد بيانات الطلاب',
  index: 'القائمة',
  is_coins_returned: 'تم ارجاع النقاط',
  last_login: 'أخر دخول',
  late_students: 'أسماء الطلاب المتأخرين',
  login: 'تسجيل الدخول',
  logout: 'تسجيل الخروج',
  management: 'الإدارة',
  membershipTypesSelect: 'نوع الاشتراك',
  ministry_of_education: 'وزارة التعليم',
  na: '-',
  no: 'لا',
  no_membership: 'لا يوجد اشتراك',
  none: 'لا يوجد',
  not_sent: 'لم يتم الإرسال',
  now: 'الآن',
  numOfCopies: 'عدد النسخ',
  official_paper: 'ورق عادي',
  oh_noes: 'حدث خطأ!',
  open_registration: 'فتح التسجيل',
  options: 'خيارات',
  pending: 'قيد التنفيذ',
  period_total: 'المجموع',
  please_fill_in_this_field: 'يرجى ملء هذا الحقل بالشكل الصحيح',
  pm: 'م',
  print: 'طباعة',
  printSchoolSchedule: 'طباعة الجدول المدرسي',
  print_access: 'طباعة اذن دخول',
  print_daily_groups: 'طباعة الحضور اليومي للمجموعات',
  print_preview: 'معاينة الطباعة',
  data_preview: 'معاينة البيانات',
  data_preview_tooltip: 'معاينة البيانات وعرضها قبل الاستيراد',
  profile: 'الملف الشخصي',
  program_rate: 'تقييم البرنامج',
  progress: 'جارٍ...',
  q: '؟',
  qr_code_capture: 'صورة الباركود',
  qr_scanner: 'ماسح الباركود',
  quantity: 'الكمية',
  rate: 'تقييم',
  receipt: 'ايصال',
  refreshTable: 'تحديث البيانات',
  regards: 'مع تحياتنا',
  register: 'تسجيل',
  reject: 'رفض',
  remaining_time_table_assigns: 'الإسنادات المتبقية',
  restore: 'استرجاع',
  return_coins: 'النقاط المسترجعة',
  sar: 'ريال',
  save: 'حفظ',
  save_changes: 'حفظ التعديلات',
  save_schedule: 'حفظ الجدول',
  schedule_distribution: 'توزيع الجدول',
  schoolClass: 'فصل مدرسي',
  search: 'بحث',
  select: 'اختيار',
  selectAll: 'تحديد الكل',
  send_homework: 'ارسال الواجب',
  send_homework_date: 'تاريخ ارسال الواجب',
  set_degree: 'رصد الدرجات',
  set_participation: 'رصد مشاركة طالب',
  set_participation_with_coins: 'مشاركة + اهداء نقاط',
  setup: 'الضبط',
  shiftReportHint: 'يتم تسليم هذا التقرير إلى وكيل شؤون الطلاب في اليوم التالي للمناوبة.',
  shop: 'المتجر المدرسي',
  show: 'عرض',
  showPermissions: 'عرض الصلاحيات',
  sign_out: 'تسجيل الخروج',
  signature: 'التوقيع',
  start_rating: 'فتح التقييم',
  status: 'الحالة',
  store: 'إضافة',
  studentQrScanner: 'استعلام عن طالب',
  student_name: 'اسم الطالب',
  switch: 'تبديل',
  switch_changes: 'تغييرات طلب التبديل',
  switch_hint: 'يمكن ارسال طلب للإدارة بطلب تبديل الحصص إن امكن',
  switch_request: 'تغيير الحصة',
  switch_request_from: 'طلب تبديل حصص :From',
  teacher: 'معلم',
  teacher_question: 'مساءلة',
  template_notify: 'الطباعة والتنبيهات',
  text_copied: 'تم نسخ البيانات',
  there_is_an_error: 'يوجد خطأ الرجاء المحاولة لاحقاً',
  time_table_assigns: 'الإسنادات',
  to: 'إلى',
  today: 'اليوم',
  trashed_index: 'قائمة المحذوفات',
  truncate_schedule: 'افراغ بيانات الجدول',
  unacceptable: 'غير مسموح',
  unregister: 'إلغاء التسجيل',
  update: 'تعديل',
  view: 'عرض',
  violating_students: 'أسماء الطلاب المخالفين',
  whoops: 'اووبس',
  yes: 'نعم',
  print_employees_schedule: 'طباعة جدول المعلمين',
  print_edu_schedule: 'طباعة جدول الفصول',
  minutes: 'دقائق',
  minute: 'دقيقة',
  confirm_absence: 'تأكيد الغياب الصباحي',
  subscription: 'الاشتراك',
  sponsors: 'الرعاة',
  myCoins: 'نقاطي',
  partners: 'شركاؤنا',
  remaining: 'المتبقي',
  sold: 'المباع',
  available_quantity: 'الكمية المتوفرة',
  copy: 'نسخ',
  new_affidavit: 'إفادة'
}
