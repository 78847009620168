/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const url = StubUrl('Front/GuidanceCounselor/InfractionName')

export default {
  ...Stub(url()),
  allIndex (params, ...config) {
    const u = 'Resources/School/GuidanceCounselor/InfractionName'
    return params === !0 ? u : axios.get(u, { params, ...config })
  }
}
