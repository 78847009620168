<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<template>
  <app-row v-bind="$attrs">
    <slot name="prepend" />
    <app-auto-select
      v-model="form.education_stage_id"
      :hide-selected="!showSelected"
      :items="educationStages"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="education_stage_id"
    />
    <app-auto-select
      v-if="eduGrade"
      v-model="form.education_grade_id"
      :hide-selected="!showSelected"
      :items="educationGrades"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="education_grade_id"
    />
    <app-auto-select
      v-if="eduClass"
      v-model="form.education_class_id"
      :chips="classChips"
      :deletable-chips="classDeletableChips"
      :hide-selected="!showSelected"
      :items="educationClasses"
      :loading="loading"
      :multiple="multipleClass"
      :rules="getRules"
      :small-chips="classSmallChips"
      cols="12"
      md="6"
      name="education_class_id"
    />
    <slot />
    <slot name="append" />
  </app-row>
</template>

<script>

export default {
  name: 'EducationStagesRow',
  props: {
    required: {
      type: Boolean,
      default: () => undefined
    },
    form: {
      type: Object,
      required: true
    },
    eduGrade: {
      type: Boolean,
      default: () => true
    },
    eduClass: {
      type: Boolean,
      default: () => true
    },
    multipleClass: {
      type: Boolean,
      default: () => undefined
    },
    showSelected: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    const urls = this.$api.methods.educationStage
    return {
      urls,
      loading: !1,
      educationStages: [],
      formRules: []
    }
  },
  computed: {
    selectedStage () {
      const form = this.form || {}
      const id = form.education_stage_id || undefined
      const selected = this.educationStages.find(e => e.id === id)
      if (!id || !selected) return []
      return selected
    },
    educationGrades () {
      const selected = this.selectedStage
      if (!selected || !selected.education_grades) return []
      return selected.education_grades
    },
    selectedGrade () {
      const form = this.form || {}
      const id = form.education_grade_id || undefined
      const selected = this.educationGrades.find(e => e.id === id)
      if (!id || !selected) return []
      return selected
    },
    educationClasses () {
      const selected = this.selectedGrade
      if (!selected || !selected.education_classes) return []
      return selected.education_classes
    },
    getRules () {
      const r = this.formRules
      this.required === true && r.indexOf('required') < 0 && r.push('required')
      return r.join('|')
    },
    classChips () {
      return this.multipleClass || undefined
    },
    classDeletableChips () {
      return this.multipleClass || undefined
    },
    classSmallChips () {
      return this.multipleClass || undefined
    }
  },
  watch: {
    'form.education_stage_id' (n, o) {
      o && (this.form.education_grade_id = undefined)
    },
    'form.education_grade_id' (n, o) {
      o && (this.form.education_class_id = undefined)
    }
  },
  mounted () {
    this.$nextTick(() => this.fetchEducationStages())
  },
  methods: {
    fetchEducationStages () {
      if (this.loading) return
      this.loading = !0
      const items = this.getAxiosItems()
      if (items !== undefined && items !== null) {
        this.setEducationStages(items)
        this.loading = !1
        return
      }

      this.setEducationStages([])

      setTimeout(() => {
        this.urls.getSchoolSelect().then(({ data }) => {
          if (data && data.data) {
            this.setEducationStages(data.data)
            this.setIniAxios(this.getAxiosName(), data.data)
          }
        }).catch(() => this.setEducationStages([])).finally(() => this.loading = !1)
      }, this.increaseAxios())
    },
    setEducationStages (v) {
      this.educationStages = v
    }
  }
}
</script>
