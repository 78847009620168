<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-list :dense="!AppIsSmall">
    <template v-for="(item, i) in items">
      <app-list-item
        :key="i.toString()"
        :item="item"
      />
    </template>
  </v-list>
</template>

<script>

export default {
  name: 'DrawerList',
  props: ['items']
}
</script>
