/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  switchTimeTable: {
    school_day_id: 'اختر اليوم الخاص بك',
    school_class_id: 'اختر الحصة الخاصة بك',
    switch_employee_id: 'اختر المعلم للتبديل معه',
    switch_school_day_id: 'قم باختيار اليوم الجديد',
    switch_school_class_id: 'قم باختيار الحصة الجديدة'
  },
  studentAbsencesIndex: 'Absence report according to morning attendance, it is not recorded in the system',
  studentDelayNotification: {
    send: 'قم باختيار الفصل المدرسي أو اختيار تاريخ'
  },
  studentAbsenceNotification: {
    send: 'قم باختيار الفصل المدرسي أو اختيار تاريخ'
  }
}
