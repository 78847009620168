/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default {
  studentAttendQrIndex: 'ماسح الحضور الصباحي',
  studentDelayQrIndex: 'ماسح التأخر الصباحي',
  employeeTimeTableIndex: 'جدولي',
  employeeAbsenceIndex: 'غياباتي',
  employeeWaitingClassIndex: 'حصص الانتظار',
  employeeEmpPermitIndex: 'الخروج من الدوام',
  employeeEmpPermitDemandIndex: 'طلبات الخروج من الدوام',
  employeeSwitchTimeTableIndex: 'جدولة طلبات التبديل',
  setDailyParticipationIndex: 'رصد المشاركات اليومية',
  shop: 'التخفيضات',
  myOrders: 'طلباتي',
  aboutUs: 'من نحن',
  contactUs: 'تواصل معنا',
  termsConditions: 'الشروط والأحكام',
  privacyPolicy: 'سياسة الخصوصية',
  [routes.studentAttend.confirmAbsences]: 'غياب تأخر الطالب الصباحي',
  [routes.notification.studentDelay]: 'إرسال إشعارات تأخر الطلاب',
  [routes.notification.studentAbsence]: 'إرسال إشعارات غياب الطلاب',
  [routes.studentAbsence.report]: 'لائحة المواظبة',
  [routes.shop.providerInquiry]: 'استعلام الخصومات',
  [routes.shop.providerProducts]: 'منتجاتي',
  [routes.affidavit.details.name]: 'الإفادات'
}
