/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import Vue from 'vue'
import Vuex from 'vuex'
import modules from './register'

Vue.use(Vuex)

const
  state = {}
const getters = {}
const actions = {}
const mutations = {}

const options = {
  modules,
  state,
  getters,
  actions,
  mutations
}
// console.log(modules)
export default new Vuex.Store(options)
