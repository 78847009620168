/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  code: 'en',
  messages: {
    alpha: 'The {_field_} field may only contain alphabetic characters',
    alpha_num: 'The {_field_} field may only contain alpha-numeric characters',
    alpha_dash: 'The {_field_} field may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces: 'The {_field_} field may only contain alphabetic characters as well as spaces',
    between: 'The {_field_} field must be between {min} and {max}',
    confirmed: 'The {_field_} field confirmation does not match',
    digits: 'The {_field_} field must be numeric and exactly contain {length} digits',
    dimensions: 'The {_field_} field must be {width} pixels by {height} pixels',
    email: 'The {_field_} field must be a valid email',
    excluded: 'The {_field_} field is not a valid value',
    ext: 'The {_field_} field is not a valid file',
    image: 'The {_field_} field must be an image',
    integer: 'The {_field_} field must be an integer',
    length: 'The {_field_} field must be {length} long',
    max_value: 'The {_field_} field must be {max} or less',
    max: 'The {_field_} field may not be greater than {length} characters',
    mimes: 'The {_field_} field must have a valid file type',
    min_value: 'The {_field_} field must be {min} or more',
    min: 'The {_field_} field must be at least {length} characters',
    numeric: 'The {_field_} field may only contain numeric characters',
    oneOf: 'The {_field_} field is not a valid value',
    regex: 'The {_field_} field format is invalid',
    required_if: 'The {_field_} field is required',
    required: 'The {_field_} field is required',
    size: 'The {_field_} field size must be less than {size}KB',
    mobile: 'The {_field_} not valid'
  },
  names: require('./attributes').default,
  fields: {}
}
