/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const prefix = 'Front/SchoolActivity/SchoolProgram'

export default {
  index (params, config = {}) {
    return params === true
      ? prefix
      : axios.get(prefix, {
        ...config,
        params
      })
  },
  employeeIndex (params, config = {}) {
    const url = `${prefix}/EmployeePrograms`
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  studentIndex (params, config = {}) {
    const url = `${prefix}/StudentPrograms`
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true ? prefix : axios.post(prefix, data, config)
  },
  show (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.delete(url, config)
  },
  rate (id, data = {}, config = {}) {
    const url = `${prefix}/${id}/Rate`
    return id === true ? url : axios.put(url, data, config)
  },

  setRegistration (id, params, config = {}) {
    const url = `${prefix}/${id}/setRegistration`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  setAccepted (id, params, config = {}) {
    const url = `${prefix}/${id}/setAccepted`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  setRating (id, params, config = {}) {
    const url = `${prefix}/${id}/setRating`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  setFinished (id, params, config = {}) {
    const url = `${prefix}/${id}/setFinished`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  setApproved (id, params, config = {}) {
    const url = `${prefix}/${id}/setApproved`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  setUnApproved (id, params, config = {}) {
    const url = `${prefix}/${id}/setUnApproved`
    return id === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },

  registration (programs, config = {}) {
    const url = `${prefix}/Registration`
    return programs === true ? url : axios.put(url, programs, config)
  }
}
