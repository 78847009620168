/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import api from '@/app/api-config'
import routes from '@routes/config'

export const defaultLocale = 'ar'
export const rtl = true
export const APP_DARK = true
export const LOCALE_STORAGE_KEY = 'locale'
export const THEME_DARK_STORAGE = 'theme_dark'

const APP_CONFIG = {
  api,
  routes
}

export default APP_CONFIG
