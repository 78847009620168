/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'
import axios from 'axios'

const url = StubUrl('Front/School/Affidavit')
export default {
  ...Stub(url()),
  detailsUrl: (id, type) => url(`Details/${id}/t/${type}`),
  details (id, type, config = {}) {
    const p = this.detailsUrl(id, type)
    return id === !0 ? p : axios.get(p, config)
  },
  storeAffidavitable (id, type, form, config = {}) {
    const p = url(`Store/${id}/t/${type}`)
    return id === !0 ? p : axios.post(p, form, config)
  }
}
