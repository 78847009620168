/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from './Stub'

const prefix = 'Front/Student'
const url = StubUrl(prefix)

export default {
  ...Stub(prefix),
  import (form) {
    if (form instanceof FormData) {
      form.append('_method', 'POST')
    }
    return axios.post(url('Import'), form)
  },
  exportFromImport: rows => axios.post(url('Import'), { export: !0, rows }, { responseType: 'blob' }),
  qrScanner: qr => axios.post(url('Qr-Scanner'), { student_id: qr })
}
