/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  '#': 'م',
  Both_absent_user_id: 'المعلم الغائب',
  Both_waiting_user_id: 'المعلم المنتظر',
  Female_absent_user_id: 'المعلمة الغائبة',
  Female_waiting_user_id: 'المعلمة المنتظرة',
  Male_absent_user_id: 'المعلم الغائب',
  Male_waiting_user_id: 'المعلم المنتظر',
  absent_employee_id: 'المعلم الغائب',
  action_date: 'تاريخ الاجراء',
  action_taken: 'الإجراء المتخذ',
  actions: 'خيارات',
  active: 'فعال',
  active_to_string: 'الحالة',
  add_answer: 'اضافة اجابة',
  add_question: 'اضافة سؤال',
  address: 'العنوان',
  answer: 'الإجابة',
  answer_name: 'الإجابة',
  answers: 'الاجابات',
  app_country_id: 'الدولة',
  app_name_ar: 'اسم الموقع بالعربي',
  app_name_en: 'اسم الموقع بالانجليزي',
  app_region_id: 'المدينة/المنطقة',
  approve_action: 'اعتماد',
  approved: 'تم الاعتماد',
  archive_category: 'فئة المستند',
  archive_type_id: 'نوع المستند',
  arrive_time: 'وقت الحضور',
  attend_date: 'تاريخ الحضور',
  attend_time: 'وقت الحضور',
  automatic_absence_waiting: 'توزيع الانتظار التلقائي',
  balance: 'الرصيد',
  both_student_id: 'الطالب/الطالبة',
  both_teacher_id: 'المعلم/المعلمة',
  can_login: 'إمكانية تسجيل الدخول',
  cancel_reason: 'سبب الإلغاء',
  categories: 'الأقسام',
  'categories.*': 'القسم',
  category_id: 'القسم',
  city_id: 'المدينة/المنطقة',
  city_key: 'مفتاح المدينة/المنطقة',
  civil_registry: 'السجل المدني',
  code: 'الكود',
  coin: 'نقطة',
  coins: 'النقاط',
  contact_data: 'بيانات الإتصال',
  contact_number: 'رقم التواصل',
  control: 'تحكم',
  correct_answer: 'إجابة صحيحة',
  country_code: 'رمز الدولة',
  country_id: 'الدولة',
  country_key: 'مفتاح الدولة',
  created_at: 'تاريخ الإضافة',
  created_by_id: 'اضيف بواسطة',
  currency_code: 'كود العملة',
  current_password: 'كلمة المرور الحالية',
  customer_id: 'العميل',
  date: 'التاريخ',
  date_delay_degree: 'درجة حسم التخصيص',
  day: 'اليوم',
  day_delay_degree: 'تخصيص يوم الحسم',
  day_key: 'مفتاح اليوم',
  default: 'افتراضي',
  default_delay_degree_deduction: 'درجة حسم التأخر الصباحي الافتراضية',
  degree: 'الدرجة',
  degree_deduction: 'درجة الحسم',
  degree_deduction_count: 'الدرجات المحسومة',
  degree_rating: 'درجة التقييم',
  'degree_rating.*': 'درجة التقييم',
  delay_date: 'تاريخ التأخر',
  delay_minutes: 'دقائق التأخر',
  delay_time: 'وقت التأخر',
  delays_count: 'عدد التأخرات',
  delivery_date: 'تاريخ التوصيل',
  delivery_fees: 'رسوم التوصيل',
  delivery_type_id: 'طريقة التوصيل',
  demand_kind_id: 'نوع الخدمة',
  description: 'الوصف',
  description_ar: 'الوصف بالعربية',
  description_en: 'الوصف بالانجليزية',
  details: 'البيانات',
  disable: 'معطل',
  double: 'مزدوج',
  education_class2_id: 'الفصل',
  education_class_group_id: 'مجموعة الفصل',
  education_class_id: 'الفصل المدرسي',
  education_classes: 'الفصول المدرسية',
  education_department_id: 'إدارة التعليم',
  education_grade_id: 'المرحلة الدراسية',
  education_id: 'التعليم',
  education_major_id: 'التخصص',
  education_office_id: 'مكتب التعليم',
  education_program_id: 'برنامج التعليم',
  education_specialization_id: 'التخصص التعليمي',
  education_stage_id: 'المرحلة التعليمية',
  education_stages: 'المراحل التعليمية',
  education_system_id: 'نظام التعليم',
  email: 'البريد الإلكتروني',
  email_confirmation: 'تأكيد البريد الإلكتروني',
  email_contact: 'بريد التواصل معنا',
  email_content: 'نص رسالة البريد',
  email_content_ar: 'نص رسالة البريد بالعربية',
  email_content_en: 'نص رسالة البريد بالإنجليزية',
  employee_id: 'المعلم',
  employee_name: 'اسم المعلم',
  employees: 'المعلمين',
  end_date: 'تاريخ النهاية',
  end_date_delay_degree: 'نهاية الحسم المخصص',
  excuse_id: 'الإجراء',
  extra_procedures: 'الإجرائات المتخذة',
  female_student_id: 'الطالبة',
  female_teacher_id: 'المعلمة',
  financial_status_id: 'الحالة المادية',
  first_address: 'العنوان 1',
  first_class_repeat: 'تخصيص عدد الحصة الأولى',
  first_name: 'الاسم الاول',
  fortified: 'محصن',
  from_date: 'التاريخ من',
  gender_code: 'كود الجنس',
  gender_id: 'الجنس',
  gifted_id: 'المستخدم المهدى',
  google_map: 'جوجل ماب',
  grades: 'الدرجات',
  'grades.*.homework': 'الواجب',
  'grades.*.monthly_test': 'الاختبار الشهري',
  'grades.*.participate': 'المشاركة',
  group: 'المجموعة',
  guardian_id: 'ولي الأمر',
  guardian_mobile: 'جوال ولي الامر',
  guardian_name: 'اسم ولي الامر',
  has_employee_list: 'إظهار قائمة الموظفين عند الإرسال',
  has_student_list: 'إظهار قائمة الطلاب عند الإرسال',
  have_coins: 'يوجد نقاط',
  have_reason: 'يوجد عذر',
  health_status_id: 'الحالة الصحية',
  homework: 'الواجب',
  homework_content: 'بيانات الواجب',
  homework_date: 'تاريخ الواجب',
  homework_id: 'الواجب',
  homework_received_date: 'تاريخ تسليم الواجب',
  homework_setting_answer_id: 'الإجابة',
  homework_setting_id: 'اعداد الواجب',
  homework_setting_name: 'اسم اعداد الواجد',
  homework_setting_question_id: 'السؤال',
  homework_title: 'عنوان الواجب',
  id_number: 'رقم الهوية',
  image: 'الصورة',
  images: 'الصور',
  implementation: 'التنفيذ',
  incoming: 'إستلام',
  infraction_categories: 'فئات المخالفة',
  infraction_category_id: 'فئة المخالفة',
  infraction_date: 'تاريخ المخالفة',
  infraction_day: 'يوم المخالفة',
  infraction_groups: 'إجراءات اسم المخالفة',
  infraction_hijri_ar: 'تاريخ المخالفة هجري عربي',
  infraction_hijri_en: 'تاريخ المخالفة هجري انجليزي',
  infraction_level_id: 'درجة المخالفة',
  infraction_levels: 'درجات المخالفة',
  infraction_name_id: 'اسم المخالفة',
  infraction_no: 'رقم المخالفة',
  infraction_type_id: 'المخالفة',
  invoice_prefix: 'بادئة الطلبات',
  is_active: 'نشط',
  is_approved: 'مقبول',
  is_coins_returned: 'تم ارجاع النقاط',
  is_read: 'تم القراءة',
  is_withdraw: 'تم الخصم',
  iso_2: 'ISO-2',
  iso_3: 'ISO-3',
  job_id: 'الوظيفة',
  job_title_id: 'المسمى الوظيفي',
  jobs: 'الوظائف',
  key: 'المفتاح',
  language_code: 'كود اللغة',
  last_class_repeat: 'تخصيص عدد الحصة الأخيرة',
  last_name: 'الاسم الاخير',
  late_students: 'الطلاب المتأخرين',
  leader_id: 'مدير المدرسة',
  leave_time: 'وقت الخروج',
  leave_type: 'طريقة الانصراف',
  limit_shifts: 'نصاب المناوبة',
  limit_supervisions: 'نصاب الإشراف اليومي',
  limit_time_table: 'نصاب الحصص الاسبوعي',
  loadMore: 'عرض المزيد',
  locale: 'اللغة',
  login_id: 'اسم المستخدم',
  long_name: 'الاسم الطويل',
  long_name_ar: 'الإسم الطويل بالعربية',
  long_name_en: 'الإسم الطويل بالانجليزية',
  male_student_id: 'الطالب',
  male_teacher_id: 'المعلم',
  manager_email: 'بريد مدير الجهة',
  manager_mobile: 'جوال مدير الجهة',
  manager_name: 'اسم مدير الجهة',
  max_class_repeat: 'اقصى عدد متتالي للحصص في الجدول',
  max_first_class: 'أكثر عدد للحصة الأولى',
  max_last_class: 'أكثر عدد للحصة الأخيرة',
  membership_date: 'تاريخ الاشتراك',
  membership_end_date: 'نهاية الاشتراك',
  membership_end_date_from: 'تاريخ نهاية الاشتراك - من',
  membership_end_date_to: 'تاريخ نهاية الاشتراك - إلى',
  membership_period: 'مدة الاشتراك',
  membership_type: 'نوع الاشتراك',
  membership_type_id: 'نوع الاشتراك',
  message: 'الرسالة',
  min_first_class: 'اقل عدد للحصة الأولى',
  min_last_class: 'اقل عدد للحصة الأخيرة',
  min_price: 'اقل سعر',
  mobile: 'الجوال',
  mobile_content: 'نص رسالة الجوال',
  mobile_content_ar: 'نص رسالة الجوال بالعربية',
  mobile_content_en: 'نص رسالة الجوال بالإنجليزية',
  monthly_test: 'الاختبار الشهري',
  myAccount: 'حسابي',
  myCoins: 'نقاطي',
  name: 'الإسم',
  name_ar: 'الإسم بالعربية',
  name_en: 'الإسم بالانجليزية',
  nationality_id: 'الجنسية',
  net_total: 'المجموع النهائي',
  new_school_class_id: 'الحصة الجديدة',
  new_school_day_id: 'اليوم الجديد',
  new_user_id: 'مستقبل الطلب',
  notes: 'ملاحظات',
  number: 'العدد',
  number_of_repeat: 'ترتيب الحصص باليوم',
  number_per_week: 'عدد الحصص في الاسبوع',
  online_payment: 'الدفع الالكتروني',
  opinion: 'رأي مدير المدرسة',
  orderNow: 'اطلب الآن',
  orderTotal: 'إجمالي الطلب',
  order_by: 'ترتيب العرض',
  order_coins: 'نقاط الطلب',
  order_date: 'تاريخ الطلب',
  order_id: 'رقم الطلب',
  order_items: 'اصناف الطلب',
  'order_items.*.id': 'صنف الطلب',
  'order_items.*.product_id': 'المنتج',
  order_return_coins: 'نقاط الطلب المسترجعة',
  order_status: 'حالة الطلب',
  order_total: 'إجمالي الطلب',
  organization_type_id: 'نوع الجهة',
  outgoing: 'تسليم',
  owner_request: 'صاحب الطلب',
  parent_id: 'الأب',
  participate: 'المشاركة',
  participations: 'المشاركات',
  participations_count: 'عدد المشاركات',
  passport_number: 'رقم الجواز',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  payment_method_code: 'طريقة الدفع',
  payment_method_id: 'طريقة الدفع',
  period_delay: 'مدة التأخير',
  phase: 'الفترة',
  phone: 'الهاتف',
  postcode: 'الرمز البريدي',
  price: 'السعر',
  print_after_student_delay: 'الطباعة بعد تحضير التأخر الصباحي',
  procedure_assign: 'إحالة إلى الموجه الطلابي',
  procedure_entries: 'الإجراءات',
  procedure_id: 'الإجراء',
  procedure_number: 'رقم الإجراء',
  procedure_taken: 'الإجراء المتخذ',
  procedure_type: 'نوع الإجراء',
  procedures: 'الإجراءات',
  product_id: 'المنتج',
  'product_id.*': 'المنتج',
  product_image: 'صورة المنتج',
  product_images: 'صور المنتج',
  products: 'المنتجات',
  profile_type: 'نوع الحساب',
  program_date: 'تاريخ البرنامج',
  program_name: 'اسم البرنامج',
  program_number: 'رقم الدورة',
  purchases_manager_email: 'بريد مدير المشتريات',
  purchases_manager_mobile: 'جوال مدير المشتريات',
  purchases_manager_name: 'اسم مدير المشتريات',
  quantity: 'الكمية',
  question: 'السؤال',
  question_degree: 'درجة السؤال',
  question_name: 'السؤال',
  rate: 'التقييم',
  'rate.*.degree_rating': 'درجة التقييم',
  'rate.*.rating_item_id': 'بند التقييم',
  rating: 'درجة التقييم',
  'rating.*': 'درجة التقييم',
  'rating.*.degree_rating': 'درجة التقييم',
  rating_item_id: 'بند التقييم',
  rating_items: 'بنود التقييم',
  'rating_items.*': 'بنود التقييم',
  read_at: 'تم القراءة',
  reason: 'السبب',
  receiver_jobs: 'الوظائف المستقبلة',
  region_code: 'رمز المنطقة',
  region_id: 'المنطقة',
  region_key: 'مفتاح المدينة/المنطقة',
  remember: 'تذكرني',
  remove_answer: 'إزالة الإجابة',
  remove_question: 'إزالة السؤال',
  return_coins: 'النقاط المسترجعة',
  role_code: 'مجموعة المستخدم',
  role_id: 'الوظيفة',
  roles: 'الوظائف',
  sar: 'ريال',
  schedule_notes: 'ملاحظات الجدول',
  schoolCoins: 'نقاط المدرسة',
  school_class_id: 'الحصة',
  school_classes: 'الحصص المدرسية',
  school_day_id: 'اليوم',
  school_days_id: 'الأيام',
  school_gender_code: 'كود الجنس المدرسي',
  school_gender_id: 'نوع تعليم المدرسة',
  school_id: 'المدرسة',
  school_name: 'اسم المدرسة',
  school_program_id: 'البرنامج',
  school_semester_id: 'الفصل الدراسي',
  school_semester_id2: 'الفصل الدراسي',
  school_subject2_id: 'الإسناد',
  school_subject_id: 'المادة',
  school_subjects: 'المواد/المقررات',
  school_year_id: 'العام الدراسي',
  schools: 'المدارس',
  search: 'بحث',
  search_infraction_name_id: 'اسم المخالفة',
  search_type: 'نوع البحث',
  second_address: 'العنوان 2',
  send_date: 'تاريخ الارسال',
  sender_jobs: 'الوظائف المرسلة',
  set_degree: 'رصد الدرجة',
  share_waiting_classes: 'توزيع حصص الانتظار',
  shared_subject: 'مشتركة',
  shift_course_id: 'دورة المناوبة',
  shift_date: 'تاريخ المناوبة',
  shift_period_id: 'فترة المناوبة',
  shift_procedure_id: 'إجراء المناوبة',
  shifts: 'المناوبة',
  'shifts.*.shift_course_id': 'دورة المناوبة',
  'shifts.*.shift_date': 'تاريخ المناوبة',
  'shifts.*.shift_period_id': 'فترة المناوبة',
  'shifts.*.shift_procedure_id': 'إجراء المناوبة',
  shipping_method_code: 'طريقة الشحن',
  shipping_method_id: 'طريقة الشحن',
  shop: 'المتجر المدرسي',
  short_name: 'الاسم المختصر',
  show: 'إظهار',
  show_to_students: 'إظهار لـ الطلاب',
  signature: 'التوقيع',
  single: 'فردي',
  social_status_id: 'الحالة الاجتماعية',
  sort_order: 'ترتيب العرض',
  start_date: 'تاريخ البداية',
  start_date_delay_degree: 'بداية الحسم المخصص',
  status: 'الحالة',
  student_id: 'الطالب',
  students: 'الطلاب',
  study_date: 'تاريخ الدراسة',
  study_day: 'يوم دراسي',
  subject: 'العنوان',
  subject_ar: 'العنوان بالعربية',
  subject_en: 'العنوان بالإنجليزية',
  subtotal: 'المجموع الفرعي',
  supervision_course_id: 'دورة الاشراف',
  supervision_date: 'تاريخ الاشراف',
  supervision_location_id: 'موقع الاشراف',
  supervision_procedure_id: 'إجراء الاشراف',
  supervisions: 'الاشراف',
  'supervisions.*.supervision_date': 'تاريخ الاشراف',
  'supervisions.*.supervision_location_id': 'موقع الاشراف',
  'supervisions.*.supervision_procedure_id': 'إجراء الاشراف',
  switch_employee_id: 'المعلم المستبدل',
  switch_school_class_id: 'الحصة المستبدلة',
  switch_school_day_id: 'اليوم المستبدل',
  teacher_id: 'المعلم',
  template_content: 'نص نموذج الطباعة',
  template_content_ar: 'نموذج الطباعة بالعربية',
  template_content_en: 'نموذج الطباعة بالإنجليزية',
  text_input: 'مربع نص',
  textarea_input: 'مربع نص كبير',
  time: 'الوقت',
  time_back: 'وقت العودة',
  time_exit: 'وقت الخروج',
  time_table_assign_count: 'الإسناد المدرج',
  time_table_count: 'عدد الحصص المسندة',
  to_date: 'التاريخ إلى',
  to_id: 'ارسال الى',
  total: 'الإجمالي',
  transcript_attend: 'درجة الحضور',
  transcript_homework: 'واجبات',
  transcript_monthly_test: 'اختبار تحريري قصير',
  transcript_participate: 'مشاركة',
  transcript_report: 'بحوث مشروعات تقارير',
  transcript_programs: 'تطبيقات أنشطة صفية',
  type: 'النوع',
  unfortified: 'غير محصن',
  url: 'الرابط',
  user_group_id: 'مجموعة المستخدم',
  user_id: 'المستخدم',
  vat: 'القيمة المضافة',
  verify_code: 'رمز التحقق',
  violating_students: 'الطلاب المخالفين',
  waiting: 'انتظار',
  waiting_employee_id: 'المعلم المنتظر',
  waiting_excuse_id: 'الإجراء',
  week_number: 'رقم الأسبوع',
  work_time: 'وقت الدراسة',
  shift_date_hijri: 'تاريخ الإشراف',
  supervision_date_hijri: 'تاريخ المناوبة',
  hijri_date: 'التاريخ هجري',
  has_mandate: 'صك ولاية',
  absence_days: 'أيام الغياب',
  attachment: 'المرفق',
  attachments: 'المرفقات',
  promo_code: 'كوبون الخصم',
  promo_codes: 'كوبونات خصم',
  has_quantity: 'لديه كمية',
  affidavit: 'الإفادة',
  title: 'الموضوع',
  content: 'الرسالة'
}
