/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export const LOGIN_ROUTE_NAME = 'login'
export const LOGOUT_ROUTE_NAME = 'logout'
export const HOME_ROUTE_NAME = 'home'
export const USER_HOME_ROUTE_NAME = 'dashboard'

export const AppRoutes = {
  errors: {
    noSchoolSetup: {
      name: 'noSchoolSetup',
      path: '/setup'
    },
    noMembership: {
      name: 'noMembership',
      path: '/Dnfguiwnqwu'
    }
  },
  landing: {
    home: HOME_ROUTE_NAME,
    aboutUs: {
      path: 'AboutUs',
      name: 'about-us'
    },
    contactUs: {
      path: 'ContactUs',
      name: 'contact-us'
    },
    termsConditions: {
      path: 'TermsConditions',
      name: 'terms-conditions'
    },
    privacyPolicy: {
      path: 'PrivacyPolicy',
      name: 'privacy-policy'
    }
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    },
    register: 'register',
    forgotPassword: 'forgot-password'
  },
  user: {
    home: USER_HOME_ROUTE_NAME,
    profile: 'profile',
    index: 'user-index'
  },
  school: {
    index: 'school-index',
    setup: 'setup'
  },
  leader: {
    index: 'leader-index'
  },
  student: {
    index: 'student-index',
    qrList: 'qr-code-students',
    qrScanner: 'student-qr-scanner',
    import: 'import-students'
  },
  guardian: {
    index: 'guardian-index'
  },
  educationMajor: {
    index: 'education-major-index'
  },
  employee: {
    index: 'employee-index',
    import: 'import-employees'
  },
  membershipType: {
    index: 'membership-type-index'
  },
  membership: {
    index: 'membership-index'
  },
  education: {
    index: 'education-index'
  },
  educationDepartment: {
    index: 'education-department-index'
  },
  educationOffice: {
    index: 'education-office-index'
  },
  educationProgram: {
    index: 'education-program-index'
  },
  educationSystem: {
    index: 'education-system-index'
  },
  educationSpecialization: {
    index: 'education-specialization-index'
  },
  educationStage: {
    index: 'education-stage-index'
  },
  educationGrade: {
    index: 'education-grade-index'
  },
  educationClass: {
    index: 'education-class-index'
  },
  educationClassGroup: {
    index: 'education-class-group-index'
  },
  attendanceClassGroup: {
    index: 'attendance-class-group-index'
  },
  jobTitle: {
    index: 'job-title-index'
  },
  job: {
    index: 'job-index'
  },
  nationality: {
    index: 'nationality-index'
  },
  country: {
    index: 'country-index'
  },
  city: {
    index: 'city-index'
  },
  financialStatus: {
    index: 'financial-status-index'
  },
  healthStatus: {
    index: 'health-status-index'
  },
  socialStatus: {
    index: 'social-status-index'
  },
  gender: {
    index: 'gender-index'
  },
  schoolGender: {
    index: 'school-gender-index'
  },
  schoolYear: {
    index: 'school-year-index'
  },
  schoolSemester: {
    index: 'school-semester-index'
  },
  schoolSubject: {
    index: 'school-subject-index'
  },
  schoolClass: {
    index: 'school-class-index'
  },
  schoolDay: {
    index: 'school-day-index'
  },
  infraction: {
    index: 'infraction-index',
    view: 'infraction-view',
    studentInfractions: 'student-infraction',
    assignedInfractions: 'assigned-infraction'
  },
  infractionCategory: {
    index: 'infraction-category-index'
  },
  infractionLevel: {
    index: 'infraction-level-index'
  },
  infractionName: {
    index: 'infraction-name-index'
  },
  // Employees
  infractionType: {
    index: 'infraction-types-index'
  },
  employeeInfraction: {
    index: 'employee-infraction-index',
    employeeIndex: 'my-employee-infractions'
  },
  // Students
  studentInfraction: {
    myIndex: 'my-student-infractions'
  },
  procedure: {
    index: 'procedures-index'
  },
  extraProcedure: {
    index: 'extra-procedure-index'
  },
  procedureNumber: {
    index: 'procedure-number-index'
  },
  demandKind: {
    index: 'demand-kind-index'
  },
  demand: {
    index: 'demand-index',
    myIndex: 'my-demand-index'
  },
  excuse: {
    index: 'excuse-index'
  },
  waitingExcuse: {
    index: 'waiting-excuse-index'
  },
  waitingClass: {
    index: 'waiting-class-index',
    employeeIndex: 'employee-waiting-class'
  },
  absence: {
    index: 'absence-index',
    employeeIndex: 'employee-absence'
  },
  empPermit: {
    index: 'emp-permit-index',
    employeeIndex: 'employee-emp-permit'
  },
  empPermitDemand: {
    index: 'emp-permit-demand-index',
    employeeIndex: 'employee-emp-permit-demand'
  },
  studentAttend: {
    index: 'student-attend-index',
    qr: 'student-attend-qr-index',
    confirmAbsences: 'student-attend-confirm-absences'
  },
  studentDelay: {
    index: 'student-delay-index',
    myIndex: 'my-student-delays',
    qr: 'student-delay-qr-index'
  },
  supervisionLocation: {
    index: 'supervision-location-index'
  },
  supervisionProcedure: {
    index: 'supervision-procedure-index'
  },
  supervisionCourse: {
    index: 'supervision-course-index'
  },
  supervision: {
    index: 'supervision-index',
    myIndex: 'my-supervision-index',
    registration: 'supervision-registration-index'
  },
  shiftPeriod: {
    index: 'shift-period-index'
  },
  shiftProcedure: {
    index: 'shift-procedure-index'
  },
  shiftCourse: {
    index: 'shift-course-index'
  },
  shift: {
    index: 'shift-index',
    myIndex: 'my-shift-index',
    registration: 'shift-registration-index'
  },
  ratingItem: {
    index: 'rating-item-index'
  },
  schoolProgram: {
    index: 'school-program-index',
    rating: 'rate-program-index',
    employee: 'my-school-program-index',
    student: 'student-school-program-index',
    studentRegistration: 'student-registration-school-program-index'
  },
  transcript: {
    transcriptRecord: 'transcript-record-index',
    setting: 'transcript-setting-index',
    homeworkSetting: 'homework-setting-index',
    homework: 'homework-index',
    dailyHomework: 'daily-homework',
    studentNewHomework: 'new-homework-index',
    studentSentHomework: 'sent-homework-index',
    setDailyParticipation: 'set-daily-participation-index',
    dailyParticipation: 'daily-participation-index'
  },
  timeTable: {
    index: 'time-table-index',
    distributionSchedule: 'distribution-schedule',
    employeeIndex: 'employee-time-table',
    studentIndex: 'student-time-table',
    assignIndex: 'time-table-assign-index',
    assignDataEntryReport: 'data-entry-report-index',
    employeeSwitchIndex: 'employee-switch-time-table',
    switchIndex: 'time-table-switch-index'
  },
  shop: {
    category: 'category-index',
    product: 'product-index',
    schoolProduct: 'school-product-index',
    deliveryType: 'delivery-type-index',
    order: 'order-index',
    index: 'shop',
    myOrders: 'my-orders',
    mySchoolOrders: 'my-school-orders',
    userWallet: 'user-wallet',
    employeeWallet: 'employee-wallet-index',
    sendSchoolPrizes: 'send-school-prizes',
    providerInquiry: 'provider-inquiry',
    providerProducts: 'provider-products'
  },
  studentPermit: {
    index: 'student-permit-index',
    myIndex: 'my-student-permits'
  },
  studentAbsence: {
    index: 'student-absence-index',
    myIndex: 'my-student-absences',
    report: 'student-absences-report'
  },
  archive: {
    archiveType: 'archive-type-index',
    outgoingArchive: 'outgoing-archive-index',
    incomingArchive: 'incoming-archive-index',
    studentOutgoingArchive: 'student-outgoing-archive-index',
    studentIncomingArchive: 'student-incoming-archive-index'
  },
  employeeDelay: {
    index: 'employee-delay-index',
    employeeIndex: 'my-employee-delay'
  },
  notification: {
    index: 'notification',
    studentDelay: 'student-delay-notification',
    studentAbsence: 'student-absence-notification'
  },
  affidavit: {
    index: 'affidavit-index',
    details: {
      path: 'affidavit-details/:id/type/:type',
      name: 'affidavit-details'
    }
  },
  circular: {
    index: 'circular-index'
  }
}
// console.log(routeAllies)
export default AppRoutes
