/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  AbsenceReports: 'تقرير الغياب|تقارير الغياب',
  Absences: 'غياب المعلم|غياب المعلمين',
  Ages: '{0} جديد|[1] سنة|[2] سنتان|[3,10]:age سنوات|[11,*] :age سنة',
  AllStudents: 'جميع الطلاب|جميع الطلاب',
  Answers: 'الإجابة|الإجابات',
  ArchiveTypes: 'اعداد الارشيف|اعداد الارشيف',
  AssignedInfractions: 'المخالفة المحالة|المخالفات المحالة',
  AttendanceClassGroups: 'اعداد حضور المجموعة|اعداد حضور المجموعات',
  Attends: 'الحضور|الحضور',
  Behaviors: 'السلوك|السلوك',
  BothEmpPermitDemands: 'طلب استئذان المعلمـ/ـة|طلبات استئذان المعلمين/ـات',
  BothEmpPermits: 'استئذان المعلمـ/ـة|استئذان المعلمين/ـات',
  BothEmployeeAttends: 'حضور المعلمـ/ـة الصباحي|الحضور الصباحي للمعلمين/المعلمات',
  BothEmployees: 'المعلم/ـة|المعلمين',
  BothShifters: 'المعلمـ/ـة المناوبة|المعلمـ/ـة المناوبة',
  BothStudentAttends: 'حضور الطالبـ/ـة الصباحي|الحضور الصباحي للطلاب/الطالبات',
  BothStudentDelays: 'تأخر الطالبـ/ـة الصباحي|التأخر الصباحي للطلاب/الطالبات',
  BothStudents: 'الطالب/الطالبة|الطلبة',
  BothTeachers: 'المعلم/المعلمة|المعلمين',
  CPSchools: 'لوحة التحكم|لوحة التحكم',
  CPanels: 'لوحة التحكم|لوحة التحكم',
  Categories: 'القسم|الأقسام',
  Cities: 'المدينة/المنطقة|المدن/المناطق',
  CorrectingDuties: 'تصحيح الواجب|تصحيح الواجبات',
  Countries: 'الدولة|الدول',
  CreateMyEmpPermitDemands: 'طلب استئذان جديد|طلب استئذان جديد',
  CreateTimeTables: 'توزيع الجدول المدرسي|توزيع الجدول المدرسي',
  Creates: 'إضافة|إضافة',
  DailyParticipations: 'المشاركة اليومية|المشاركات اليومية',
  DailySupervisions: 'الإشراف اليومي|الإشراف اليومي',
  Dashboards: 'الاحصائية|الاحصائيات',
  DataDetailsReports: 'تفاصيل الإسناد|تفاصيل الإسنادات',
  DataEntryReports: 'التحقق من الإسناد|التحقق من الإسنادات',
  Days: '{0}- |[1] يوم واحد|[2] يومان|[3,10]:day أيام|[11,*] :day يوم',
  Delays: 'التأخر الصباحي|التأخر الصباحي',
  DeliveryTypes: 'طريقة التوصيل|طرق التوصيل',
  DemandKinds: 'إدارة نوع التواصل|إدارة التواصل معنا',
  Demands: 'التواصل|التواصل معنا',
  DistributionSchedules: 'توزيع الجدول المدرسي|توزيع الجدول المدرسي',
  Edits: 'تعديل|تعديل',
  Education: 'التعليم|التعليم',
  EducationClassGroups: 'مجموعة الفصل|مجموعات الفصول',
  EducationClasses: 'الفصل المدرسي|الفصول المدرسية',
  EducationDepartments: 'إدارة التعليم|إدارات التعليم',
  EducationGrades: 'المرحلة الدراسية|المراحل الدراسية',
  EducationMajors: 'تخصص التعليم|تخصصات التعليم',
  EducationOffices: 'مكتب التعليم|مكاتب التعليم',
  EducationPrograms: 'برنامج التعليم|البرامج التعليمية',
  EducationSpecializations: 'التخصص التعليمي|التخصصات التعليمية',
  EducationStages: 'المرحلة التعليمية|المراحل التعليمية',
  EducationSystems: 'نظام التعليم|أنظمة التعليم',
  EmpPermitDemands: 'استئذان المعلم|استئذانات المعلمين',
  Permits: 'الإستئذان|الإستئذانات',
  EmpPermits: 'استئذان المعلم|استئذانات المعلمين',
  EmployeeAbsences: 'الغياب|الغياب',
  EmployeeAttends: 'حضور المعلم الصباحي|الحضور الصباحي للمعلمين',
  EmployeeDelays: 'التأخر الصباحي|التأخر الصباحي',
  EmployeeEmpPermitDemands: 'طلب الخروج من الدوام|طلبات الخروج من الدوام',
  EmployeeEmpPermits: 'الخروج من الدوام|الخروج من الدوام',
  EmployeeInfractions: 'مخالفة المعلم|مخالفات المعلمين',
  EmployeePrograms: 'برنامج المعلم|برامج المعلم',
  EmployeeSwitchTimeTables: 'طلب تبديل حصة|طلبات تبديل الحصص',
  EmployeeTimeTables: 'الجدول المدرسي|الجدول المدرسي',
  EmployeeWaitingClasses: 'حصة الانتظار|حصص الانتظار',
  EmployeeWallets: 'نقاط الموظف|نقاط الموظفين',
  Employees: 'المعلم|المعلمين',
  Excuses: 'عذر الغياب|أعذار الغياب',
  ExtraProcedures: 'الإجراء المتخذ|الإجراءات المتخذة',
  FemaleEmpPermitDemands: 'استئذان المعلمة|استئذان المعلمات',
  FemaleEmpPermits: 'استئذان المعلمة|استئذان المعلمات',
  FemaleEmployeeAttends: 'حضور المعلمة الصباحي|الحضور الصباحي للمعلمات',
  FemaleEmployees: 'المعلمة|المعلمات',
  FemaleShifters: 'المعلمة المناوبة|المعلمات المناوبات',
  FemaleStudentAttends: 'حضور الطالبة الصباحي|الحضور الصباحي للطالبات',
  FemaleStudentDelays: 'تأخر الطالبة الصباحي|التأخر الصباحي للطالبات',
  FemaleStudents: 'الطالبة|الطالبات',
  FemaleTeachers: 'المعلمة|المعلمات',
  FinancialStatuses: 'الحالة المادية|الحالات المادية',
  Genders: 'الجنس|الأجناس',
  Gifts: 'الجائزة|الجوائز',
  GlobalShows: 'عرض|عرض',
  Guardians: 'ولي الأمر|أولياء الأمور',
  GuidanceCounselors: 'التوجيه والارشاد|التوجيه والارشاد',
  HealthStatuses: 'الحالة الصحية|الحالات الصحية',
  Homework: 'الواجب|الواجبات',
  HomeworkAndInfractions: 'سجل الواجبات والسلوك|سجل الواجبات والسلوك',
  HomeworkSettings: 'اعداد الواجب|اعداد الواجبات',
  Homeworks: 'الواجب|الواجبات',
  DailyHomework: 'الواجب اليومي|الواجبات اليومية',
  DailyHomeworks: 'الواجب اليومي|الواجبات اليومية',
  Hours: '{0}- |[1] ساعة|[2] ساعتان|[3,10]:hour ساعات|[11,*] :hour ساعة',
  ImportStudents: 'استيراد بيانات الطالب|استيراد بيانات الطلاب',
  ImportEmployees: 'استيراد بيانات المعلمين|استيراد بيانات المعلم',
  Imports: 'استيراد|استيراد',
  IncomingArchives: 'الاستلام|الاستلام',
  IndexTimeTables: 'الجدول المدرسي|الجدول المدرسي',
  Indices: 'القوائم|القوائم',
  InfractionCategories: 'فئة المخالفة|فئات المخالفة',
  InfractionLevels: 'درجة المخالفة|درجات المخالفة',
  InfractionNames: 'اسم المخالفة|أسماء المخالفات',
  InfractionTypes: 'اعداد مخالفة المعلم|اعداد مخالفات المعلمين',
  Infractions: 'المخالفة|المخالفات',
  JobTitles: 'المسمى الوظيفي|المسميات الوظيفية',
  Jobs: 'الوظيفة|الوظائف',
  Leaders: 'مدير المدرسة|مدير المدرسة',
  MaleEmpPermitDemands: 'طلب استئذان المعلم|طلبات استئذانات المعلمين',
  MaleEmpPermits: 'استئذان المعلم|استئذانات المعلمين',
  MaleEmployeeAttends: 'حضور المعلم الصباحي|الحضور الصباحي للمعلمين',
  MaleEmployees: 'المعلم|المعلمين',
  MaleShifters: 'المعلم المناوب|المعلمون المناوبون',
  MaleStudentAttends: 'حضور الطالب الصباحي|الحضور الصباحي للطلاب',
  MaleStudentDelays: 'تأخر الطالب الصباحي|التأخر الصباحي للطلاب',
  MaleStudents: 'الطالب|الطلاب',
  MaleTeachers: 'المعلم|المعلمين',
  MembershipTypes: 'نوع الاشتراك|أنواع الاشتراكات',
  Memberships: 'الاشتراك|الاشتراكات',
  Minutes: '{0}- |[1] دقيقة|[2] دقيقتان|[3,10]:minute دقائق|[11,*] :minute دقيقة',
  Months: '{0}- |[1] شهر واحد|[2] شهرين|[3,10]:month شهور|[11,*] :month شهر',
  MyAbsences: 'الغياب|الغياب',
  MyDemands: 'تواصل معنا|تواصل معنا',
  MyEmpPermitDemands: 'طلب الخروج من الدوام|طلبات الخروج من الدوام',
  MyEmpPermits: 'الخروج من الدوام|الخروج من الدوام',
  MyEmployeeDelays: 'التأخر الصباحي|التأخر الصباحي',
  MyEmployeeInfractions: 'مخالفتي|مخالفاتي',
  MyStudentInfractions: 'مخالفتي|مخالفاتي',
  MyInfractions: 'مخالفتي|مخالفاتي',
  MyIndices: 'قائمتي|قائمتي',
  MyOrders: 'طلبي|طلباتي',
  MyRecords: 'سجلي|سجلاتي',
  MySchoolOrders: 'الجائزة المستحقة|الجوائز المستحقة',
  MySchoolPrograms: 'برنامجي المدرسي|برامجي المدرسية',
  MyShifts: 'مناوبتي|مناوباتي',
  MyStudentPermits: 'استئذاني|استئذاناتي',
  MySupervisions: 'إشرافي اليومي|إشرافي اليومي',
  MyTimeTableSwitches: 'طلب تبديل حصة|طلبات تبديل الحصص',
  MyTimeTables: 'الجدول المدرسي|الجدول المدرسي',
  MyWaitingClasses: 'حصة الانتظار|حصص الانتظار',
  Nationalities: 'الجنسية|الجنسيات',
  NewHomework: 'الواجب الجديد|الواجبات الجديدة',
  NewHomeworks: 'الواجب الجديد|الواجبات الجديدة',
  Notifications: 'الإشعار|الإشعارات',
  OrderItems: 'صنف الطلب|أصناف الطلب',
  Orders: 'الطلب|الطلبات',
  OutgoingArchives: 'التسليم|التسليم',
  Permissions: 'الصلاحية|الصلاحيات',
  Persons: '{0} لا يوجد|[1] فرد واحد|[2] فردان|[3,10]:persons أفراد|[11,*] :persons شخص',
  Predicates: 'المسند|الإسنادات',
  ProcedureNumbers: 'رقم الإجراء|أرقام الإجراءات',
  ProcedureTypes: 'نوع الإجراء|أنواع الإجراءات',
  Procedures: 'الإجراء|الإجراءات',
  Products: 'المنتج|المنتجات',
  Profiles: 'الملف الشخصي|الملف الشخصي',
  QRStudentAttends: 'ماسح الحضور الصباحي|ماسح الحضور الصباحي',
  QRStudentDelays: 'ماسح التأخر الصباحي|ماسح التأخر الصباحي',
  QrCodeBothStudents: 'باركود الطلاب/الطالبات|باركود الطلاب/الطالبات',
  QrCodeFemaleStudents: 'باركود الطالبة|باركود الطالبات',
  QrCodeMaleStudents: 'باركود الطالب|باركود الطلاب',
  QrCodeStudents: 'باركود الطالب|باركود الطلاب',
  QuestionKinds: 'نوع الاستفسار/الطلب|أنواع الاستفسار/الطلب',
  Questions: 'السؤال|الأسئلة',
  RatePrograms: 'تقييم البرنامج|تقييم البرامج',
  Rating: 'التقييم|التقييم',
  RatingItems: 'بند التقييم|بنود التقييم',
  Ratings: 'التقييم|التقييم',
  Reports: 'التقرير|التقارير',
  Roles: 'الوظيفة|الوظائف',
  SchoolActivities: 'النشاط المدرسي|الأنشطة المدرسية',
  SchoolClasses: 'الحصة الدراسية|الحصص الدراسية',
  SchoolDays: 'اليوم|الأيام',
  SchoolEntries: 'المدرسة|المدرسة',
  SchoolFeatures: 'الخدمة المدرسية|الخدمات المدرسية',
  SchoolGenders: 'الجنس التعليمي|الأجناس التلعيمية',
  SchoolProducts: 'الجائزة|الجوائز',
  SchoolPrograms: 'البرنامج|البرامج',
  SchoolSemesters: 'الفصل الدراسي|الفصول الدراسية',
  SchoolSettings: 'اعداد المدرسة|اعدادات المدرسة',
  SchoolSubjects: 'المادة الدراسية|المواد الدراسية',
  SchoolTimeTables: 'الجدول المدرسي|الجدول المدرسي',
  SchoolTools: 'إعداد المدرسة|إعدادات المدرسة',
  SchoolYears: 'العام الدراسي|الأعوام الدراسية',
  Schools: 'المدرسة|المدارس',
  SentHomework: 'الواجب المرسل|الواجبات المرسلة',
  SentHomeworks: 'الواجب المرسل|الواجبات المرسلة',
  SetDailyParticipations: 'رصد المشاركة اليومية|رصد المشاركات اليومية',
  Settings: 'الإعداد|الإعدادات',
  Setups: 'الضبط|الضبط',
  ShiftCourses: 'دورة المناوبة|دورات المناوبة',
  ShiftPeriods: 'فترة المناوبة|فترات المناوبة',
  ShiftProcedures: 'إجراء المناوبة|إجراءات المناوبة',
  ShiftRegistrations: 'تسجيل المناوبة|تسجيل المناوبة',
  ShiftReports: 'تقرير المناوبة|تقرير المناوبات اليومية',
  ShiftWorks: 'المناوبة|المناوبات',
  Shifters: 'المعلم المناوب|المعلمون المناوبون',
  Shifts: 'المناوبة|المناوبات',
  Shows: 'عرض|عرض',
  SocialStatuses: 'الحالة الاجتماعية|الحالات الاجتماعية',
  Statuses: 'الحالة|الحالات',
  StudentAbsences: 'غياب الطالب|غياب الطلاب',
  StudentAbsenceReports: 'لائحة المواظبة|لائحة المواظبة',
  StudentMorningAbsences: 'الغياب الصباحي|الغياب الصباحي',
  StudentAffairs: 'شؤون الطالب|شؤون الطلاب',
  StudentAttends: 'حضور الطالب الصباحي|الحضور الصباحي للطلاب',
  StudentDelays: 'تأخر الطالب الصباحي|التأخر الصباحي للطلاب',
  StudentInfractions: 'مخالفة الطالب|مخالفات الطلاب',
  StudentPermits: 'استئذان الطالب|استئذان الطلاب',
  StudentRegistrationSchoolPrograms: 'تسجيل البرنامج|تسجيل البرامج',
  StudentSchoolPrograms: 'برنامجي المدرسي|برامجي المدرسية',
  StudentTimeTables: 'الجدول المدرسي|الجدول المدرسي',
  Students: 'الطالب|الطلاب',
  SupervisionCourses: 'دورة الإشراف اليومي|دورات الإشراف اليومي',
  SupervisionLocations: 'موقع الإشراف اليومي|مواقع الإشراف اليومي',
  SupervisionProcedures: 'إجراء الإشراف اليومي|إجراءات الإشراف اليومي',
  SupervisionRegistrations: 'تسجيل الإشراف اليومي|تسجيل الإشراف اليومي',
  Supervisions: 'الإشراف|الإشراف',
  TakenProcedures: 'الإجراء المتخذ|الإجراءات المتخذة',
  TeacherAffairs: 'شؤون المعلم|شؤون المعلمين',
  Teachers: 'المعلم|المعلمين',
  TermTimeTables: 'قيد الجدول المدرسي|قيود الجدول المدرسي',
  TimeTableAssigns: 'إسناد الجدول المدرسي|اسنادات الجدول المدرسي',
  TimeTableSwitches: 'طلب تبديل حصة|طلبات تبديل الحصص',
  TimeTables: 'الجدول المدرسي|الجدول المدرسي',
  TranscriptItems: 'أداة تقييم متنوعة|أدوات تقييم متنوعة',
  TranscriptRecords: 'كشف أعمال السنة|كشف أعمال السنة',
  TranscriptSettings: 'اعداد كشف أعمال السنة|اعداد كشف أعمال السنة',
  Transcripts: 'أعمال السنة|أعمال السنة',
  Trasheds: 'المحذوفات|المحذوفات',
  Updates: 'تعديل|تعديل',
  UserGroups: 'مجموعة المستخدم|مجموعات المستخدم',
  UserWallets: 'نقاط المستخدم|نقاط المستخدمين',
  Users: 'المستخدم|المستخدمين',
  WaitingClassReports: 'تقرير حصص الانتظار|تقارير حصص الانتظار',
  WaitingClasses: 'حصة الانتظار|حصص الانتظار',
  WaitingExcuses: 'إجراء حصة الانتظار|إجراءات حصة الانتظار',
  Years: '{0}- |[1] سنة|[2] سنتان|[3,10]:year سنوات|[11,*] :year سنة',
  SendSchoolPrizes: 'إرسال الجائزة|إرسال الجوائز',
  ProviderProducts: 'منتج التاجر|منتجات التاجر',
  Affidavits: 'الإفادة|الإفادات',
  Circulars: 'التعميم|التعاميم',
  Alls: 'الكل|الكل'
}
