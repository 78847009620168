/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

import { Stub, StubUrl } from './Stub'

const prefix = 'Front/Attend/StudentAttend'
const url = StubUrl(prefix)

export default {
  ...Stub(prefix),
  async qrStudent (data = {}, config = {}) {
    const u = url('qrStudents')
    return data === true ? u : axios.post(u, data, config)
  },
  studentAbsences: params => params === !0
    ? url('Absences')
    : axios.get(
      url('Absences'), { params }),
  async confirmAbsences (data = {}, config = {}) {
    const u = url('ConfirmAbsences')
    return data === !0 ? u : axios.post(u, data, config)
  }
}
