/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "Landing" */ '@views/App'),
    meta: {
      // guest: true
    },
    children: [
      {
        path: 'home',
        name: routes.landing.home,
        component: () => import(/* webpackChunkName: "HomeLandingPage" */ '@/components/home/HomeIndex')
      },
      {
        path: routes.landing.aboutUs.path,
        name: routes.landing.aboutUs.name,
        component: () => import(/* webpackChunkName: "AboutUsPage" */ '@/components/home/AboutUs')
      },
      {
        path: routes.landing.contactUs.path,
        name: routes.landing.contactUs.name,
        component: () => import(/* webpackChunkName: "ContactUsPage" */ '@/components/home/ContactUs')
      },
      {
        path: routes.landing.termsConditions.path,
        name: routes.landing.termsConditions.name,
        component: () => import(/* webpackChunkName: "TermsConditionsPage" */ '@/components/home/TermsConditions')
      },
      {
        path: routes.landing.privacyPolicy.path,
        name: routes.landing.privacyPolicy.name,
        component: () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '@/components/home/PrivacyPolicy')
      }
    ]
  }
]
