<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<script>

import AutoSelect from '@base-inputs/AutoSelect'

export default {
  name: 'FilterSelect',
  extends: AutoSelect,
  props: {
    clearable: {
      type: Boolean,
      default: () => true
    },
    items: {
      type: Array,
      default () {
        return [
          // {text: this.parseAttribute('none'), value: null},
          { text: this.$t('yes'), value: 1 },
          { text: this.$t('no'), value: 0 }
        ]
      }
    }
  }
}
</script>
