/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

import routes from '@routes/config'

export default {
  studentAttendQrIndex: 'Student Attend Qr Scanner',
  studentDelayQrIndex: 'Student Delay Qr Scanner',
  employeeTimeTableIndex: 'My Schedule',
  employeeAbsenceIndex: 'My Absences',
  employeeWaitingClassIndex: 'My Waiting Classes',
  employeeEmpPermitIndex: 'My Emp Permits',
  employeeEmpPermitDemandIndex: 'My Emp Permit Demands',
  employeeSwitchTimeTableIndex: 'Schedule Switch Demands',
  setDailyParticipationIndex: 'Set Daily Participation',
  shop: 'shop',
  myOrders: 'My Orders',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  termsConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy & Policy',
  [routes.studentAttend.confirmAbsences]: 'Absence of Delay morning student',
  [routes.notification.studentDelay]: 'Send Delay morning notices',
  [routes.notification.studentAbsence]: 'Send Absence notices',
  [routes.studentAbsence.report]: 'Student Absences Reports',
  [routes.shop.providerInquiry]: 'Orders inquiry',
  [routes.shop.providerProducts]: 'My Products',
  [routes.affidavit.details.name]: 'Affidavits'
}
