/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { kebabCase } from 'lodash'

export default {
  methods: {
    route (name) {
      name = kebabCase(name)
      return { name }
    },
    isRoute (name) {
      return this.$route.name === name
    }
  },
  computed: {
    AppName () {
      return this.AppSetting.app_name ?? (this.AppLocale === 'ar' ? 'أتابع' : 'Atab3')
    },
    AppDescription () {
      return this.AppSetting.app_description ?? ''
    },
    AppLogo () {
      return require(`@images/${this.themeColor}.png`)
    },
    AppSetting: {
      get () {
        return this.$root.RootSetting
      },
      set (v) {
        this.$root.RootSetting = v
      }
    }
  }
}
