<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <component
    :is="getComponentName"
    v-bind="$attrs"
  >
    <!--      <v-lazy-->
    <!--          v-model="lazy"-->
    <!--          :options="{ threshold: 1}"-->
    <!--          transition="fade-transition"-->
    <!--      >-->
    <!--    <span>-->
    <slot />
    <!--    </span>-->
    <!--      </v-lazy>-->
  </component>
</template>

<script>
export default {
  name: 'ColSpan',
  props: {
    lazyModel: {
      type: Boolean,
      default: () => false
    },
    withCol: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      // lazy: this.lazyModel
    }
  },
  computed: {
    getComponentName () {
      return this.withCol ? 'app-col' : 'span'
    },
    lazy: {
      get () {
        return this.lazyModel
      },
      set (v) {
        // console.log('update:lazyModel', v);
        this.$emit('update:lazyModel', v)
      }
    }
  },
  mounted () {
    // this.lazy = this.lazyModel;
  }
}
</script>
