/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  about_us_desc: 'نظام متكامل يدعم التقنية الحديثة للتحول الرقمي.<br/> نوفر افضل الخدمات والحلول للمدارس التي يمكن من خلالها أتمتت البيانات رقمياً',
  contact_us_desc: 'نسعد بتواصلكم معنا واستقبال آرائكم وتقديم الحلول.',
  address_desc: 'المملكة العربية السعودية, الرياض',
  last_update: 'اخر تعديل: {d}',
  terms_line_1: 'باستخدامك تطبيق ({n}) يعني موافقتك على الشروط والأحكام الخاصة بالتطبيق.',
  terms_line_2: '&bullet; " اتابع " هو برنامج للتحول الرقمي للإدارة المدرسية و وسيط للشراكات المجتمعية مع الجامعات و المدارس و الهيئات و الجمعيات .<br />' +
   '&bullet; يقتصر دور " أتابع " في برامج الشراكة المجتمعية على ايصال كوبونات الخصم الممنوحة من الشريك ( مزود الخدمة ) للمستفيدين المشتركين في " أتابع " .<br />' +
   '&bullet; لا يقوم أتابع بدور الوساطة المالية بين طرفي الشراكة المجتمعية .<br />' +
   '&bullet; يلتزم " أتابع " بتطبيق أحكام و شروط الشريك فيما يخص استخدام كوبونات الخصم المتفق عليها.<br />' +
   '&bullet; يلتزم الشريك (مزود الخدمة) بتقديم خصومات حقيقية و تفعيلها خلال الفترة المتفق عليها و يتحمل ما يترتب على عدم التزامه بذلك .<br />' +
   '&bullet; يقدم "أتابع " فترة تجريبية للشريك ( 60 ) يوما بدون مقابل مالي .<br />' +
   '&bullet; في حال ظهرت بوادر تحقق اهداف الشراكة خلال الفترة التجريبية فيلزم توقيع اتفاقية شراكة بين الطرفين .<br />' +
   '&bullet; التطبيق لا يطلب من طرفي الشراكة المجتمعية اي بيانات شخصية إجبارية.<br />' +
   '&bullet; جميع البيانات التي يتم إدخالها أو تخزينها هي فقط لتقديم الخدمات واستخدماها من طرفكم.<br />' +
   '&bullet; لا يتم جمع البيانات، تخزينها أو تقديمها لأي جهة أخرى.<br />' +
   '&bullet; التطبيق غير مسؤول عن النزاعات التي قد تحدث بين طرفي الشراكة المجتمعية<br />' +
   '&bullet; يحق للتطبيق الغاء حساب المشترك في حال حدث خلل في الاتفاق<br />' +
   '&bullet; هذه الخدمات عامة لتسهيل عمليات أتمتة البيانات واستخدامها رقمياً.',
  pp_line_1: 'سياسة خصوصية تطبيق ({n})',
  pp_line_2: 'جميع البيانات التي يتم إدخالها أو تخزينها من طرفكم هي لغرض استخدام مميزات وخدمات التطبيق.<br />' +
   'لا يتم جمع البيانات أو مشاركتها وجميع البيانات يتم تشفيرها وتخزينها بأمان.<br />' +
   'التطبيق غير مسؤول عن النزاعات التي قد تحدث بواسطة الخدمات أو سوء تفاهم.<br />' +
   'يحق للتطبيق حذف بيانات الحساب أو المدرسة في حال حدث خلل بالاتفاق أو أمور أخرى.<br />',
  contact_with_app: 'تواصل مع إدارة التطبيق',
  plans: {
    basic: 'الباقة المجانية',
    gold: 'الباقة الذهبية'
  },
  subscribe: 'اشتراك'
}
