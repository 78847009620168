<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<script>
import AxiosSelect from '@base-inputs/AxiosSelect'
import Api from '@helpers/api'

export default {
  name: 'SchoolYearSelect',
  extends: AxiosSelect,
  props: {
    axiosUrl: {
      default: () => Api.methods.schoolYear.getSchoolSelect
    }
  }
}
</script>
