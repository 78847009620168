/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const url = StubUrl('Front/TeacherAffair/ShiftReport')

export default {
  ...Stub(url()),
  employeeStore: (id, form) => axios.post(url(`${id}/Shift`), form)
}
