/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'
import { Stub } from '@helpers/api/requests/Stub'

const stubUrl = a => 'Front/Shop/ProviderProduct' + (a ? `/${a}` : '')

export default {
  ...Stub(stubUrl()),
  qrScanner: qr => axios.post(stubUrl('Qr-Scanner'), { order_id: qr })
}
