<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-row>
    <slot name="prepend" />
    <app-auto-select
      v-model="form.country_id"
      :items="countries"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="country_id"
      v-bind="$attrs"
    />
    <app-auto-select
      v-if="city"
      v-model="form.city_id"
      :items="cities"
      :loading="loading"
      :rules="getRules"
      cols="12"
      md="6"
      name="city_id"
    />
    <slot />
    <slot name="append" />
  </app-row>
</template>

<script>

export default {
  name: 'CountriesRow',
  props: {
    required: {
      type: Boolean,
      default: () => undefined
    },
    city: {
      type: Boolean,
      default: () => true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    const url = this.$api.methods.resources.countries

    return {
      url,
      loading: !1,
      countries: [],
      formRules: []
    }
  },
  computed: {
    selectedCountry () {
      const form = this.form || {}
      const id = form.country_id || undefined
      const selected = this.countries.find(e => e.id === id)
      if (!id || !selected) return []
      return selected
    },
    cities () {
      const selected = this.selectedCountry
      if (!selected || !selected.cities) return []
      return selected.cities
    },
    getRules () {
      const r = this.formRules
      this.required === true && r.indexOf('required') < 0 && r.push('required')
      return r.join('|')
    }
  },
  mounted () {
    this.$nextTick(() => this.fetchCountries())
  },
  methods: {
    fetchCountries () {
      if (this.loading) return
      this.loading = !0

      const items = this.getAxiosItems()
      if (items !== undefined && items !== null) {
        this.setCountries(items)
        this.loading = !1
        return
      }

      this.setCountries([])

      setTimeout(() => {
        this.url().then(({ data }) => {
          if (data && data.data) {
            this.setCountries(data.data)
            this.setIniAxios(this.getAxiosName(), data.data)
          }
        }).catch(() => this.setCountries([])).finally(() => this.loading = !1)
      }, this.increaseAxios())
    },
    setCountries (v) {
      this.countries = v
    }
  }
}
</script>
