/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'
import axios from 'axios'

const url = StubUrl('Front/StudentAffair/StudentAbsence')
export default {
  ...Stub(url()),
  studentIndex: params => params === !0
    ? url('MyIndex')
    : axios.get(url('MyIndex'), { params }),
  report: config => config === !0
    ? url('Report')
    : axios.get(url('Report'), config),
  sendNotifications (data = {}, config = {}) {
    const u = url('SendNotifications')
    return data === !0 ? u : axios.post(u, data, config)
  }
}
