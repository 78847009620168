<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-col
    :class="getClasses"
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-col>
</template>

<script>
export default {
  name: 'Col',
  props: {
    all: {
      type: [String, Number],
      default: () => undefined
    },
    cols: {
      type: [Number, String],
      default () {
        return this.all !== undefined ? this.all : '12'
      }
    },
    sm: {
      type: [Number, String],
      default () {
        return this.all !== undefined ? this.all : (this.cols || 6)
      }
    },
    md: {
      type: [Number, String],
      default () {
        return this.all !== undefined ? this.all : (this.sm || this.cols || 4)
      }
    },
    lg: {
      type: [Number, String],
      default () {
        return this.all !== undefined ? this.all : (this.md || this.sm || this.cols || 4)
      }
    },
    left: {
      type: Boolean,
      default: () => true
    },
    right: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    align () {
      return (this.right && !this.left ? 'right' : (this.left && !this.right ? 'left' : 'center'))
    },
    getClasses () {
      return [
        'app--col',
        'text-center',
        'text-sm-start'
      ].join(' ')
    }
  }
}
</script>
