<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-icon
    :left="getLeft"
    :right="getRight"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-icon>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    left: {
      type: Boolean,
      default () {
        return !this.AppRtl
      }
    },
    right: {
      type: Boolean,
      default () {
        return Boolean(this.AppRtl)
      }
    },
    align: {
      type: Boolean,
      default: () => undefined
    }
  },
  computed: {
    getLeft () {
      return this.align !== undefined ? !this.AppRtl : this.left
    },
    getRight () {
      return this.align !== undefined ? this.AppRtl : this.right
    }
  },
  mounted () {
    // console.log(this.right, this.left)
  }
}
</script>
