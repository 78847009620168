/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const url = StubUrl('Front/TeacherAffair/TimeTableSwitch')

export default {
  ...Stub(url()),
  storeUserRecord: form => axios.post(url('Store-User-Record'), form),
  userSwitch: ({ from, to }) => axios.post(url('User-Switch'), { from, to }),
  approve: id => axios.get(url(`${id}/Approve`)),
  reject: id => axios.get(url(`${id}/Reject`))
}
