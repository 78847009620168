/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  scheduleCount: 'عدد الحصص المدرجة',
  progressing: 'جاري',
  checkFromEdu: 'التحقق من تعارض الفصول',
  checkFromEmployee: 'التحقق من تعارض الموظفين',
  checkFromConflictError: 'يوجد تعارض لدى الموظف {n} يوم {d} الحصة {p}',
  insertedRemainingSchedule: 'تم إدراج الإسناد الى الجدول',
  errorScheduleLength: 'الرجاء التحقق من الإسناد المدرج للفصل المدرسي {n}',
  noSwitch: 'لا يمكن تغيير الحصة الرجاء حذف الحصة لدى المعلم الآخر',
  insert: 'إدراج',
  errorSwitchBetweenEmployees: 'الرجاء اختيار حصة فارغة لنفس الموظف',
  cancelChoose: 'الغاء الاختيار',
  choosePeriod: 'اختيار الحصة',
  remaining: 'المتبقي',
  count: 'العدد',
  movePeriod: 'تغيير الحصة',
  scheduleProgress: 'جاري إعادة توزيع الجدول',
  selectNewSchedule: 'قم باختيار حصة فارغة لنفس الموظف',
  errorNoNewMovedSelected: 'لا يمكن تغيير الحصص',
  confirmSwitch: 'تأكيد التغيير'
}
