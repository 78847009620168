<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <div class="mb-4">
    <v-toolbar
      color="light-blue darken-4"
      dark
      dense
      elevation="10"
      flat
    >
      <v-toolbar-title>
        <slot />
      </v-toolbar-title>
    </v-toolbar>
    <v-divider class="mt-3 mb-2" />
  </div>
</template>

<script>
export default {
  name: 'PageTitle'
}
</script>

<style scoped>

</style>
