/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import Auth from '@helpers/auth'
import axios from 'axios'

const publicPrefix = 'User'
const prefix = 'Admin/User'
export default {
  getSchoolsSelect (config = {}) {
    const p = `${publicPrefix}/MySchools`
    return config === true ? p : axios.get(p, config)
  },
  setDefaultSchool (schoolId, config = {}) {
    const url = `${publicPrefix}/School/${schoolId}/Default`
    return config === true ? url : axios.get(url, config)
  },
  getDefaultSchool (config = {}) {
    const url = `${publicPrefix}/School/GetDefault`
    return config === true ? url : axios.get(url, config)
  },
  sideMenu () {
    return axios.get(`${publicPrefix}/side-menu`)
  },
  profile () {
    return axios.get(`${publicPrefix}/profile`)
  },
  updateAvatar (url = false) {
    const a = `${publicPrefix}/profile-avatar`
    return url === !0 ? a : axios.get(a)
  },
  refreshProfile () {
    return this.profile().then((response) => {
      const { data } = response || {}

      if (data && data.data && data.success === true) {
        Auth.save({ user: data.data })
      }
      return response
    })
  },
  updateProfile (form = {}) {
    return axios.post(`${publicPrefix}/profile`, form)
  },
  deleteAccount (form = {}) {
    return axios.post(`${publicPrefix}/DeleteAccount`, form)
  },
  index (params, config = {}) {
    return params === true
      ? prefix
      : axios.get(prefix, {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true ? prefix : axios.post(prefix, data, config)
  },
  show (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = `${prefix}/${id}`
    return id === true ? url : axios.delete(url, config)
  },
  dashboard: (config = {}) => axios.get(`${publicPrefix}/Dashboard`, config)
}
