/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { THEME_DARK_STORAGE } from '@/app/config'
import { mapActions, mapGetters } from 'vuex'

// eslint-disable-next-line no-unused-vars
const flipChoice = (data) => {
  const f = {}
  for (const a in data) {
    let c
    c = data[a].split('|')
    c = [c[1], c[0]].join('|')
    f[a] = c
  }
  // console.log(f);
  // console.log(JSON.stringify(f));
  return f
}

export default {
  metaInfo () {
    const AppName = this.AppName
    // const AppName = process.env.MIX_APP_NAME
    // const AppName = undefined
    return {
      title: AppName,
      titleTemplate (title) {
        // : `${AppName} | %s`
        //   console.log(title, arguments)
        return title && title !== AppName ? `${AppName} | ${title}` : AppName
      }
    }
  },
  data () {
    return {
      AppNavigation: this.$vuetify.breakpoint.lgAndUp,
      IniApp: false,
      AppProgress: false,
      AppFullscreen: false,
      iniAxios: {},
      AxiosCountdown: 100,
      showAxiosErrorMessage: !0,
      RootSetting: {}
    }
  },
  methods: {
    ...mapActions('auth', {
      iniAuthFromStorage: 'iniAuthFromStorage',
      refreshUser: 'refresh'
    }),
    updateProfile (user = null) {
      if (user) {
        return new Promise((resolve, reject) => {
          this.authUser = user
          resolve(user)
          return user
        })
      } else {
        return this.$api.methods.user.refreshProfile().then(({ data }) => {
          if (data && data.data && data.success === true) {
            this.authUser = data.data
            return data.data
          }
          return {}
        })
      }
    },
    iniTheme () {
      this.themeDark = parseInt(localStorage.getItem(THEME_DARK_STORAGE)) === 1
    }
  },
  beforeCreate () {
    this.$axios.interceptors.response.use((response) => response, (error) => {
      const res = () => {
        this.$root.showAxiosErrorMessage = !0
        return Promise.reject(error)
      }
      if (!error || !error.response) return res()

      if (this.$root.showAxiosErrorMessage && error.response.status === 422 &&
       error.responseError && error.responseError.message) {
        this.$nextTick(() => this.alertError(error.responseError.message))
      }

      return res()
    })
  },
  async beforeMount () {
    await this.iniAuthFromStorage()
    // console.log(12)
  },
  created () {
    this.IniAppLocale()
    this.iniTheme()
  },
  mounted () {
    // alert('window.isMyThApp:' + window.isMyThApp)
  },
  computed: {
    ...mapGetters('sideMenu', { sideMenuItems: 'getItems' })
  }

}
