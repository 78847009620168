/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  AbsenceReports: 'Absence Report|Absence Reports',
  Absences: 'Absence|Absences',
  Ages: '{0} New|[1] One Year|[2,*] :age Years',
  AllStudents: 'All Students|All Students',
  Answers: 'Answer|Answers',
  ArchiveTypes: 'Archive Type|Archive Types',
  AssignedInfractions: 'Assigned Infraction|Assigned Infractions',
  AttendanceClassGroups: 'Attendance Class Group|Attendance Class Groups',
  Attends: 'Attend|Attends',
  Behaviors: 'Behavior|Behaviors',
  BothEmpPermitDemands: 'Teacher Demand permit|Teacher Demand permits',
  BothEmpPermits: 'Teacher permit|Teacher permits',
  BothEmployeeAttends: 'Employee Attend|Employee Attends',
  BothEmployees: 'Employee|Employees',
  BothShifters: 'Shifter|Shifters',
  BothStudentAttends: 'Student Attend|Student Attends',
  BothStudentDelays: 'Student Delay|Student Delays',
  BothStudents: 'Student|Students',
  BothTeachers: 'Teacher|Teachers',
  CPSchools: 'CPanel|CPanel',
  CPanels: 'CPanel|CPanel',
  Categories: 'Category|Categories',
  Cities: 'City/Region|Cities/Regions',
  CorrectingDuties: 'Correcting duties|Correcting duties',
  Countries: 'Country|Countries',
  CreateMyEmpPermitDemands: 'New Permit Demand|New Permit Demand',
  CreateTimeTables: 'School schedule distribution|School schedule distribution',
  Creates: 'Create|Create',
  DailyParticipations: 'Daily Participation|Daily Participations',
  DailySupervisions: 'Daily Supervision|Daily Supervisions',
  Dashboards: 'Dashboard|Dashboard',
  DataDetailsReports: 'Data Details Report|Data Details Reports',
  DataEntryReports: 'Data Entry Report|Data Entry Reports',
  Days: '{0}- |[1] One Day|[2,*] :year Days',
  Delays: 'Delay|Delays',
  DeliveryTypes: 'Delivery Type|Delivery Types',
  DemandKinds: 'Demand Kind|Demand Kinds',
  Demands: 'Demand|Demands',
  DistributionSchedules: 'Distribution Schedule|Distribution Schedules',
  Edits: 'Edit|Edit',
  Education: 'Education|Education',
  EducationClassGroups: 'School Class Group|School Class Groups',
  EducationClasses: 'School Class|School Classes',
  EducationDepartments: 'Education Department|Education Departments',
  EducationGrades: 'Grade|Grades',
  EducationMajors: 'Education Major|Education Majors',
  EducationOffices: 'Education Office|Education Offices',
  EducationPrograms: 'Education Program|Education Programs',
  EducationSpecializations: 'Education Specialization|Education Specializations',
  EducationStages: 'Education Stage|Education Stages',
  EducationSystems: 'Education System|Education Systems',
  EmpPermitDemands: 'Teacher Demand permit|Teacher Demand permits',
  Permits: 'Permits|Permit',
  EmpPermits: 'Teacher permit|Teacher permits',
  EmployeeAbsences: 'Absence|Absences',
  EmployeeAttends: 'Employee Attend|Employee Attends',
  EmployeeDelays: 'Employee Delay|Employee Delays',
  EmployeeEmpPermitDemands: 'Permit Demand|Permit Demands',
  EmployeeEmpPermits: 'Permit|Permits',
  EmployeeInfractions: 'Employee Infraction|Employee Infractions',
  EmployeePrograms: 'Employee Programs|Employee Program',
  EmployeeSwitchTimeTables: 'Switch School Class|Switch School Classes',
  EmployeeTimeTables: 'School Time Table|School Time Tables',
  EmployeeWaitingClasses: 'Waiting Class|Waiting Classes',
  EmployeeWallets: 'Employee Wallet|Employee Wallets',
  Employees: 'Employee|Employees',
  Excuses: 'Excuse|Excuses',
  ExtraProcedures: 'Procedure Action|Procedure Actions',
  FemaleEmpPermitDemands: 'Teacher Demand permit|Teacher Demand permits',
  FemaleEmpPermits: 'Teacher permit|Teacher permits',
  FemaleEmployeeAttends: 'Employee Attend|Employee Attends',
  FemaleEmployees: 'Employee|Employees',
  FemaleShifters: 'Female Shifter|Female Shifters',
  FemaleStudentAttends: 'Student Attend|Student Attends',
  FemaleStudentDelays: 'Student Delay|Student Delays',
  FemaleStudents: 'Female Student|Female Students',
  FemaleTeachers: 'Female Teacher|Female Teachers',
  FinancialStatuses: 'Financial Status|Financial Statuses',
  Genders: 'Gender|Genders',
  Gifts: 'Gifts|Gift',
  GlobalShows: 'Show|Show',
  Guardians: 'Guardian|Guardians',
  GuidanceCounselors: 'Guidance Counselor|Guidance Counselors',
  HealthStatuses: 'Health Status|Health Statuses',
  Homework: 'Homework|Homeworks',
  HomeworkAndInfractions: 'Homework And Infractions|Homework And Infractions',
  HomeworkSettings: 'Homework Setting |Homework Settings',
  Homeworks: 'Homework|Homeworks',
  DailyHomework: 'Daily Homework|Daily Homeworks',
  DailyHomeworks: 'Daily Homework|Daily Homeworks',
  Hours: '{0}-|[1] One Hour|[2,*] :hour Hours',
  ImportStudents: 'Import Student|Import Students',
  ImportEmployees: 'Import Employee|Import Employees',
  Imports: 'Import|Imports',
  IncomingArchives: 'Incoming Archive|Incoming Archives',
  IndexTimeTables: 'School Time Table|School Time Tables',
  Indices: 'Indices|Indices',
  InfractionCategories: 'Infraction Category|Infraction Categories',
  InfractionLevels: 'Infraction Level|Infraction Levels',
  InfractionNames: 'Infraction Name|Infraction Names',
  InfractionTypes: 'Infraction Type|Infraction Types',
  Infractions: 'Infraction|Infractions',
  JobTitles: 'Job Title|Job Titles',
  Jobs: 'Job|Jobs',
  Leaders: 'School Leader|School Leaders',
  MaleEmpPermitDemands: 'Teacher Demand permit|Teacher Demand permits',
  MaleEmpPermits: 'Teacher permit|Teacher permits',
  MaleEmployeeAttends: 'Employee Attend|Employee Attends',
  MaleEmployees: 'Employee|Employees',
  MaleShifters: 'Shifter|Shifters',
  MaleStudentAttends: 'Student Attend|Student Attends',
  MaleStudentDelays: 'Student Delay|Student Delays',
  MaleStudents: 'Student|Students',
  MaleTeachers: 'Teacher|Teachers',
  MembershipTypes: 'Membership Type|Membership Types',
  Memberships: 'Membership|Memberships',
  Minutes: '{0}-|[1] One Minute|[2,*] :minute Minutes',
  Months: '{0}- |[1] One Month|[2,*] :year Days',
  MyAbsences: 'My Absence|My Absences',
  MyDemands: 'My Demand|My Demands',
  MyEmpPermitDemands: 'My Permit Demand|My Permit  Demands',
  MyEmpPermits: 'My Permit|My Permits',
  MyEmployeeDelays: 'Employee Delay|Employee Delays',
  MyEmployeeInfractions: 'My Infraction|My Infractions',
  MyStudentInfractions: 'My Infraction|My Infractions',
  MyInfractions: 'My Infraction|My Infractions',
  MyIndices: 'My List|My List',
  MyOrders: 'My Orders|My Order',
  MyRecords: 'My Record|My Records',
  MySchoolOrders: 'My School Order|My School Orders',
  MySchoolPrograms: 'My School Program|My School Programs',
  MyShifts: 'My Shift|My Shifts',
  MyStudentPermits: 'My Permit|My Permits',
  MySupervisions: 'My Supervision|My Supervisions',
  MyTimeTableSwitches: 'My Switch School Class|My Switch School Classes',
  MyTimeTables: 'My School Schedule|My School Schedule',
  MyWaitingClasses: 'Waiting Class|My Waiting Classes',
  Nationalities: 'Nationality|Nationalities',
  NewHomework: 'New Homework|New Homeworks',
  NewHomeworks: 'New Homework|New Homeworks',
  Notifications: 'Notification|Notifications',
  OrderItems: 'Order item|Order items',
  Orders: 'Order|Orders',
  OutgoingArchives: 'Outgoing Archive|Outgoing Archives',
  Permissions: 'Permission|Permissions',
  Persons: '{0} No One|[1] One Person|[2,*] :persons Persons',
  Predicates: 'Predicate|Predicates',
  ProcedureNumbers: 'Procedure Number|Procedure Numbers',
  ProcedureTypes: 'Procedure Type|Procedure Types',
  Procedures: 'Procedure|Procedures',
  Products: 'Product|Products',
  Profiles: 'Profile|Profile',
  QRStudentAttends: 'QR Attends Scanner|QR Attends Scanner',
  QRStudentDelays: 'QR Delays Scanner|QR Delays Scanner',
  QrCodeBothStudents: 'Students Qr Code|Students Qr Code',
  QrCodeFemaleStudents: 'Students Qr Code|Students Qr Code',
  QrCodeMaleStudents: 'Students Qr Code|Students Qr Code',
  QrCodeStudents: 'Students Qr Code|Students Qr Code',
  QuestionKinds: 'Question Kind|Question Kinds',
  Questions: 'Question|Questions',
  RatePrograms: 'Rate Program|Rate Programs',
  Rating: 'Rating|Ratings',
  RatingItems: 'Rating Item|Rating Items',
  Ratings: 'Rating|Ratings',
  Reports: 'Report|Reports',
  Roles: 'Role|Roles',
  SchoolActivities: 'School Activity|School Activities',
  SchoolClasses: 'Class|Classes',
  SchoolDays: 'Day|Days',
  SchoolEntries: 'School|School',
  SchoolFeatures: 'School Feature|School Features',
  SchoolGenders: 'School Gender|School Genders',
  SchoolProducts: 'Gift|Gifts',
  SchoolPrograms: 'School Program|School Programs',
  SchoolSemesters: 'Semester|Semesters',
  SchoolSettings: 'School Setting|School Settings',
  SchoolSubjects: 'School Subject|School Subjects',
  SchoolTimeTables: 'School Schedule|School Schedule',
  SchoolTools: 'School Tool|School Tools',
  SchoolYears: 'School Year|School Years',
  Schools: 'School|Schools',
  SentHomework: 'Sent Homework|New Homeworks',
  SentHomeworks: 'Sent Homework|New Homeworks',
  SetDailyParticipations: 'Set Daily Participations|Set Daily Participation',
  Settings: 'Settings|Setting',
  Setups: 'Setup|Setup',
  ShiftCourses: 'Shift Course|Shift Courses',
  ShiftPeriods: 'Shift Period|Shift Periods',
  ShiftProcedures: 'Shift Procedure|Shift Procedures',
  ShiftRegistrations: 'Shift Registration|Shift Registrations',
  ShiftReports: 'Shift Report|Shift Reports',
  ShiftWorks: 'Shift Work|Shift Works',
  Shifters: 'Shifter|Shifters',
  Shifts: 'Shift|Shifts',
  Shows: 'Show|Show',
  SocialStatuses: 'Social Status|Social Statuses',
  Statuses: 'Status|Statuses',
  StudentAbsences: 'Student Absences|Student Absence',
  StudentAbsenceReports: 'Student Absence Report|Student Absences Reports',
  StudentMorningAbsences: 'Student Morning Absence|Student Morning Absences',
  StudentAffairs: 'Student Affairs|Student Affair',
  StudentAttends: 'Student Attend|Student Attends',
  StudentDelays: 'Student Delay|Student Delays',
  StudentInfractions: 'Student Infraction|Student Infractions',
  StudentPermits: 'Student Permit|Student Permits',
  StudentRegistrationSchoolPrograms: 'School Program Registration|School Programs Registration',
  StudentSchoolPrograms: 'My School Program|My School Programs',
  StudentTimeTables: 'School Time Table|School Time Tables',
  Students: 'Student|Students',
  SupervisionCourses: 'Course Supervision|Course Supervisions',
  SupervisionLocations: 'Supervision Location|Supervision Locations',
  SupervisionProcedures: 'Supervision Procedure|Supervision Procedures',
  SupervisionRegistrations: 'Supervision Registration|Supervision Registrations',
  Supervisions: 'Supervision|Supervisions',
  TakenProcedures: 'Taken Procedure|Taken Procedures',
  TeacherAffairs: 'Teacher Affair|Teacher Affairs',
  Teachers: 'Teacher|Teachers',
  TermTimeTables: 'Term Time Tables|Term Time Tables',
  TimeTableAssigns: 'Data Time Table|Data Time Tables',
  TimeTableSwitches: 'Switch School Class|Switch School Classes',
  TimeTables: 'School Time Table|School Time Tables',
  TranscriptItems: 'Transcript Item|Transcript Items',
  TranscriptRecords: 'Transcript Record|Transcript Records',
  TranscriptSettings: 'Transcript Setting|Transcript Settings',
  Transcripts: 'Transcript |Transcripts',
  Trasheds: 'Trashed|Trashed',
  Updates: 'Update|Update',
  UserGroups: 'User Group|User Groups',
  UserWallets: 'User Wallet|User Wallets',
  Users: 'User|Users',
  WaitingClassReports: 'WaitingClass Report|Waiting Class Reports',
  WaitingClasses: 'Waiting Class|Waiting Classes',
  WaitingExcuses: 'Waiting Class Excuses|Waiting Class Excuse',
  Years: '{0}- |[1] One Year|[2,*] :year Years',
  SendSchoolPrizes: 'Send Prize|Send Prizes',
  ProviderProducts: 'Provider Product|Provider Products',
  Affidavits: 'Affidavit|Affidavits',
  Circulars: 'Circular|Circulars',
  Alls: 'All|All'
}
