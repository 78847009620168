<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <v-container fluid>
    <app-row
      v-if="getTitle"
      no-gutters
    >
      <app-col all="12">
        <app-page-title>
          <slot name="top-title" />
          {{ getTitle }}
          <slot name="bottom-title" />
        </app-page-title>
      </app-col>
    </app-row>
    <app-row>
      <app-col all="12">
        <slot name="top" />
        <slot />
        <slot name="bottom" />
      </app-col>
    </app-row>
  </v-container>
</template>

<script>
export default {
  name: 'Page',
  props: {
    title: {
      type: String,
      default: () => null
    }
  },
  computed: {
    getTitle () {
      return this.title || this.$parent && this.$parent.getPageTitle() || ''
    }
  }
}
</script>

<style scoped>

</style>
