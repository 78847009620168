/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  scheduleCount: 'عدد الحصص المدرجة',
  progressing: 'Progressing',
  checkFromEdu: 'Check for class conflict',
  checkFromEmployee: 'Employee conflict check',
  checkFromConflictError: 'There is a conflict with {n} On {d}, {p}',
  insertedRemainingSchedule: 'The references have been added to the table',
  errorScheduleLength: 'Please check the references listed for the school term {n}',
  noSwitch: 'Class cannot be changed. Please delete class at the other teacher',
  insert: 'Insert',
  errorSwitchBetweenEmployees: 'Please choose an empty period for the same employee',
  cancelChoose: 'Cancel Choose',
  choosePeriod: 'Choose Period',
  remaining: 'Remaining',
  count: 'Count',
  movePeriod: 'Move Period',
  scheduleProgress: 'Progressing',
  selectNewSchedule: 'Select new period',
  errorNoNewMovedSelected: 'No New Moved Selected',
  confirmSwitch: 'confirm Switch'
}
