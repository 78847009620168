/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const prefix = a => 'Admin/Shop/Category' + (a ? `/${a}` : '')

export default {
  index (params, config = {}) {
    return params === true
      ? prefix()
      : axios.get(prefix(), {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true ? prefix() : axios.post(prefix(), data, config)
  },
  show (id, config = {}) {
    const url = prefix(id)
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = prefix(id)
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = prefix(id)
    return id === true ? url : axios.delete(url, config)
  }
}
