<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-container
    :class="{'container--fluid' : fluid}"
    :fluid="fluid"
  >
    <div class="mt-10" />
    <v-card
      :class="classes"
      class="v-card--app px-3"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div class="d-print-none d-flex grow flex-wrap card--div">
        <v-avatar
          v-if="avatar"
          class="mx-auto v-card--app__avatar elevation-6"
          color="grey"
          size="128"
        >
          <v-img :src="avatar" />
        </v-avatar>

        <v-sheet
          v-else
          :class="sheetClasses"
          :color="color"
          :max-height="sheetMaxHeight"
          :width="sheetWidth"
          elevation="6"
        >
          <slot
            v-if="$slots.heading"
            name="heading"
          />
          <slot
            v-else-if="$slots.image"
            name="image"
          />
          <div
            v-else-if="getCardTitle && !getCardIcon"
            :class="titleClasses"
          >
            {{ getCardTitle }}
          </div>
          <v-icon
            v-else-if="getCardIcon"
            size="32"
          >
            {{ getCardIcon }}
          </v-icon>
          <div
            v-if="text"
            class="text-start px-4"
          >
            {{ text }}
          </div>
        </v-sheet>

        <div
          v-if="$slots['after-heading']"
          class="ml-6"
        >
          <slot name="after-heading" />
        </div>

        <div
          v-else-if="getCardIcon && getCardTitle"
          class="ms-4 align-self-center"
        >
          <div class="text-button font-weight-light">
            {{ getCardTitle }}
          </div>
        </div>

        <div
          v-if="$slots['after-title'] && getCardTitle"
          class="ml-6 align-self-center"
        >
          <slot name="after-title" />
        </div>
      </div>
      <slot />
      <template v-if="$slots.actions">
        <v-divider class="mt-2 d-print-none" />

        <v-card-actions class="pb-0 d-print-none">
          <slot name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </app-container>
</template>

<script>
export default {
  name: 'Card',
  props: {
    fluid: {
      type: Boolean,
      default: () => false
    },
    avatar: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => 'secondary'
    },
    icon: {
      type: String,
      default: () => undefined
    },
    text: {
      type: String,
      default: () => undefined
    },
    title: {
      type: String,
      default: () => undefined
    }
  },
  computed: {
    titleClasses () {
      return ['text-body-1', 'text-sm-h4', 'font-weight-light', 'text-start'].join(' ')
    },
    classes () {
      return {
        'v-card--app--has-heading': this.hasHeading
      }
    },
    hasHeading () {
      // return Boolean(this.$slots.heading || this.title || this.icon)
      return Boolean(this.$slots.heading || this.title || this.getCardIcon)
    },
    sheetClasses () {
      return [
        'text-center',
        'v-card--app__heading',
        // 'mb-6',
        !this.$slots.image ? 'pa-5' : ''
      ].join(' ')
    },
    sheetWidth () {
      // return this.icon ? 'auto' : '100%'
      return this.getCardIcon ? 'auto' : '100%'
    },
    sheetMaxHeight () {
      // return this.icon ? 90 : undefined
      return this.getCardIcon ? 70 : undefined
    },
    getCardIcon () {
      if (this.icon !== undefined) return this.icon

      const items = this.$root.sideMenuItems
      if (!items) return this.icon
      const logo = this.getIconFormItems(items)
      return logo || this.icon
    },
    getCardTitle () {
      if (this.title !== undefined) return this.title

      const items = this.$root.sideMenuItems
      if (!items) return this.title

      const title = this.getTitleFormItems(items)
      return title || this.getPageTitle() || this.title
    }
  },
  methods: {
    getIconFormItems (items) {
      const routeName = this.$route.name
      let index
      let item
      for (index in items) {
        item = items[index]
        if (item.name === routeName && item.icon) {
          return item.icon
        }
        if (item.items) {
          const logo = this.getIconFormItems(item.items)
          if (logo) return logo
        }
      }
    },
    getTitleFormItems (items) {
      const routeName = this.$route.name
      let index
      let item
      for (index in items) {
        item = items[index]
        if (item.name === routeName && item.title) {
          return item.title
        }
        if (item.items) {
          const title = this.getTitleFormItems(item.items)
          if (title) return title
        }
      }
    }
  }
}
</script>

<style
  lang="sass"
  scoped
>
.v-card--app
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -30px
    transition: .3s ease
    z-index: 1

  &__heading-2
    position: relative
    top: -15px
    transition: .3s ease
    z-index: 1

.v-sheet
  border-radius: 4px
</style>
