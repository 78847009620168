<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <app-col-span
    :lazy-model.sync="lazy"
    v-bind="$attrs"
  >
    <ValidationProvider
      v-slot="v"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <slot name="top" />
      <v-autocomplete
        :append-icon="getAppendIcon"
        :clearable="getClearable"
        :error-messages="v.errors"
        :items="getItems"
        :label="getLabel"
        :loading="loading"
        :multiple="multiple"
        :placeholder="getPlaceholder"
        :readonly="readonly"
        :single-line="singleLine"
        :value="value"
        v-bind="$attrs"
        v-on="{...$listeners,on:input}"
      >
        <template
          v-for="slot in getSlots"
          #[slot]="props"
        >
          <slot
            :name="slot"
            v-bind="props"
          />
        </template>
        <template
          v-if="getSlots.indexOf('append-outer') !== -1"
          #prepend
        >
          <slot name="append-outer" />
        </template>
      </v-autocomplete>
      <slot name="bottom" />
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import InputsMixins from '@mixins/InputsMixins'

export default {
  name: 'AutoSelect',
  mixins: [InputsMixins],
  props: {
    lazyModel: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: () => undefined
    },
    singleLine: {
      type: Boolean,
      default: () => undefined
    },
    loading: {
      type: Boolean
    },
    appendIcon: {
      type: String,
      default: () => '$dropdown'
    }
  },
  computed: {
    getSlots () {
      const a = Object.keys(this.$scopedSlots).filter(i => ['top', 'bottom'].indexOf(i) < 0)
      return a
    },
    lazy: {
      get () {
        return this.lazyModel
      },
      set (v) {
        this.$emit('update:lazyModel', v)
      }
    },
    getAppendIcon () {
      return this.readonly === true ? null : this.appendIcon
    },
    getItems () {
      return this.items || this.defaultItems
    }
  },
  mounted () {
    // console.log(this.$slots)
    // console.log(this.$scopedSlots)
  },
  methods: {
    input (e) {
      this.$emit('input', e || null)
    }
  }
}
</script>
