/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const prefix = 'Notification'

export default {
  index (params, config = {}) {
    return params === !0
      ? prefix
      : axios.get(prefix, {
        ...config,
        params
      })
  },
  indexUnreadCount (config = {}) {
    return axios.get(`${prefix}/UnreadCount`, config)
  },
  markAsRead (id, config = {}) {
    return axios.put(`${prefix}/${id}/read`, config)
  }
}
