/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const prefix = 'Resources'

const Resource = a => `${prefix}/${a}`

const Education = (a = '') => Resource(`Education/${a}`)
const School = (a = '') => Resource(`School/${a}`)

const Actor = a => Resource(`Actor/${a}`)

const Accessory = a => School(`Accessory/${a}`)
const TeacherAffair = a => School(`TeacherAffair/${a}`)
const SchoolFeature = a => School(`SchoolFeature/${a}`)
const DailySupervision = a => School(`DailySupervision/${a}`)
const SchoolActivity = a => School(`SchoolActivity/${a}`)
const Employer = a => School(`Employer/${a}`)
const Shop = a => Resource(`Shop/${a}`)

const GuidanceCounselor = a => School('GuidanceCounselor/InfractionName') +
 (a ? `/${a}` : '')

export default {
  shop: {
    allCategories (params = {}, config = {}) {
      const url = Shop('AllCategory')
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    mainCategories (params = {}, config = {}) {
      const url = Shop('MainCategory')
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    mainCoinsCategories (params = {}, config = {}) {
      const url = Shop('MainCoinsCategory')
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    categoryProducts (cat, params = {}, config = {}) {
      const url = Shop(`CategoryProducts/${cat}`)
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    deliveryTypes (params = {}, config = {}) {
      const url = Shop('DeliveryType')
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    orderStatus (params = {}, config = {}) {
      const url = Shop('OrderStatus')
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    storeOrder (data = {}, config = {}) {
      const url = Shop('StoreOrder')
      return data === true ? url : axios.post(url, data, config)
    }
  },
  app: {
    educations (config = {}) {
      const url = Resource('Education')
      return config === true ? url : axios.get(url, config)
    },
    educationOffices (config = {}) {
      const url = Education('EducationOffice')
      return config === true ? url : axios.get(url, config)
    },
    educationDepartments (config = {}) {
      const url = Education('EducationDepartment')
      return config === true ? url : axios.get(url, config)
    },
    phases (config = {}) {
      const url = Education('Phases')
      return config === true ? url : axios.get(url, config)
    }
  },
  membershipType: {
    list (config = {}) {
      const url = Resource('MembershipType')
      return config === true ? url : axios.get(url, config)
    },
    all (config = {}) {
      const url = Resource('MembershipType')
      // let c;
      // if(typeof config === 'object') {
      //   c = {...config};
      //   c.params = c.params || {};
      //   c.params['_a'] = 1;
      // }
      return config === true ? url : axios.get(`${url}?_a=1`, config)
    },
    codes (config = {}) {
      const url = Resource('MembershipType/Code')
      return config === true ? url : axios.get(url, config)
    }
  },
  actor: {
    users (params = {}, config = {}) {
      const url = Actor('Users')
      if (params !== !0 && params) {
        params.itemsPerPage = -1
      }
      return params === !0
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    permissions (config = {}) {
      const url = Actor('Permissions')
      return config === true ? url : axios.get(url, config)
    },
    leaders (config = {}) {
      const url = Actor('Leaders')
      return config === true ? url : axios.get(url, config)
    },
    schools (config = {}) {
      const url = Actor('Schools')
      return config === true ? url : axios.get(url, config)
    },
    employees (config = {}) {
      const url = School('Employees')
      return config === true ? url : axios.get(url, config)
    },
    fullEmployees (config = {}) {
      const url = Actor('FullEmployees')
      return config === true ? url : axios.get(url, config)
    },
    guardians (config = {}) {
      const url = School('Guardians')
      return config === true ? url : axios.get(url, config)
    },
    providers (config = {}) {
      const url = Actor('Providers')
      return config === true ? url : axios.get(url, config)
    },
    roleCodes (config = {}) {
      const url = Actor('RoleCodes')
      return config === true ? url : axios.get(url, config)
    },
    allRoleCodes (config = {}) {
      const url = Actor('AllRoleCodes')
      return config === true ? url : axios.get(url, config)
    },
    guardianStudents (config = {}) {
      const url = School('Guardian-Students')
      return config === true ? url : axios.get(url, config)
    },
    myStudents (config = {}) {
      const url = School('MyStudents')
      return config === true ? url : axios.get(url, config)
    },
    students (config = {}) {
      const url = School('Students')
      return config === true ? url : axios.get(url, config)
    },
    myEducationClass (config = {}) {
      const url = School('MyEducationClass')
      return config === true ? url : axios.get(url, config)
    }
  },
  guidanceCounselor: {
    infractionName (config = {}) {
      const url = GuidanceCounselor()
      return config === true ? url : axios.get(url, config)
    }
  },
  accessory: {
    scheduleHeaders: (config) => axios.get(Accessory('ScheduleHeaders'),
      config),
    schoolDays (config = {}) {
      const url = Accessory('SchoolDay')
      return config === true ? url : axios.get(url, config)
    },
    studySchoolDays (config = {}) {
      const url = Accessory('StudySchoolDay')
      return config === true ? url : axios.get(url, config)
    },
    schoolSubject (config = {}) {
      const url = Accessory('SchoolSubject')
      return config === true ? url : axios.get(url, config)
    },
    schoolClass (config = {}) {
      const url = Accessory('SchoolClass')
      return config === true ? url : axios.get(url, config)
    },
    studySchoolSubject (config = {}) {
      const url = Accessory('StudySchoolSubject')
      return config === true ? url : axios.get(url, config)
    },
    mySchoolSubject (config = {}) {
      const url = Accessory('MySchoolSubject')
      return config === true ? url : axios.get(url, config)
    },
    sharedSchoolSubject (config = {}) {
      const url = Accessory('SharedSchoolSubject')
      return config === true ? url : axios.get(url, config)
    },
    jobs (config = {}) {
      const url = Accessory('Job')
      return config === true ? url : axios.get(url, config)
    },
    jobTitles (config = {}) {
      const url = Accessory('JobTitle')
      return config === true ? url : axios.get(url, config)
    }
  },
  teacherAffair: {
    waitingExcuse (config = {}) {
      const url = TeacherAffair('WaitingExcuse')
      return config === true ? url : axios.get(url, config)
    },
    shiftPeriods (config = {}) {
      const url = TeacherAffair('ShiftPeriod')
      return config === true ? url : axios.get(url, config)
    },
    shiftProcedures (config = {}) {
      const url = TeacherAffair('ShiftProcedure')
      return config === true ? url : axios.get(url, config)
    },
    shiftCourses (config = {}) {
      const url = TeacherAffair('ShiftCourse')
      return config === true ? url : axios.get(url, config)
    },
    shiftsByShiftCourse (courseId, config = {}) {
      const url = TeacherAffair(`${courseId}/Shift`)
      return config === true ? url : axios.get(url, config)
    },
    timetableHeaders (config = {}) {
      const url = TeacherAffair('TimeTable/Headers')
      return config === true ? url : axios.get(url, config)
    },
    infractionTypes (config = {}) {
      const url = TeacherAffair('InfractionType')
      return config === true ? url : axios.get(url, config)
    }
  },
  schoolFeature: {
    mySenderDemandKind (config = {}) {
      const url = SchoolFeature('MySenderDemandKind')
      return config === true ? url : axios.get(url, config)
    },
    archiveTypes (config = {}) {
      const url = SchoolFeature('ArchiveType')
      return config === true ? url : axios.get(url, config)
    }
  },
  dailySupervision: {
    supervisionLocations (config = {}) {
      const url = DailySupervision('SupervisionLocation')
      return config === true ? url : axios.get(url, config)
    },
    supervisionProcedures (config = {}) {
      const url = DailySupervision('SupervisionProcedure')
      return config === true ? url : axios.get(url, config)
    },
    supervisionCourses (config = {}) {
      const url = DailySupervision('SupervisionCourse')
      return config === true ? url : axios.get(url, config)
    },
    supervisionsBySupervisionCourse (courseId, config = {}) {
      const url = DailySupervision(`${courseId}/Supervision`)
      return config === true ? url : axios.get(url, config)
    }
  },
  schoolActivity: {
    ratingItems (config = {}) {
      const url = SchoolActivity('RatingItem')
      return config === true ? url : axios.get(url, config)
    },
    schoolProgram (config = {}) {
      const url = SchoolActivity('SchoolProgram')
      return config === true ? url : axios.get(url, config)
    },
    ratingSchoolProgram (config = {}) {
      const url = SchoolActivity('SchoolProgram/Rating')
      return config === true ? url : axios.get(url, config)
    },
    registrationSchoolProgram (config = {}) {
      const url = SchoolActivity('SchoolProgram/Registration')
      return config === true ? url : axios.get(url, config)
    },
    schoolProgramStatuses (config = {}) {
      const url = SchoolActivity('SchoolProgram/StatusCodes')
      return config === true ? url : axios.get(url, config)
    },
    userRating (programId, config = {}) {
      const url = SchoolActivity(`SchoolProgram/${programId}/UserRating`)
      return programId === true ? url : axios.get(url, config)
    }
  },
  employer: {
    homeworkSettings (config = {}) {
      const url = Employer('HomeworkSetting')
      return config === true ? url : axios.get(url, config)
    }
  },
  school: {
    educationMajors (config = {}) {
      const url = School('EducationMajors')
      // console.log(url)
      return config === true ? url : axios.get(url, config)
    }
  },
  nationalities (config = {}) {
    const url = Accessory('Nationality')
    return config === true ? url : axios.get(url, config)
  },
  countries (config = {}) {
    const url = Accessory('Country')
    return config === true ? url : axios.get(url, config)
  },
  genders (config = {}) {
    const url = Accessory('Gender')
    return config === true ? url : axios.get(url, config)
  },
  schoolGenders (config = {}) {
    const url = Accessory('SchoolGender')
    return config === true ? url : axios.get(url, config)
  },
  healthStatuses (config = {}) {
    const url = Accessory('HealthStatus')
    return config === true ? url : axios.get(url, config)
  },
  financialStatuses (config = {}) {
    const url = Accessory('FinancialStatus')
    return config === true ? url : axios.get(url, config)
  },
  socialStatuses (config = {}) {
    const url = Accessory('SocialStatus')
    return config === true ? url : axios.get(url, config)
  }
}
