<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->

<template>
  <tooltip
    :disabled="tooltipDisabled"
    :text="getTooltip"
  >
    <base-btn
      v-bind="getAttrs"
      v-on="$listeners"
    >
      <slot />
    </base-btn>
  </tooltip>
</template>

<script>
export default {
  name: 'Btn',
  components: {
    Tooltip: () => import('@components/base/tooltip/Tooltip'),
    BaseBtn: () => import('@components/base/btn/BaseBtn')
  },
  props: {
    tooltip: {
      type: String,
      default: () => undefined
    }
  },
  computed: {
    getAttrs () {
      // console.log(this.$el)
      return this.$attrs
    },
    getTooltip () {
      if (this.tooltip) return this.parseAttribute(this.tooltip)
      return null
    },
    tooltipDisabled () {
      return this.getTooltip === null || this.getTooltip === undefined
    }
  }
}
</script>
