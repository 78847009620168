/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'
import { StubUrl } from '@helpers/api/requests/Stub'

// const prefix = a => 'Admin/Shop/Order' + (a ? `/${a}` : '')
const stubUrl = StubUrl('Admin/Shop/Order')

export default {
  index (params, config = {}) {
    return params === true
      ? stubUrl()
      : axios.get(stubUrl(), {
        ...config,
        params
      })
  },
  myIndex (params, config = {}) {
    const url = 'Resources/Shop/MyOrders'
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  mySchoolOrders (params, config = {}) {
    const url = 'Front/MySchoolOrders'
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true ? stubUrl() : axios.post(stubUrl(), data, config)
  },
  show (id, config = {}) {
    const url = stubUrl(id)
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = stubUrl(id)
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = stubUrl(id)
    return id === true ? url : axios.delete(url, config)
  },
  complete (id, config = {}) {
    const url = `Resources/Shop/CompleteOrder/${id}`
    return id === true ? url : axios.get(url, config)
  },
  cancel (id, config = {}) {
    const url = `Resources/Shop/CancelOrder/${id}`
    return id === true ? url : axios.get(url, config)
  }
}
