/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

export default {
  login: post => {
    return axios.post('login', post)
  },
  logout (push_token) {
    return axios.post('logout', { push_token })
  },
  checkToken () {
    return axios.get('check-token')
  },
  register: form => {
    return axios.post('register', form)
  },
  forgetPassword: form => axios.post('forget-password', form)
}
