/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import { trimStart } from 'lodash'

export const API_CONFIG = {
  baseUrl: '/api/v3',
  // baseUrl: 'https://atab3.com/api/v3',
  url (url) {
    return `${this.baseUrl}/${trimStart(url, '/')}`
  }
}

export default API_CONFIG
