/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  register: 'حساب جديد',
  create_account: 'إنشاء الحساب',
  reset_hint: 'حساب جديد'
}
