<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<script>
import NumberInput from '@base-inputs/NumberInput'

export default {
  name: 'FloatInput',
  extends: NumberInput,
  props: {
    escapeInput: {
      type: [Array, String],
      default: () => (['.'])
    }
  }
}
</script>
