/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const url = StubUrl('Front/Education/EducationClassGroup')

export default {
  ...Stub(url()),
  allSelect (config = {}) {
    const p = 'Resources/School/EducationClassGroup'
    return config === true ? p : axios.get(p, config)
  }
}
