/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const employerPrefix = 'Front/Employer/Homework'
const studentPrefix = 'Front/Student/Student-Homework'
const studentUrl = a => `${studentPrefix}/${a}`

export default {
  index (params, config = {}) {
    return params === true
      ? employerPrefix
      : axios.get(employerPrefix, {
        ...config,
        params
      })
  },
  store (data = {}, config = {}) {
    return data === true
      ? employerPrefix
      : axios.post(employerPrefix, data,
        config)
  },
  show (id, config = {}) {
    const url = `${employerPrefix}/${id}`
    return id === true ? url : axios.get(url, config)
  },
  update (id, data = {}, config = {}) {
    const url = `${employerPrefix}/${id}`
    return id === true ? url : axios.put(url, data, config)
  },
  destroy (id, config = {}) {
    const url = `${employerPrefix}/${id}`
    return id === true ? url : axios.delete(url, config)
  },
  indexByStudent (id, params, config = {}) {
    const url = `${employerPrefix}/Student/${id}/Sent`
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },

  studentNewIndex (params, config = {}) {
    const url = studentUrl('New')
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  studentSentIndex (params, config = {}) {
    const url = studentUrl('Sent')
    return params === true
      ? url
      : axios.get(url, {
        ...config,
        params
      })
  },
  studentShow (id, config = {}) {
    const url = studentUrl(`${id}/show`)
    return id === true ? url : axios.get(url, config)
  },
  studentSendHomework (id, data, config = {}) {
    const url = studentUrl(`${id}/Send-Homework`)
    return id === true ? url : axios.post(url, data, config)
  }
}
