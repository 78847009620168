<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2018-2024  All rights reserved.
  - MyTh Ahmed
  -->
<template>
  <app-text-input
    ref="input"
    :clearable="clearable"
    :counter="false"
    :force-clearable="forceClearable"
    :name="name"
    :prepend-icon="prependIcon"
    :rules="getRules"
    :value="value"
    :vid="vid"
    readonly
    v-bind="$attrs"
    @click="click"
    v-on="{...$listeners,on:click}"
  >
    <v-dialog
      ref="dialog"
      v-model="modal"
      :max-width="maxWidth"
      :return-value.sync="date"
      :width="width"
      persistent
      v-bind="$attrs"
    >
      <v-time-picker
        v-model="date"
        :max="max"
        :min="min"
        ampm-in-title
        scrollable
      >
        <app-btn
          color="primary"
          text
          @click="save(date)"
        >
          {{ $t('done') }}
        </app-btn>
        <v-spacer />
        <app-btn
          color="error"
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </app-btn>
      </v-time-picker>
    </v-dialog>
  </app-text-input>
</template>

<script>

import InputsMixins from '@mixins/InputsMixins'
import moment from 'moment'

export default {
  name: 'TimePicker',
  mixins: [InputsMixins],
  props: {
    forceClearable: {
      type: Boolean,
      default: () => !1
    },
    maxWidth: {
      type: [String, Number],
      default: () => 290
    },
    width: {
      type: [String, Number],
      default: () => 290
    },
    prependIcon: {
      type: String,
      default: () => 'access_time'
    },
    clearable: {
      type: Boolean,
      default: () => !1
    },
    min: {
      default: () => undefined
    },
    max: {
      default: () => undefined
    }
  },
  data () {
    return {
      modal: false,
      date: undefined
    }
  },
  computed: {
    dateLabel () {
      const val = ''
      if (this.value) {
        // console.log(this.value)
        // val = this.$helpers.fromArabicNumber(moment(this.value).format('HH:mm'))
      }
      return val
    }
  },
  mounted () {
    if (this.value) {
      try {
        const d = moment().format('YYYY-MM-DD') + ' ' + this.value
        const formatted = moment(d).format('HH:mm')
        // console.log(d, formatted)

        if (formatted) {
          this.date = this.$helpers.fromArabicNumber(formatted)
          this.emit(this.date)
        }
      } catch (e) {
        this.date = this.value
      }
    }
  },
  methods: {
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
    save (v) {
      this.$refs.dialog.save(v)
      this.emit(v)
      this.$nextTick(() => {
        this.focus()
        this.blur()
      })
    },
    click () {
      this.modal = !this.modal

      if (this.modal === false) {
        this.$nextTick(() => this.blur())
      }
    },
    cancel () {
      this.modal = !1
      this.$nextTick(() => this.blur())
    },
    emit (v) {
      this.$emit('input', v)
    }
  }
}
</script>
