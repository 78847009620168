/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'
import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const prefix = 'Front/Education/EducationClass'
const url = StubUrl(prefix)

export default {
  ...Stub(prefix),
  allSelect (params, config = {}) {
    const p = 'Resources/School/EducationClass'
    return params === true ? p : axios.get(p, { params, ...config })
  },
  studentsQrCodeUrl: (id, params) => axios.get(url(`${id}/Students-Qr-Code-Url`), { params })
}
