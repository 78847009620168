/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  '#': 'No.',
  send: 'Send',
  about_us: 'About Us',
  terms_conditions: 'Terms & Conditions',
  privacy_policy: 'Privacy & Policy',
  NoResults: 'No Results',
  SA: 'Saudi Arabia',
  TranscriptSetting: 'Transcript Setting',
  accept: 'Accept',
  accepted: 'Accepted',
  activate: 'Activate',
  active: 'Enabled',
  add: 'Add',
  add_new: 'Add New',
  all: 'All',
  all_week: 'All Week',
  allow: 'Allow',
  am: 'AM',
  and: 'And',
  and_implode: ' And ',
  apply: 'Apply',
  approve: 'Approve',
  approveInfraction: 'Approve Infraction',
  approve_program: 'Approve Program',
  ar: 'العربية',
  archiveTypeLabel: 'Archive Category',
  are_you_sure: 'Are you sure ?',
  attendanceRegistration: 'Attendance',
  avatar: 'Image',
  back: 'Back',
  barcode: 'Barcode',
  'barcode4-6': 'Barcode 4*6',
  browse: 'Browse',
  cancel: 'Cancel',
  cancelInfraction: 'Cancel Infraction',
  cancel_approve: 'Cancel Approve',
  change: 'Change',
  choose: 'Choose',
  choose_switch_class: 'Choose Class',
  class_period: 'Class',
  clear: 'Clear',
  close: 'Close',
  close_registration: 'Close Registration',
  close_window: 'Close Window',
  coin: 'Coin',
  coins: 'Coins',
  confirm: 'Confirm',
  contact_us: 'Contact us',
  contact_us_management: 'Contact us Management',
  control: 'control',
  create: 'Create',
  create_time_tables: 'School schedule redistribution',
  dashboard: 'Dashboard',
  data_and: ' And ',
  date: 'Date',
  day: 'Day',
  default: 'Default',
  degree: 'Degree',
  delayedRegistration: 'Delayed',
  delete: 'Delete',
  deleteData: 'Delete Data',
  delete_all_term_rows: 'Delete All',
  delete_all_time_tables: 'Truncate all data',
  delete_print_header: 'Delete Print Header',
  delete_time_tables: 'Delete Schedule',
  delivery_fees: 'Delivery Fees',
  destroy: 'Delete',
  details: 'Details',
  disable: 'Disable',
  disabled: 'Disabled',
  distributionSchedule: 'Distribution Schedule',
  done: 'Done',
  download_example_file: 'Download example file',
  edit: 'Edit',
  employeeScheduleHint: 'Click on the quota to switch with an empty quota',
  employee_shifting_name: 'Contact us',
  en: 'English',
  end_rating: 'End Rating',
  error: 'Error !!',
  expansionPanelHeaderOptions: 'Tools',
  export: 'Export',
  exportExcel: 'Export as Excel',
  exportPdf: 'Export as PDF',
  filter: 'Filter',
  finishOrder: 'Finish order',
  finish_program: 'Finish Program',
  force_delete: 'Force Delete',
  forgot_your_password: 'Forgot Your Password?',
  reset_password: 'Reset Password?',
  from: 'From',
  gift: 'Gift',
  hello: 'Hello',
  home: 'Home',
  id: '#',
  image: 'Image',
  import: 'Import',
  importStudents: 'Import Students',
  index: 'List',
  is_coins_returned: 'Coins returned',
  last_login: 'Last Login',
  late_students: 'The names of the late students',
  login: 'Login',
  logout: 'Logout',
  management: 'Management',
  membershipTypesSelect: 'Membership Type',
  ministry_of_education: 'Ministry of education',
  na: '-',
  no: 'No',
  no_membership: 'No membership',
  none: 'None',
  not_sent: 'Not Sent',
  now: 'Now',
  numOfCopies: 'Number of copies',
  official_paper: 'Official Paper',
  oh_noes: 'Oh Noes!!',
  open_registration: 'Open Registration',
  options: 'options',
  pending: 'Pending',
  period_total: 'Period Total',
  please_fill_in_this_field: 'Please fill in this field',
  pm: 'PM',
  print: 'Print',
  printSchoolSchedule: 'Print School Schedule',
  print_access: 'Print entry permit',
  print_daily_groups: 'Print daily groups',
  print_preview: 'Print Preview',
  data_preview: 'Data Preview',
  data_preview_tooltip: 'Preview Before insert',
  profile: 'Profile',
  program_rate: 'Program Rate',
  progress: 'progress ...',
  q: '?',
  qr_code_capture: 'QR Code Capture',
  qr_scanner: 'QR Code Scanner',
  quantity: 'quantity',
  rate: 'Rate',
  receipt: 'Receipt',
  refreshTable: 'Refresh Data',
  regards: 'Regards',
  register: 'Register',
  reject: 'Reject',
  remaining_time_table_assigns: 'Remaining Time Table Assigns',
  restore: 'Restore',
  return_coins: 'Return coins',
  sar: 'SAR',
  save: 'Save',
  save_changes: 'Save Changes',
  save_schedule: 'Save Schedule',
  schedule_distribution: 'Schedule Distribution',
  schoolClass: 'School Class',
  search: 'Search',
  select: 'Select',
  selectAll: 'Select All',
  send_homework: 'Send Homework',
  send_homework_date: 'Send Date',
  set_degree: 'Set Degree',
  set_participation: 'Set Participation',
  set_participation_with_coins: 'Participation + 1 Coin',
  setup: 'Setup',
  shiftReportHint: 'This report is delivered to the Student Affairs Representative the day after the shift.',
  shop: 'Shop',
  show: 'Show',
  showPermissions: 'Show Permissions',
  sign_out: 'Sign out',
  signature: 'Signature',
  start_rating: 'Open Rating',
  status: 'Status',
  store: 'Add',
  studentQrScanner: 'Student Qr Scanner',
  student_name: 'Student name',
  switch: 'Switch',
  switch_changes: 'Switch Changes',
  switch_hint: 'A request can be sent to the administration requesting a change of shares if possible',
  switch_request: '',
  switch_request_from: 'Switch Request From :From',
  teacher: 'Teacher',
  teacher_question: 'Teacher question',
  template_notify: 'Printing and notifications',
  text_copied: 'Text Copied',
  there_is_an_error: 'There is an error',
  time_table_assigns: 'Time Table Assigns',
  to: 'To',
  today: 'Today',
  trashed_index: 'Trashed List',
  truncate_schedule: 'Truncate Schedule',
  unacceptable: 'Unacceptable',
  unregister: 'Un Register',
  update: 'Update',
  view: 'View',
  violating_students: 'Names of the violating students',
  whoops: 'Whoops',
  yes: 'Yes',
  print_employees_schedule: 'Teachers schedule printing',
  print_edu_schedule: 'Class schedule printing',
  minutes: 'Minutes',
  minute: 'Minute',
  confirm_absence: 'Confirm Absence',
  subscription: 'Subscription',
  sponsors: 'Sponsors',
  myCoins: 'My Coins',
  partners: 'Partners',
  remaining: 'Remaining',
  sold: 'Sold',
  available_quantity: 'Available Quantity',
  copy: 'Copy',
  new_affidavit: 'Affidavit'
}
