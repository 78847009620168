/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

export default {
  choose: 'Choose {n}',
  store: 'Add new {n}',
  update: 'Update {n}',
  show: 'Show {n}',
  destroy: 'Delete {n}',
  jobPermissions: 'Permissions of {n}',
  studentParticipations: 'Participations of {n}',
  studentHomeworks: '{n} Homeworks',
  imported: 'Imported {n}',
  notImported: 'Not Imported {n}',
  copy_right: 'All rights reserved © {d}'
}
