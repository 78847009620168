/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'

const Employee = a => ('Front/Employer/MyRecords' + (a ? `/${a}` : ''))
const Student = a => ('Front/Student/MyRecords' + (a ? `/${a}` : ''))

export default {
  employee: {
    studentByClass (id, params, config = {}) {
      const url = Employee(`EducationClass/${id}/Students`)
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    timeTable (params, config = {}) {
      const url = Employee('TimeTable')
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    switchTimeTable (params, config = {}) {
      const url = Employee('SwitchTimeTable')
      return params === !0 ? url : axios.get(url, { ...config, params })
    },
    absence (params, config = {}) {
      const url = Employee('Absence')
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    waitingClass (params, config = {}) {
      const url = Employee('WaitingClass')
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    empPermit (params, config = {}) {
      const url = Employee('EmpPermit')
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    },
    empPermitDemand (params, config = {}) {
      const url = Employee('EmpPermitDemand')
      return params === true
        ? url
        : axios.get(url, {
          ...config,
          params
        })
    }
  },
  student: {
    timeTable (params, config = {}) {
      const url = Student('TimeTable')
      params = params || {}
      params.showEmployee = 0
      return params === !0 ? url : axios.get(url, { ...config, params })
    }
  }
}
