/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import axios from 'axios'
import { Stub, StubUrl } from '@helpers/api/requests/Stub'

const prefix = 'Front/Attend/StudentDelay'
const url = StubUrl(prefix)

export default {
  ...Stub(prefix),
  async qrStudent (data = {}, config = {}) {
    const u = url('qrStudents')
    return data === true ? u : axios.post(u, data, config)
  },
  sendNotifications (data = {}, config = {}) {
    const u = url('SendNotifications')
    return data === !0 ? u : axios.post(u, data, config)
  },
  studentIndex: params => params === !0
    ? url('MyIndex')
    : axios.get(url('MyIndex'), { params })

}
