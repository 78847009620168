/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('snackbar')

export default {
  methods: {
    alert (text = '', type = '', callback = null) {
      if (_.isObject(text)) {
        type = text.type || null
        callback = text.callback || null
        text = text.text
      }

      if (type && typeof type !== 'string') {
        callback = type
        type = null
      }
      return mapActions(['showSnackbar']).showSnackbar.call(this, {
        text,
        type,
        callback
      })
    },
    alertError (text = '', callback = null) {
      return this.alert(text, 'error', callback)
    },
    alertSuccess (text = '', callback = null) {
      return this.alert(text, 'success', callback)
    },
    toast (text = '', type = 'normal', toast = !0) {
      return mapActions(['showSnackbar']).showSnackbar.call(this, {
        text,
        type,
        toast
      })
    },
    confirm (textOrCallback = '', callback = null, reject = null, type = null) {
      const confirm = !0
      if (typeof textOrCallback === 'function') {
        callback = textOrCallback
        textOrCallback = this.$t('messages.areYouSure')
      }
      return mapActions(['showSnackbar']).showSnackbar.call(this, {
        text: textOrCallback,
        type,
        callback,
        confirm,
        reject
      })
    }
  }
}
